.buttons {
	display: flex;
	width: 100%;
	padding: 12px 16px;

	&__label {
		display: flex;
		align-items: center;
		margin-right: 16px;
		transition: 0.3s ease-in-out;
		cursor: pointer;

		@media (hover: hover) and (pointer: fine) {
			&:hover {
				opacity: 0.7;
			}
		}
	}

	&__radio {
		&:checked + .buttons__labelText {
			color: #fff;
			background: #2c2c2e;
		}
	}

	&__labelText {
		display: flex;
		align-items: center;
		color: rgba(235, 235, 245, 0.6);
		padding: 6px 8px;
		background: transparent;
		border-radius: 8px;
		font-size: 14px;
		font-weight: 500;
		line-height: 16px;
		text-align: center;
		transition: 0.3s ease-in-out;
	}
}
