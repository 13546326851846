@import "~@btc-alpha/ui-components/dist/styles/functions";
@import "~@btc-alpha/ui-components/dist/styles/media";
@import "~@btc-alpha/ui-components/dist/styles/mixins";

.root {
	width: 100%;
	max-width: rems(440);

	form {
		display: flex;
		flex-direction: column;
		gap: rems(16);

		.cols {
			display: flex;
			flex-direction: row;
			gap: rems(16);

			& > * {
				flex-grow: 1;
			}
		}
	}

	.submit {
		margin-top: rems(32);
	}
}
