.borrowSize {
	margin: 0 16px;
	padding: 12px 0;
	display: flex;
	flex-direction: column;
	border-top: 1px solid rgba(84, 84, 88, 0.36);

	&__mainRow {
		display: flex;
		justify-content: space-between;
	}

	&__mainText {
		color: rgba(235, 235, 245, 0.6);
		font-size: 12px;
		font-weight: 400;
		line-height: 16px;
		text-decoration-line: underline;
		padding: 0;
		margin: 0;
	}

	&__value {
		color: #fff;
		font-size: 12px;
		font-weight: 500;
		line-height: 14px;
		padding: 0;
		margin: 0;
	}

	&__row {
		display: flex;
		justify-content: space-between;
		margin-top: 8px;
		padding-left: 14px;
	}

	&__rowText {
		color: rgba(235, 235, 245, 0.6);
		font-size: 12px;
		font-weight: 400;
		line-height: 16px;
		padding: 0;
		margin: 0;
	}

	&__value {
		color: #fff;
		font-size: 12px;
		font-weight: 500;
		line-height: 14px;
		padding: 0;
		margin: 0;
	}
}
