.banner {
	height: calc(100vh - 44px);
	min-height: 400px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 40px 24px;

	@media screen and (max-width: 767px) {
		padding: 40px 24px 60px 24px;
		min-height: calc(100vh - 44px);
		height: auto;
	}

	&__headline {
		color: rgba(255, 255, 255, 0.5);
		text-align: center;
		font-size: 18px;
		font-weight: 400;
		line-height: 132%;
		letter-spacing: 0.06px;
		margin: 0;

		@media screen and (max-width: 767px) {
			font-size: 16px;
		}
	}

	&__description_btn_wrapper {
		position: relative;
		width: 100%;
		max-width: 661px;
		text-align: center;
	}

	&__description {
		margin: 24px 0 0 0;
		color: #fff;
		font-size: 64px;
		font-weight: 600;
		line-height: 116%;
		letter-spacing: -1.2px;
		text-align: center;

		@media screen and (max-width: 767px) {
			margin-top: 8px;
			font-size: 48px;
		}
	}

	&__button {
		display: inline-flex;
		width: auto;
		padding: 18px 20px;
		justify-content: center;
		align-items: center;
		border-radius: 12px;
		background: #fff;
		margin: 48px auto 0;
		color: #7c59ff;
		font-size: 18px;
		font-weight: 600;
		line-height: 132%;
		letter-spacing: 0.2px;
		transition: 0.3s ease-in-out;

		@media screen and (max-width: 767px) {
			width: 100%;
			margin-top: 72px;
		}
	}
}
