.cards {
	padding-top: 40px;
	padding-bottom: 30px;

	@media screen and (max-width: 679px) {
		padding-bottom: 20px;
	}

	&__container {
		width: 100%;
		max-width: 1024px;
		margin-left: auto;
		margin-right: auto;
		padding: 0 24px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 40px;

		@media screen and (max-width: 679px) {
			flex-direction: column;
			gap: 0;
		}
	}

	&__content {
		width: 100%;
		max-width: 410px;
		display: flex;
		flex-direction: column;
	}

	&__imageWrapper {
		position: relative;

		@media screen and (max-width: 679px) {
			margin-top: 30px;
		}
	}

	&__image {
		@media screen and (max-width: 679px) {
			width: 100%;
			height: auto;
			aspect-ratio: auto;
		}
	}

	&__imageItem {
		position: absolute;
		bottom: -78px;
		left: 50%;
		max-width: 100vw;
		transform: translateX(-50%);
		height: auto;

		@media screen and (max-width: 679px) {
			bottom: -55px;
			max-width: 100%;
		}
	}

	&__title {
		margin: 0;
		color: #fff;
		font-size: 40px;
		font-weight: 600;
		line-height: 132%;
		letter-spacing: -0.45px;

		@media screen and (max-width: 993px) {
			font-size: 32px;
		}
	}

	&__description {
		margin: 25px 0 0;
		color: #fff;
		font-size: 18px;
		font-weight: 400;
		line-height: 132%;
		letter-spacing: 0.06px;

		@media screen and (max-width: 993px) {
			font-size: 16px;
			margin-top: 16px;
		}
	}
}
