@import "styles/mixins";

.communities {
	margin-top: 80px;
	position: relative;
	z-index: 2;

	@media screen and (max-width: 1199px) {
		margin-top: 60px;
	}

	@media screen and (max-width: 993px) {
		margin-top: 40px;
	}

	&__container {
		@include home-page-container;
	}

	&__headline {
		@include home-page-headline;

		margin: 0;
	}

	&__content {
		@include home-page-content;

		margin: 16px 0 0 0;

		@media screen and (max-width: 993px) {
			margin: 12px 0 0 0;
		}
	}

	&__list {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 24px;

		list-style: none;
		margin: 40px 0 0 0;
		padding: 0;

		@media screen and (max-width: 1199px) {
			grid-gap: 18px;
			margin: 30px 0 0 0;
		}

		@media screen and (max-width: 993px) {
			grid-gap: 16px;
			margin: 24px 0 0 0;
		}

		@media screen and (max-width: 767px) {
			grid-template-columns: repeat(2, 1fr);
			grid-template-areas:
				"first second"
				"third third";
		}

		@media screen and (max-width: 599px) {
			grid-template-columns: 1fr;
			grid-template-areas:
				"first"
				"second"
				"third";
		}
	}

	&__item {
		@media screen and (max-width: 767px) {
			&:nth-child(1) {
				grid-area: first;
			}

			&:nth-child(2) {
				grid-area: second;
			}

			&:nth-child(3) {
				grid-area: third;
			}
		}
	}

	&__link {
		display: flex;
		background-color: var(--communities-background);
		border-radius: 12px;
		padding: 24px;
		align-items: center;

		@media screen and (max-width: 1199px) {
			padding: 18px;
		}

		@media screen and (max-width: 993px) {
			padding: 16px 12px;
		}

		@media screen and (max-width: 599px) {
			padding: 31px 24px;
		}

		@media (hover: hover) and (pointer: fine) {
			&:hover .communities__linkName {
				color: var(--communities-link-count-text-color--hover);
			}
		}

		svg {
			width: 64px;
			height: 64px;

			@media screen and (max-width: 1199px) {
				width: 52px;
				height: 52px;
			}

			@media screen and (max-width: 993px) {
				width: 46px;
				height: 46px;
			}

			@media screen and (max-width: 599px) {
				width: 52px;
				height: 52px;
			}
		}
	}

	&__linkContent {
		display: flex;
		flex-direction: column;
		margin-left: 12px;

		@media screen and (max-width: 993px) {
			margin-left: 8px;
		}
	}

	&__linkName {
		color: var(--communities-link-name-text-color);
		font-size: 24px;
		font-weight: 600;
		line-height: 132%;
		letter-spacing: -0.3px;

		@media screen and (max-width: 1199px) {
			font-size: 20px;
		}

		@media (hover: hover) and (pointer: fine) {
			transition: 0.3s ease-in;
		}
	}

	&__linkArrow {
		display: none;

		@media screen and (max-width: 599px) {
			display: flex;
			margin-left: auto;

			svg {
				width: 20px;
				height: 12px;
			}
		}
	}

	&__linkCount {
		margin-top: 4px;
		color: var(--communities-link-count-text-color);
		font-size: 18px;
		font-weight: 400;
		line-height: 150%;

		@media screen and (max-width: 1199px) {
			margin-top: 0;
			font-size: 16px;
		}
	}
}
