@import "styles/mixins";

.benefits {
	margin-top: 80px;

	@media screen and (max-width: 993px) {
		margin-top: 60px;
	}

	@media screen and (max-width: 767px) {
		margin-top: 48px;
	}

	&__headline {
		@include home-page-headline;

		margin: 0;
	}

	&__container {
		@include home-page-container;

		position: relative;
	}

	&__navList {
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid var(--benefits-nav-list-border-color);

		list-style: none;
		margin: 40px 0 0 0;
		padding: 0;

		@media screen and (max-width: 1199px) {
			margin-top: 20px;
		}

		@media screen and (max-width: 993px) {
			margin-top: 24px;
		}

		@media screen and (max-width: 767px) {
			justify-content: flex-start;
			border-bottom: none;
			margin-right: -24px;
			margin-left: -24px;
			padding-left: 24px;
			padding-right: 24px;
			overflow: auto;
			gap: 16px;
			padding-bottom: 10px;

			&::-webkit-scrollbar {
				display: none;
				-webkit-appearance: none;
			}
		}
	}

	&__navItem {
	}

	&__navButton {
		display: flex;
		align-items: center;
		transition: 0.3s ease;

		padding-top: 24px;
		padding-bottom: 25px;
		border-bottom: 2px solid transparent;

		@media screen and (max-width: 993px) {
			padding-top: 16px;
			padding-bottom: 17px;
		}

		@media screen and (max-width: 767px) {
			padding: 15px 16px;
			border: 1px solid #7c59ff;
			border-radius: 42px;

			svg path {
				fill: #7c59ff;
			}

			.benefits__navText {
				color: #7c59ff;
			}
		}

		&___active {
			border-bottom: 2px solid var(--benefits-nav-border-color--active);

			@media screen and (max-width: 767px) {
				background-color: rgba(124, 89, 255, 0.2);
			}

			svg path {
				fill: var(--benefits-nav-svg-color--active);
			}

			.benefits__navText {
				color: var(--benefits-nav-text-color--active);
			}
		}

		&___activeMobile {
			@media screen and (max-width: 767px) {
				background-color: rgba(124, 89, 255, 0.2);
				border-bottom: 1px solid var(--benefits-nav-border-color--active);
			}
		}
	}

	&__navIcon {
		display: flex;
		justify-content: center;
		align-items: center;

		width: 30px;
		height: 30px;
		margin-right: 16px;

		svg path {
			transition: 0.3s ease;
		}

		@media screen and (max-width: 993px) {
			width: 24px;
			height: 24px;
			margin-right: 12px;
		}

		@media screen and (max-width: 767px) {
			margin-right: 8px;
		}
	}

	&__navText {
		color: var(--benefits-nav-text-color);
		font-size: 18px;
		font-weight: 600;
		line-height: 28px;
		letter-spacing: -0.3px;
		transition: 0.3s ease;

		margin: 0;

		@media screen and (max-width: 1199px) {
			font-size: 16px;
		}

		@media screen and (max-width: 767px) {
			line-height: 24px;
			letter-spacing: 0.2px;
			white-space: nowrap;
		}
	}

	&__content {
		display: flex;
		margin-top: 24px;
		border-radius: 12px;
		border: 1px solid var(--benefits-border-color);
		background: var(--benefits-background-color);
		z-index: 2;
		position: relative;

		@media screen and (max-width: 767px) {
			margin-top: 14px;
		}
	}

	&__contentItem {
		display: none;

		&___active {
			display: flex;
			flex: 1;
			min-height: 100%;
		}
	}

	&__triangle {
		position: absolute;
		bottom: -140px;
		left: 186px;
		z-index: 1;
	}

	&__circle {
		position: absolute;
		border: 1px solid rgba(210, 232, 181, 0.8);
		border-radius: 100%;
		width: 31px;
		height: 31px;
		backdrop-filter: blur(7px);
		background: rgba(206, 240, 163, 0.6);
		right: 0;
		top: 86px;
	}
}
