.bottom {
	display: flex;
	flex-direction: column;
	width: 100%;

	&__header {
		display: flex;
		align-items: center;
		padding: 0 16px;
		width: 100%;
		border-bottom: 1px solid rgba(84, 84, 88, 0.36);

		overflow: auto;
	}

	&__headerTabList {
		list-style: none;
		padding: 0;
		margin: 0;
		display: flex;
		align-items: center;
	}

	&__headerTab {
		margin-right: 16px;
	}

	&__tradingListTabRadio:checked + label {
		color: #fff;
		border-bottom: 2px solid #7c59ff;

		&:hover {
			color: #fff;
		}
	}

	&__headerTabButton {
		display: flex;
		color: rgba(235, 235, 245, 0.6);
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		padding-top: 12px;
		padding-bottom: 9px;
		border-bottom: 2px solid #7c59ff00;
		cursor: pointer;
		white-space: pre;

		@media (hover: hover) and (pointer: fine) {
			transition: 0.3s ease-in-out;

			&:hover {
				color: rgba(235, 235, 245, 0.8);
				border-bottom: 2px solid #7c59ff30;
			}
		}
	}

	&__headerCheckboxWrapper {
		display: flex;
		align-items: center;
		margin-left: auto;
	}

	&__headerAllPairsCheckbox {
		&:checked + label:before {
			background-color: #7c59ff;
			border-color: #7c59ff;
		}

		&:checked + label:after {
			opacity: 1;
		}
	}

	&__headerAllPairsLabel {
		color: rgba(235, 235, 245, 0.6);
		font-size: 14px;
		font-weight: 500;
		line-height: 16px;
		cursor: pointer;
		position: relative;

		&:after {
			opacity: 0;
			display: flex;
			width: 16px;
			height: 16px;
			content: "";
			background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0ibm9uZSIgdmlld0JveD0iMCAwIDI0IDI0Ij4KCQk8cGF0aAoJCQlzdHJva2U9IiNGRkYiCgkJCXN0cm9rZUxpbmVjYXA9InJvdW5kIgoJCQlzdHJva2VMaW5lam9pbj0icm91bmQiCgkJCXN0cm9rZVdpZHRoPSIzIgoJCQlkPSJNNCAxMi42MTFMOC45MjMgMTcuNSAyMCA2LjUiCgkJLz4KCTwvc3ZnPg==");
			border-radius: 4px;
			position: absolute;
			left: -22px;
			top: 0;
			transition: 0.3s ease-in-out;
		}

		&:before {
			display: flex;
			width: 16px;
			height: 16px;
			content: "";
			border-radius: 4px;
			border: 1px solid rgba(235, 235, 245, 0.6);
			position: absolute;
			left: -22px;
			transition: 0.3s ease-in-out;
		}

		@media (hover: hover) and (pointer: fine) {
			transition: 0.3s ease-in-out;

			&:hover {
				color: rgba(235, 235, 245, 0.8);

				&:before {
					border: 1px solid rgba(235, 235, 245, 0.8);
				}
			}
		}
	}

	&__headerAllOrders {
		color: #7c59ff;
		font-size: 14px;
		font-weight: 500;
		line-height: 16px;
		margin-left: 16px;
		text-align: center;

		@media (hover: hover) and (pointer: fine) {
			transition: 0.3s ease-in-out;

			&:hover {
				opacity: 0.8;
			}
		}
	}
}
