@import "styles/mixins";

.security360 {
	display: flex;
	justify-content: space-between;
	padding: 18px 32px;
	min-height: 100%;
	flex: 1;

	@media screen and (max-width: 579px) {
		flex-direction: column-reverse;
		padding: 24px;
	}

	&__content {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;
		padding: 26px 0;
		margin-right: 95px;

		@media screen and (max-width: 1299px) {
			margin-right: 60px;
		}

		@media screen and (max-width: 767px) {
			margin-right: 35px;
		}

		@media screen and (max-width: 579px) {
			max-width: 100%;
			margin-top: 24px;
			margin-right: 0;
			padding: 0;
		}
	}

	&__contentWrapper {
		margin-bottom: 16px;
	}

	&__headline {
		color: var(--benefits-headline-color);
		font-size: 32px;
		font-weight: 600;
		line-height: 125%;
		letter-spacing: -1px;

		margin: 0;
		max-width: 440px;

		@media screen and (max-width: 1299px) {
			font-size: 28px;
		}

		@media screen and (max-width: 993px) {
			font-size: 24px;
		}
	}

	&__contentText {
		margin: 16px 0 0 0;
		color: var(--benefits-content-text-color);
		font-size: 18px;
		font-style: normal;
		font-weight: 400;
		line-height: 28px; /* 155.556% */
		letter-spacing: -0.3px;

		@media screen and (max-width: 1299px) {
			font-size: 16px;
		}

		@media screen and (max-width: 993px) {
			margin: 12px 0 0 0;
		}
	}

	&__contentTextSecondary {
		margin: 12px 0 0 0;
		color: var(--benefits-content-text-color);
		font-size: 18px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px; /* 133.333% */
		letter-spacing: -0.3px;

		@media screen and (max-width: 1299px) {
			font-size: 16px;
		}

		@media screen and (max-width: 993px) {
			margin: 8px 0 0 0;
		}
	}

	&__headlineSecondary {
		margin: 24px 0 0 0;
		color: var(--benefits-content-secondary-headline-color);
		font-size: 18px;
		font-style: normal;
		font-weight: 600;
		line-height: 28px;
		letter-spacing: -0.3px;

		@media screen and (max-width: 1299px) {
			font-size: 16px;
		}

		@media screen and (max-width: 993px) {
			margin: 16px 0 0 0;
		}
	}

	&__button {
		@include home-page-main-button;

		min-width: 155px;

		@media screen and (max-width: 579px) {
			width: 100%;
		}
	}

	&__svg {
		display: flex;
		justify-content: center;
		align-items: center;

		@media screen and (max-width: 767px) {
			align-items: flex-start;
		}

		@media screen and (max-width: 579px) {
			width: 100%;
			max-width: 300px;
			margin-left: auto;
			margin-right: auto;
			align-self: center;
		}
	}
}
