.balances {
	display: flex;
	flex-direction: column;
	padding: 16px 16px 12px;

	&__text {
		color: rgba(235, 235, 245, 0.6);
		font-size: 12px;
		font-weight: 400;
		line-height: 14px;
		margin: 0;
		padding: 0;
	}

	&__amount {
		color: #fff;
		font-size: 12px;
		font-weight: 500;
		line-height: 14px;
	}

	&__currency {
		padding-left: 2px;
	}

	&__row {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		margin-top: 8px;

		&:first-child {
			margin-top: 0;
		}
	}
}
