@import "~@btc-alpha/ui-components/dist/styles/functions";
@import "~@btc-alpha/ui-components/dist/styles/media";
@import "~@btc-alpha/ui-components/dist/styles/mixins";

.root {
	width: 100%;
	max-width: rems(440);

	.type {
		margin-bottom: rems(24);
	}

	form {
		display: flex;
		flex-direction: column;
		gap: rems(16);
	}

	.captcha {
		align-self: center;
		max-height: 100px;
		transition: 0.3s ease-in-out;

		&.closed {
			max-height: 0;
			overflow: hidden;
		}
	}

	.rules {
		margin-top: rems(-12);
	}

	.agreement {
		max-width: rems(340);

		span,
		a {
			@include font(16, 400, 20, true);
		}
	}

	.submit {
		margin-top: rems(32);
	}
}
