@import "~@btc-alpha/ui-components/dist/styles/functions";
@import "~@btc-alpha/ui-components/dist/styles/media";
@import "~@btc-alpha/ui-components/dist/styles/mixins";

.root {
	.title {
		margin: rems(16) 0;
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: rems(24);
		color: var(--alpha-ui-color-label-tertiary);
		@include font(16, 400, 20);

		&:before,
		&:after {
			content: "";
			flex-grow: 1;
			height: rems(1);
			background-color: var(--alpha-ui-color-label-tertiary);
		}
	}

	.buttons {
		display: flex;
		flex-direction: row;
		align-items: stretch;
		gap: rems(8);

		button {
			flex-grow: 1;
			flex-basis: 0;
			overflow: hidden;
		}
	}
}
