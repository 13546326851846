@import "styles/mixins";

.cryptoCards {
	margin-top: 80px;

	@media screen and (max-width: 1199px) {
		margin-top: 60px;
	}

	@media screen and (max-width: 767px) {
		margin-top: 48px;
	}

	&__circle {
		border: 1px solid rgba(210, 232, 181, 0.8);
		border-radius: 100%;
		width: 200px;
		height: 200px;
		backdrop-filter: blur(7px);
		background: rgba(206, 240, 163, 0.6);
		position: absolute;
		right: 200px;
		top: -57px;
		z-index: 1;
	}

	&__container {
		@include home-page-container;

		position: relative;
	}

	&__gradient {
		position: relative;
		z-index: 2;
		display: flex;
		align-items: center;
		flex-direction: column;
		padding-top: 60px;

		border-radius: 12px;
		background-image: conic-gradient(from 180deg at 50% 62.75%, #252629 0deg, #815FFF 360deg);

		@media screen and (max-width: 1199px) {
			padding-top: 40px;
		}

		@media screen and (max-width: 993px) {
			padding-top: 32px;
		}

		@media screen and (max-width: 767px) {
			overflow: hidden;
		}

		@media screen and (max-width: 579px) {
			align-items: flex-start;
			padding-top: 24px;
		}
	}

	&__headline {
		@include home-page-headline;

		color: #fff;
		margin: 0;

		@media screen and (max-width: 579px) {
			text-align: left;
			margin: 0 24px;
		}
	}

	&__content {
		@include home-page-content;

		margin: 16px 0 0 0;
		width: 560px;
		color: #fff;

		@media screen and (max-width: 767px) {
			text-align: center;
			width: auto;
			max-width: 400px;
		}

		@media screen and (max-width: 579px) {
			text-align: left;
			max-width: unset;
			margin: 16px 24px 0 24px;
		}
	}

	&__button {
		@include home-page-main-button(true);

		min-width: 161px;
		margin-top: 24px;

		@media screen and (max-width: 579px) {
			text-align: left;
			max-width: unset;
			margin: 24px 24px 0 24px;
			align-self: stretch;
			height: 54px;
		}
	}

	&__img {
		max-width: 37%;
		margin-top: 58px;

		@media screen and (max-width: 1199px) {
			max-width: 80%;
		}

		@media screen and (max-width: 993px) {
			margin-top: 32px;
		}

		@media screen and (max-width: 579px) {
			align-self: center;
		}
	}
}
