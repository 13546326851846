@import "~@btc-alpha/ui-components/dist/styles/functions";
@import "~@btc-alpha/ui-components/dist/styles/media";
@import "~@btc-alpha/ui-components/dist/styles/mixins";

.layout {
  --about-width: #{rems(700)};

  @include tablet-landscape {
    --about-width: #{rems(520)};
  }

	position: relative;
	padding-left: calc(var(--about-width) + #{rems(16)});

	.content {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.about {
		position: absolute;
		width: var(--about-width);
		left: rems(16);
		top: rems(16);
		height: calc(100% - #{rems(32)});
		display: flex;
		flex-direction: column;
		padding: rems(32);
		padding-bottom: calc(var(--about-width) * 0.91);
		border-radius: rems(28);
		border: rems(1) solid var(--alpha-ui-color-separator-opaque);
		background-color: var(--alpha-ui-color-background-base-3);
		background-image: url("/assets/images/auth/preview.png"),
			linear-gradient(to right, transparent, rgba(124, 89, 255, 0.1));
		background-repeat: no-repeat;
		background-size: calc(100% - #{rems(32)}), 100%;
		background-position: left bottom;

		.subtitle {
			margin-bottom: rems(12);
			color: var(--alpha-ui-color-accent-purple);
			@include font(14, 500, 20);
		}

		.title {
			flex-grow: 1;
			@include font(44, 500, 44);
		}

		.description {
			color: var(--alpha-ui-color-label-secondary);
			@include font(16, 400, 20);
		}
	}

	@include tablet-portrait {
		padding-left: 0;

		.about {
			display: none;
		}
	}
}
