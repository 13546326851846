@import "~@btc-alpha/ui-components/dist/styles/functions";
@import "~@btc-alpha/ui-components/dist/styles/media";

.menuSections {
  display: flex;
  flex-direction: column;
  gap: rems(16);

  & > div {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: rems(4);

    & > label {
      font-size: rems(12);
      font-weight: 500;
      line-height: rems(16);
      margin-bottom: rems(4);
      padding: 0 rems(12);
      text-transform: uppercase;
      color: var(--alpha-ui-color-label-secondary);
    }

    &:not(:last-child) {
      padding-bottom: rems(16);
      border-bottom: rems(1) solid var(--alpha-ui-color-separator-opaque);
    }
  }
}
