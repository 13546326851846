@import "~@btc-alpha/ui-components/dist/styles/functions";
@import "~@btc-alpha/ui-components/dist/styles/media";
@import "~@btc-alpha/ui-components/dist/styles/mixins";

.root {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	max-width: rems(460);

	.submit {
		width: 100%;
		max-width: rems(440);
		margin-top: rems(32);
	}
}
