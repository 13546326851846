@import "styles/mixins";

.usp {
	margin-top: 32px;

	@media screen and (max-width: 1199px) {
		margin-top: 24px;
	}

	@media screen and (max-width: 993px) {
		margin-top: 16px;
	}

	@media screen and (max-width: 767px) {
		margin-top: 48px;
	}

	&__container {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-column-gap: 24px;
		grid-row-gap: 30px;
		grid-template-areas:
			"main main main main"
			"second third fourth fifth";

		@include home-page-container;

		@media screen and (max-width: 1199px) {
			grid-gap: 24px;
		}

		@media screen and (max-width: 993px) {
			grid-gap: 16px;
		}

		@media screen and (max-width: 767px) {
			grid-template-columns: repeat(2, 1fr);
			grid-template-areas:
				"main main"
				"second third"
				"fourth fifth";
		}

		@media screen and (max-width: 469px) {
			grid-template-columns: 1fr;
			grid-template-areas:
				"main"
				"second"
				"third"
				"fourth"
				"fifth";
		}
	}

	&__item {
		display: flex;
		padding: 24px 32px;
		justify-content: space-between;
		border-radius: 16px;
		overflow: hidden;
		background: var(--usp-background-color);
		position: relative;

		svg {
			position: absolute;
			bottom: 0;
			right: 0;
			z-index: 1;
		}

		&:nth-child(2) {
			grid-area: second;
		}

		&:nth-child(3) {
			grid-area: third;
		}

		&:nth-child(4) {
			grid-area: fourth;
		}

		&:nth-child(5) {
			grid-area: fifth;
		}

		@media screen and (max-width: 1199px) {
			padding: 18px 20px 24px 20px;
		}

		@media screen and (max-width: 993px) {
			padding: 16px 16px 24px 16px;
		}

		@media screen and (max-width: 469px) {
			padding: 24px;
		}

		&___main {
			grid-area: main;
			align-items: center;

			@media screen and (max-width: 767px) {
				flex-direction: column;
			}

			.usp__itemHeadline {
				font-size: 32px;

				@media screen and (max-width: 1199px) {
					font-size: 26px;
				}

				@media screen and (max-width: 993px) {
					font-size: 24px;
				}
			}

			.usp__itemContentWrapper {
				margin-right: 20px;

				@media screen and (max-width: 767px) {
					margin-right: 0;
				}
			}

			.usp__itemContent {
				margin-top: 8px;
				max-width: unset;
			}
		}
	}

	&__itemContentWrapper {
	}

	&__itemHeadline {
		color: var(--usp-headline-color);
		font-size: 24px;
		font-weight: 600;
		line-height: 132%;
		letter-spacing: -0.3px;
		margin: 0;
		position: relative;
		z-index: 2;

		@media screen and (max-width: 1199px) {
			font-size: 22px;
		}

		@media screen and (max-width: 993px) {
			font-size: 20px;
		}
	}

	&__itemContent {
		color: var(--usp-text-color);
		font-size: 18px;
		font-weight: 400;
		line-height: 132%;
		letter-spacing: 0.06px;
		max-width: 175px;
		margin: 12px 0 0 0;
		position: relative;
		z-index: 2;

		@media screen and (max-width: 1199px) {
			font-size: 16px;
		}

		@media screen and (max-width: 993px) {
			margin: 8px 0 0 0;
		}

		@media screen and (max-width: 469px) {
			max-width: 100%;
		}
	}

	&__itemButton {
		@include home-page-main-button;

		min-width: 179px;

		@media screen and (max-width: 767px) {
			margin-top: 24px;
			width: 100%;
		}
	}
}
