@import "src/assets/styles/common/mixins";

.verification_page_container_outer {
	@include page-content-outer();

	background-color: #101014;

	> div {
		margin-bottom: 2px;
	}
}

.verification_dia_page_container_outer {
	@include page-content-outer(md);
	padding: 20px;
}

.pageContainer {
	padding: 16px 24px;
	width: 100%;
}

.breadcrumbs {
	@include flexbox(flex-start, center);

	padding: 3px 0 0 34px;
	width: 100%;
	height: 47px;
	border-bottom: 1px solid var(--divider-border);
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 14px;
}

.verification_diia {
	@include card(false);
	@include flexbox(flex-start, center, column);
	max-width: get-breakpoint-value(md);
	padding: 30px 40px;
	width: 100%;
	margin: 10px auto 0;

	@include max-w(md) {
		padding: 20px;
	}
}

.verification_diia_qr_code {
	@include flexbox(center, center, column);
	width: 100%;

	> span {
		color: var(--color-secondary);
		font-size: 14px;
		margin-bottom: 8px;
		text-align: center;
	}
}

.verification_diia_error {
	@include flexbox(flex-start, center, column);
	font-size: 16px;
	color: var(--color-secondary);
	margin-bottom: 20px;

	> img {
		margin-top: 30px;
	}
}

.verification_diia_steps {
	@include flexbox(flex-start, flex-start, column);
	width: 100%;
	margin-bottom: 20px;
}

.verification_diia_step {
	font-size: 16px;
	color: var(--color-secondary);
	margin-bottom: 5px;
}

.verification_info_container {
	@include flexbox(flex-start, center);
	justify-content: space-between;
	width: 100%;
	margin: 10px 0 15px;
	padding: 8px 16px;

	@include max-w(lg) {
		@include flexbox(flex-start, flex-start, column);
	}
}

.verification_content_section {
	@include flexbox(flex-start, center);

	@include max-w(lg) {
		width: 100%;
		@include flexbox(flex-start, flex-start);

		&:not(:last-child) {
			margin-bottom: 15px;
		}
	}
}

.verification_avatar {
	@include transition(color);
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 12px;

	i {
		font-size: 55px;
		color: var(--ui-profile-avatar-background) !important;
	}
}

.verification_row_group {
	@include flexbox(flex-start, center, column);
	align-items: flex-start;

	&:first-child {
		margin-bottom: 5px;
	}

	& span:not(:last-of-type) {
		margin-right: 22px;
	}

	> a i {
		font-size: 16px;
	}

	@include max-w(lg) {
		flex-direction: column;
		align-items: flex-start;

		> span:not(:last-of-type) {
			margin-right: 0;
			margin-bottom: 8px;
		}
	}
}

.verification_row {
	@include flexbox(flex-start, center);

	&:first-child {
		margin-bottom: 5px;
	}

	> span:not(:last-of-type) {
		margin-right: 22px;
	}

	> a i.ai {
		font-size: 16px;
	}

	@include max-w(lg) {
		flex-direction: column;
		align-items: flex-start;

		> span:not(:last-of-type) {
			margin-right: 0;
			margin-bottom: 8px;
		}
	}
}

.verification_header_title {
	@include header-title();
	font-size: 24px;
	line-height: 28px;
	margin: 0 10px 0 0;
}

.verification_vip_status {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	color: var(--color-gold);
	font-size: 16px;
	margin-right: 10px;

	i {
		color: var(--color-gold) !important;
		font-size: 16px;
		margin: 0 4px 4px 0;
	}
}

.verification_email {
	font-size: 16px;
	line-height: 23px;
	color: var(--color-primary);
}

.verification_uid {
	font-size: 14px;
	line-height: 19px;
	color: var(--color-primary);

	i {
		@include transition(color);
	}

	&:hover {
		i {
			color: var(--color-blue);
		}
	}
}

.verification_span_secondary {
	font-size: 14px;
	line-height: 19px;
	color: var(--color-secondary);
}

.level_container {
	@include flexbox(flex-start, flex-start, column);
	margin-top: 15px;
	width: 100%;
}

.level_card {
	@include card();
	width: 100%;
	padding: 0;
	margin: 0 0 15px 0;
}

.level_card_header {
	@include card-header();
	width: 100%;
	min-height: 100px;
	padding: 16px 56px 12px 16px;
	border-bottom: 1px solid var(--table-header-border-color);
	background-color: #3a3a3c40;

	@include max-w(xxl) {
		min-height: 55px;
	}

	@include max-w(md) {
		padding: 16px;
		flex-direction: column;
		gap: 20px;

		> span:nth-of-type(1) {
			margin-top: 12px;
		}
	}
}

.level_card_title {
	@include card-title();
	@include flexbox();
	margin: 0;

	i {
		font-size: 36px;
		@include transition-long(all);
	}

	&.identity {
		i {
			color: var(--color-green);
		}
	}

	&.address {
		i {
			color: var(--color-purple);
		}
	}

	&.finance {
		i {
			color: var(--color-yellow-dark);
		}
	}

	&:hover {
		&.identity {
			i {
				filter: drop-shadow(0 0 0.75rem var(--color-green));
			}
		}

		&.address {
			i {
				filter: drop-shadow(0 0 0.75rem var(--color-purple));
			}
		}

		&.finance {
			i {
				filter: drop-shadow(0 0 0.75rem var(--color-yellow-dark));
			}
		}
	}

	@include max-w(xxl) {
		i {
			font-size: 28px;
		}
	}

	@include max-w(md) {
		width: 100%;

		> i.ai {
			margin-right: 10px;
		}
	}
}

.level_card_title_label {
	@include flexbox(flex-start, flex-start, column);
	margin-left: 14px;

	span {
		color: var(--color-primary);
	}

	span:nth-of-type(2) {
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 23px;
		margin-top: 8px;
		color: var(--color-secondary);
	}
}

.level_verified {
	@include flexbox(flex-end, center);
	color: var(--color-green);
	font-size: 16px;
	line-height: 23px;
	text-align: right;
	white-space: nowrap;

	i {
		font-size: 36px;
		margin-right: 10px;
		color: var(--color-green) !important;
	}
}

.level_not_verified {
	white-space: nowrap !important;
	font-weight: normal;
	font-size: 16px;
	line-height: 23px;
	text-align: right;
	color: var(--color-red);
}

.level_card_body_rejected {
	@include card-body();
	border-color: var(--color-red) !important;
	border-bottom: 1px solid;
	border-top: 1px solid;
	background-color: var(--info-snack-color-red-a);

	> span,
	i {
		color: var(--color-red) !important;
	}
}

.level_rejected {
	color: var(--color-red) !important;
	font-size: 16px;
	line-height: 23px;
	text-align: right;
	display: flex;
	justify-content: flex-end;
	align-items: center;

	i {
		font-size: 36px;
		margin-right: 10px;
		color: var(--color-red) !important;
	}
}

.level_card_body {
	@include card-body();
}

.level_moderation {
	color: var(--color-yellow);
	font-size: 16px;
	line-height: 23px;
	text-align: right;
	display: flex;
	justify-content: flex-end;
	align-items: center;

	i {
		font-size: 36px;
		margin-right: 10px;
		color: var(--color-yellow) !important;
	}
}

.level_update_button_container {
	@include flexbox(flex-end);
	gap: 20px;
}

.level_update_button {
	background: transparent;
	color: var(--color-primary);
	text-decoration: underline;
	font-weight: normal;
	font-size: 16px;
	line-height: 23px;
	text-align: right;
	cursor: pointer;

	&:hover,
	&:active,
	&:focus {
		color: var(--color-primary);
	}
}

.level_cancel_button {
	background: transparent;
	color: var(--color-red);
	font-weight: normal;
	font-size: 16px;
	line-height: 23px;
	text-align: right;
	cursor: pointer;

	&:hover,
	&:active,
	&:focus {
		color: var(--color-red);
	}
}
.level_update_button {
	background: transparent;
	color: var(--color-primary);
	text-decoration: underline;
	font-weight: normal;
	font-size: 16px;
	line-height: 23px;
	text-align: right;
	cursor: pointer;

	&:hover,
	&:active,
	&:focus {
		color: var(--color-primary);
	}
}

.level_bonus {
	@include flexbox(flex-start, center);
	padding: 18px 56px 18px 60px;
	gap: 20px;

	&:nth-child(3) {
		border-top: 1px solid var(--divider-border);
	}

	i {
		font-size: 20px;
		margin-right: 8px;
		color: var(--icon-primary);
	}

	&.active {
		i {
			color: var(--color-green) !important;
		}
	}

	span {
		font-weight: normal;
		font-size: 14px;
		line-height: 19px;
		color: var(--color-secondary);
	}

	@include max-w(md) {
		padding: 16px 24px 24px 16px;
		@include flexbox(flex-end, center, row-reverse);
	}
}

.header_title {
	@include header-title();
}

.form_sent_successfully {
	@include flexbox(flex-start, center, column);
	padding: 40px;
	width: 100%;

	> i {
		font-size: 110px;
		margin-bottom: 42px;
	}

	> span {
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 23px;
		text-align: center;
		color: var(--color-secondary);
		margin-bottom: 50px;
		max-width: 465px;
	}
}

.form_button_group {
	@include buttons-group();
	max-width: 340px;
}

.form_container {
	width: 100%;
	display: grid;
	grid-template: auto / 1fr 1fr;
	grid-template-areas:
		"form-base form-docs"
		"submit .";
	grid-gap: 20px;
	margin: 10px 0 40px;

	> div {
		&:nth-child(1) {
			grid-area: form-base;
		}
		&:nth-child(2) {
			grid-area: form-docs;
		}
	}

	> button:last-child {
		grid-area: submit;
	}

	@include max-w(xxl) {
		grid-template: auto / 1fr 1fr;
		grid-template-areas:
			"form-base form-docs"
			"submit .";
	}

	@include max-w(xl) {
		grid-template: auto / 1fr;
		grid-gap: 0;
		grid-template-areas:
			"form-base"
			"form-docs"
			"submit";
	}

	&.diia {
		grid-template-areas:
			"diia diia"
			"form-base form-docs"
			"submit .";

		@include max-w(xxl) {
			grid-template: auto / 1fr 1fr;
			grid-template-areas:
				"diia diia"
				"form-base form-docs"
				"submit .";
		}

		@include max-w(xl) {
			grid-template: auto / 1fr;
			grid-row-gap: 20px;
			grid-template-areas:
				"diia"
				"form-base"
				"form-docs"
				"submit";
		}
	}
}

.form_section {
	@include card(false);
	display: grid;
	grid-template: auto / 1fr;
	align-content: flex-start;
	grid-row-gap: 20px;
	padding: 16px;
	margin: 0;

	@include max-w(xxl) {
		padding: 30px 40px;
		min-height: auto;
	}

	@include max-w(xl) {
		grid-row-gap: 25px;
		padding: 20px;

		> div {
			margin-top: 0;
		}
	}
}

.form_label {
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 23px;
	color: var(--color-secondary);
}

.form_gender {
	margin-top: 20px;

	.label {
		font-weight: normal;
		font-size: 16px;
		line-height: 23px;
		color: var(--color-secondary);
	}

	.input {
		@include flexbox();
		margin: 18px auto;
		gap: 20px;

		.error_text {
			margin-bottom: 18px;
		}
	}
}

.form_phone {
	width: 100%;
	display: grid;
	grid-template: auto / 1fr;
	grid-gap: 18px;
	margin-top: 20px;

	> span {
		font-weight: normal;
		font-size: 16px;
		line-height: 23px;
		color: var(--color-secondary);
	}
}

.form_document_upload {
	display: grid;
	margin-top: 20px;
	grid-template: auto / 150px 1fr;
	grid-gap: 24px;

	@include max-w(xxl) {
		grid-column-gap: 40px;
	}

	@include max-w(xs) {
		grid-template: auto / 1fr;
		grid-column-gap: 0;
	}
}

.form_address {
	display: grid;
	grid-template: auto / 1fr 1fr;
	grid-template-areas:
		"address address"
		"region region"
		"postal-code city";
	grid-gap: 26px;

	@include max-w(xs) {
		grid-template: auto / 1fr;
		grid-template-areas:
			"address address"
			"region region"
			"postal-code postal-code"
			"city city";
	}

	> div {
		&:nth-of-type(1) {
			grid-area: address;
		}
		&:nth-of-type(2) {
			grid-area: region;
		}
		&:nth-of-type(3) {
			grid-area: postal-code;
		}
		&:nth-of-type(4) {
			grid-area: city;
		}
	}
}

.form_download_questionnaire {
	@include flexbox(flex-start, flex-start, column);
	color: var(--color-blue);

	&.error {
		color: var(--color-red);
		i,
		a {
			color: var(--color-red);
		}
	}

	i {
		font-size: 52px;
		margin-bottom: 15px;
	}

	a {
		color: var(--color-blue);
	}

	i,
	a {
		margin-left: auto;
		margin-right: auto;
	}
}

.form_upload_questionnaire {
	position: relative;
	@include flexbox(flex-start, flex-start, column);
	border-radius: 5px;
	border: 2px dashed var(--input-normal);
	min-height: 250px;
	height: 250px;
	font-weight: normal;
	font-size: 16px;
	line-height: 23px;
	text-align: center;
	color: var(--color-secondary);
	padding: 5px;
	width: 100%;
	cursor: pointer;

	&.error {
		border-color: var(--color-red);
		color: var(--color-red);

		i {
			color: var(--color-red);
		}
	}

	i {
		font-size: 50px;
	}

	span {
		width: 100%;
		padding: 0 70px;
	}

	i,
	span {
		margin: auto;
	}

	input {
		display: none;
	}
}

.form_questionnaire_preview {
	position: relative;
	@include flexbox(flex-start, flex-start, column);

	background: var(--dropzone-preview-background);
	padding: 22px 48px;
	border-radius: 5px;
	height: 100%;
	width: 100%;

	img {
		position: absolute;
		inset: 0;
		margin: auto;
		width: auto;
		height: auto;
		max-width: 100%;
		max-height: 200px;
		border-radius: 15px;
	}
}

.form_questionnaire_error {
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 120%;
	margin-top: 6px;
	width: 100%;
	text-align: left;
	color: var(--color-red);

	i {
		font-size: 12px;
		margin-right: 5px;
		color: var(--color-red);
	}
}

.diia_container {
	@include flexbox(flex-start, center);
	grid-area: diia;
	padding: 14px 20px;
	background: var(--card-background-color);
	border: 1px solid var(--card-border-color);
	border-radius: 12px;
	width: 100%;
	grid-column: 1 / 3;
	position: relative;

	> img,
	> span,
	> i {
		z-index: 2;
	}

	> span {
		font-size: 16px;
		color: var(--color-primary);
		font-weight: 500;
		margin-left: 10px;
		text-align: left;
	}

	> i {
		margin-left: auto;
		color: var(--color-primary);
		font-size: 14px;
	}
}

.diia_modal_content {
	> label:not(:last-child) {
		margin-bottom: 20px;
	}
}
