@import "src/assets/styles/common/mixins";

.fake_link {
	@include flexbox(center, center);
	padding-right: 10px;
	margin-right: 10px;
	color: var(--color-primary);
	border-right: 1px solid var(--color-disabled);
	cursor: pointer;

	&:hover {
		color: var(--color-blue);
	}

	> i {
		font-size: 10px;
		margin-right: 16px;
	}
}

.page_content_container {
	@include flexbox(flex-start, flex-start, column);
	background: transparent;
	width: 100%;
	max-width: 100%;
	margin: 0 auto;

	@include max-w(xl) {
		padding: 16px;
	}
}

.breadcrumbs {
	@include flexbox(flex-start, center);
	padding: 3px 0 0 34px;
	width: 100%;
	height: 47px;
	border-bottom: 1px solid var(--divider-border);
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 14px;
	margin-bottom: 0 !important;
	background-color: #101014;
}
