.list {
	display: flex;
	padding-bottom: 36px;
	position: relative;

	&__loading {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: -10px;
	}

	&__empty {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&__listWrapper {
		display: flex;
		flex-direction: column;
		list-style: none;
		margin: 0;
		padding: 0;
		white-space: nowrap;

		&:first-child {
			.list__headlineText:first-child {
				margin-left: 16px;
			}

			.list__itemElem:first-child {
				margin-left: 16px;
			}

			.list__item {
				position: relative;

				&::before {
					content: "";
					position: absolute;
					left: 0;
					top: 0;
					bottom: 0;
					width: 250px;
					border-left: 2px solid #20b26c;
					background: linear-gradient(90deg, rgba(50, 215, 75, 0.08) 0%, rgba(50, 215, 75, 0) 100%);
				}

				&___sell {
					&::before {
						border-left: 2px solid #ef454a;
						background: linear-gradient(
							90deg,
							rgba(255, 69, 58, 0.08) 0%,
							rgba(255, 69, 58, 0) 100%
						);
					}
				}
			}
		}

		&___scroll {
			position: relative;
			overflow: hidden;
		}

		&___arrows {
			& + .list__listWrapper {
				background-color: #1c1c1e;
			}
		}
	}

	&__listContainer {
		display: flex;
		flex-direction: column;

		&___scroll {
			overflow: auto;
			scrollbar-width: none;

			-ms-overflow-style: none;

			&::-webkit-scrollbar {
				width: 0;
				height: 0;
			}

			.list__item:hover {
				.list__itemButtonLeft {
					&___show {
						opacity: 1;
						pointer-events: auto;
					}
				}

				.list__itemButtonRight {
					&___show {
						opacity: 1;
						pointer-events: auto;
					}
				}
			}
		}
	}

	&__items {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	&__item {
		display: flex;
		align-items: center;
		list-style: none;
		margin: 0 0 2px 0;
		height: 36px;

		&___sell {
			.list__itemElem___direction span {
				color: #ef454a;
			}
		}
	}

	&__itemElem {
		margin: 0 0 0 8px;

		span {
			display: flex;
			color: #fff;
			font-size: 14px;
			font-weight: 400;
			line-height: 20px;
		}

		.list__itemCurrency {
			display: inline-flex;
			color: rgba(235, 235, 245, 0.6);
			font-size: 12px;
			font-weight: 400;
			line-height: 16px;
			margin-left: 3px;
		}

		&___direction span {
			color: #20b26c;
		}
	}

	&__itemState {
		text-transform: capitalize;
	}

	&__itemWithCurrency {
		display: inline-flex;
		align-items: flex-end;
	}

	&__headline {
		display: flex;
		list-style: none;
		align-items: center;
		padding: 0;
		margin: 0;

		color: rgba(235, 235, 245, 0.6);
		font-size: 12px;
		font-weight: 400;
		line-height: 14px;
	}

	&__headlineText {
		margin-left: 8px;
		padding-top: 8px;
		padding-bottom: 8px;
	}

	&__itemButton {
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 4px;
		background: #2c2c2e;
		padding: 0 10px;
		height: 24px;
		position: relative;

		span {
			color: #fff;
			font-size: 12px;
			font-weight: 500;
			line-height: 20px;
			transition: 0.3s ease-in-out;
		}

		@media (hover: hover) and (pointer: fine) {
			transition: 0.3s ease-in-out;

			&:hover {
				opacity: 0.7;
			}
		}

		&:disabled {
			.list__itemButtonLoader {
				opacity: 1;
			}

			span {
				opacity: 0;
			}

			@media (hover: hover) and (pointer: fine) {
				transition: 0.3s ease-in-out;

				&:hover {
					cursor: progress;
					opacity: 1;
				}
			}
		}
	}

	&__itemButtonLoader {
		position: absolute;
		transition: 0.3s ease-in-out;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		height: 18px;
		opacity: 0;
	}

	&__listButtonLeft {
		position: absolute;
		width: 40px;
		left: 0;
		top: 0;
		height: 100%;
		background: linear-gradient(
			90deg,
			var(--main-background-color),
			var(--main-background-color),
			transparent
		);
		color: #fff;
		opacity: 0;
		pointer-events: none;
		transition: 0.3s ease-in-out;
		padding-right: 10px;

		svg {
			transform: rotate(90deg);
			height: 20px;
			width: 20px;
		}

		&___show {
			opacity: 1;
			pointer-events: auto;
		}
	}

	&__listButtonRight {
		position: absolute;
		width: 40px;
		right: 0;
		top: 0;
		height: 100%;
		background: linear-gradient(90deg, transparent 0, #1c1c1e 60%, #1c1c1e 100%);
		color: #fff;
		opacity: 0;
		pointer-events: none;
		transition: 0.3s ease-in-out;
		padding-left: 15px;

		svg {
			transform: rotate(-90deg);
			height: 20px;
			width: 20px;
		}

		&___show {
			opacity: 1;
			pointer-events: auto;
		}
	}

	&__itemButtonLeft {
		position: absolute;
		width: 40px;
		left: 0;
		height: 36px;
		background: transparent;
		color: #fff;
		opacity: 0;
		pointer-events: none;
		transition: 0.3s ease-in-out;
		padding-right: 10px;

		svg {
			transform: rotate(90deg);
			height: 20px;
			width: 20px;
		}

		&___show {
			opacity: 0;
			pointer-events: auto;
		}
	}

	&__itemButtonRight {
		position: absolute;
		width: 40px;
		right: 0;
		height: 36px;
		background: transparent;
		color: #fff;
		opacity: 0;
		pointer-events: none;
		transition: 0.3s ease-in-out;
		padding-left: 15px;

		svg {
			transform: rotate(-90deg);
			height: 20px;
			width: 20px;
		}

		&___show {
			opacity: 0;
			pointer-events: auto;
		}
	}
}
