@import "~@btc-alpha/ui-components/dist/styles/functions";
@import "~@btc-alpha/ui-components/dist/styles/media";

.deposit {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	gap: rems(72);

	.main {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		gap: rems(12);

		.info {
			display: flex;
			flex-direction: column;
			align-items: stretch;
			gap: rems(12);
			width: 50%;
			max-width: 440px;

			@include tablet-portrait {
				width: 100%;
				max-width: none;
			}

			& > header {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;

				& > span {
					font-size: rems(16);
					font-weight: 500;
					line-height: rems(20);
				}
			}

			& > .faq {
				display: flex;
				flex-direction: column;
				align-items: stretch;
				gap: rems(8);

				&:not(:last-child) {
					padding-bottom: rems(12);
					border-bottom: rems(1) solid var(--alpha-ui-color-separator-opaque);
				}

				p:first-child {
					margin-top: 0;
				}

				p:last-child {
					margin-bottom: 0;
				}
			}
		}

		@include tablet-portrait {
			flex-direction: column;
			gap: rems(50);
		}
	}

	.history {
		h2 {
			padding: 0;
			margin: 0;
			margin-bottom: rems(20);
			font-size: rems(20);
			font-weight: 500;
			line-height: rems(28);
		}
	}
}

.form {
	width: 100%;
	flex-grow: 1;

	.step {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		gap: rems(16);
	}

	.currencies span:not(:first-child):not(:nth-child(2)):last-child {
		display: none;
	}

	.defaults {
		display: flex;
		flex-direction: row;
		align-items: stretch;
		gap: rems(4);
		margin-top: rems(-12);

		button {
			flex-grow: 1;
		}
	}

	.error {
		font-size: rems(16);
		font-weight: 400;
		line-height: rems(22);
		color: var(--alpha-ui-color-accent-red);
	}

	.qr {
		margin-top: rems(8);
	}

	.values {
		margin-top: rems(36);
	}

	.text {
		margin-bottom: rems(20);

		& > span {
			font-size: rems(16);
			font-weight: 400;
			line-height: rems(22);
			color: var(--alpha-ui-color-label-secondary);
		}

		& > p {
			padding: 0;
			margin: rems(8) 0;
			font-size: rems(16);
			font-weight: 400;
			line-height: rems(22);
			color: var(--alpha-ui-color-text-primary);
		}
	}

	.full {
		margin-left: rems(-34);
	}
}
