@import "~@btc-alpha/ui-components/dist/styles/functions";
@import "~@btc-alpha/ui-components/dist/styles/media";
@import "~@btc-alpha/ui-components/dist/styles/mixins";

.root {
	display: flex;
	flex-direction: column;
	gap: rems(12);

	.search {
		height: rems(40);
		padding: 0 rems(6);

		input::placeholder {
			color: var(--alpha-ui-color-text-primary);
		}
	}

	.currencies {
		display: flex;
		flex-direction: column;
		gap: rems(4);
		max-height: rems(380);
		margin-right: rems(-14);
		padding-right: rems(3);
		margin-bottom: rems(-16);
		padding-bottom: rems(16);
		overflow-y: scroll;
		@include scroll(5, false, transparent);
		scrollbar-width: thin;

		.item {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: rems(8);
			padding: rems(12);
			height: auto;
			position: relative;

			&:not(:hover) {
				background-color: transparent;
			}

			.name {
				flex-grow: 1;
				align-items: flex-start;
			}

			.name,
			.price {
				display: flex;
				flex-direction: column;
				gap: rems(4);
				opacity: 1;

				span {
					@include font(14, 400, 20);
				}

				p {
					padding: 0;
					margin: 0;
					@include font(12, 400, 16);
					color: var(--alpha-ui-color-label-secondary);
				}
			}

			.chevron {
				position: absolute;
				right: rems(12);
				transform: translateX(#{rems(-10)});
				visibility: hidden;
				opacity: 0;
			}

			&:hover {
				.price {
					opacity: 0;
				}

				.chevron {
					transform: translateX(0);
					visibility: visible;
					opacity: 1;
				}
			}
		}
	}

	.empty {
		padding: rems(20) 0;
		text-align: center;
		color: var(--alpha-ui-color-text-primary);
	}
}
