@import "src/assets/styles/common/mixins";

.card_header {
	@include card-header();
	width: 100%;
	min-height: 60px;
	padding: 24px;
	border-bottom: none;
	border-bottom: var(--table-header-border-color);
	background-color: var(--card-background-color);
	border-radius: 8px 8px 0 0;

	> a,
	> span {
		&:last-child {
			cursor: pointer;
			margin-left: 25px;
			color: var(--color-blue);

			i {
				font-size: 16px;
				margin-left: 15px;
			}
		}
	}

	@include max-w(xxl) {
		min-height: 55px;
	}

	@include max-w(md) {
		padding: 16px;
		flex-wrap: wrap;

		> a,
		> span {
			&:last-child {
				margin-left: 0;
			}
		}
	}
}

.card_title {
	@include card-title();
	margin: 0;

	i {
		font-size: 24px;
		margin-right: 10px;
	}

	a {
		cursor: pointer;
		margin-left: auto;
		color: var(--color-blue);

		i {
			font-size: 16px;
			margin-left: 15px;
		}
	}
}

.header_links {
	@include flexbox(flex-start, center);

	a {
		color: var(--color-blue);

		&:not(:last-child) {
			margin-right: 10px;
		}
	}

	a:last-child {
		cursor: pointer;
		margin-left: 25px;
		i {
			font-size: 16px;
			margin-left: 15px;
		}
	}

	@include max-w(md) {
		a:last-child {
			margin-left: 0;
		}
	}
}

.button {
	i {
		font-size: 24px;
		margin: 0;
	}
}

.tabs {
	@include flexbox();
	position: relative;
	height: 32px;
	min-height: 32px;
	width: 100%;
	margin: 6px auto 0;
	border-bottom: 1px solid var(--divider-border);
	white-space: nowrap;
	z-index: 8;

	&:first-child a {
		border-top-left-radius: 5px;
	}

	&:last-child a {
		border-top-right-radius: 5px;
	}

	a {
		z-index: 2;
	}

	@include max-w(xs) {
		height: 40px;
		min-height: 40px;
	}
}

.content {
	@include flexbox(space-between, flex-start);
	padding: 20px 28px;

	@include max-w(md) {
		@include flexbox(flex-start, flex-start, column);
		padding: 20px 16px;
	}
}

.coming_soon_container {
	@include flexbox(center, center, column);
	width: 100%;
	padding-bottom: 46px;

	img {
		margin-bottom: 20px;
	}

	span {
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 23px;
		text-align: center;
		color: var(--color-secondary);
	}
}

.coming_soon_info_sack {
	@include flexbox(center, center);
	position: absolute;
	bottom: 0;
	left: 0;
	padding: 0 28px;
	width: 100%;
	height: 46px;
	border-radius: 0 0 8px 8px;
	background-color: var(--input-normal);

	i {
		font-size: 18px;
		margin-right: 5px;
		color: var(--color-white);
	}

	span {
		color: var(--color-white);
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
	}
}

.balance_conversions {
	@include flexbox(flex-start, flex-start, column);
	padding: 5px 0;
	margin-right: 20px;

	@include max-w(xxl) {
		margin-right: 0;
	}
}

.balance_conversion {
	@include flexbox(flex-start, flex-start, column);

	&:not(:last-child) {
		margin-bottom: 20px;
	}
}

.balance_conversion_label {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 19px;
	margin-bottom: 6px;
	color: var(--color-secondary);
}

.balance_conversion_value {
	@include flexbox(flex-start, center);

	i {
		font-size: 24px;
		margin-right: 6px;
	}

	span {
		&:nth-of-type(1) {
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			max-width: 250px;
			font-style: normal;
			font-weight: normal;
			font-size: 24px;
			line-height: 28px;
			color: var(--color-primary);
		}

		&:nth-of-type(2) {
			line-height: 1;
			margin: 6px 0 0 4px;
		}
	}
}

.chart_container {
	@include flexbox(flex-end, flex-end);

	@include max-w(xxxl) {
		flex-wrap: wrap;
	}
}

.chart_ccy_list {
	@include flexbox(flex-start, flex-start, column);
	width: 260px;
	padding: 12px 0 12px 30px;

	@include max-w(xxl) {
		padding: 12px 0 12px 10px;
	}

	@include max-w(md) {
		margin-left: 0;
		width: 100%;
	}
}

.chart_ccy_list_item {
	@include flexbox(space-between, center);
	width: 100%;
	cursor: pointer;

	&:not(:last-child) {
		margin-bottom: 5px;
	}
}

.chart_ccy_list_item_label {
	@include flexbox(flex-start, center);
	@include transition(background-color);
	font-size: 14px;
	line-height: 19px;
	color: var(--color-secondary);
	border-radius: 5px;
	background-color: transparent;
	padding: 1px 5px;

	&.active {
		background-color: var(--card-header-background);
	}

	span {
		width: 12px;
		height: 12px;
		border-radius: 50%;
		margin-right: 10px;
		background-color: var(--ui-balances-ccy-label);
	}
}

.chart_ccy_list_item_value {
	font-size: 14px;
	line-height: 19px;
	text-align: right;
	max-width: 160px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	color: var(--color-secondary);
}
