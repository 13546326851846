@import "~@btc-alpha/ui-components/dist/styles/functions";
@import "~@btc-alpha/ui-components/dist/styles/media";

.wallets {
	.heading {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: rems(8);
	}

	.info {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		border-radius: rems(16);
		padding: rems(20);
		margin-bottom: rems(72);
		background-color: var(--alpha-ui-color-background-base-3);

		div:first-child {
			display: flex;
			flex-direction: column;
			gap: rems(8);

			.title {
				font-size: rems(12);
				font-weight: 500;
				line-height: rems(16);
				color: var(--alpha-ui-color-label-secondary);
			}

			.amount {
				font-size: rems(32);
				font-weight: 500;
				line-height: rems(40);
				color: var(--alpha-ui-color-text-primary);
			}
		}

		div:last-child {
			display: flex;
			flex-direction: row;
			gap: rems(12);

			button {
			}
		}
	}

	.currency > div {
		gap: rems(8) !important;

		&:not(:has(img)) {
			padding-left: rems(40);
		}

    & > div {
      display: flex;
      flex-direction: column;
      gap: rems(2);

      span:last-child {
        color: var(--alpha-ui-color-label-secondary);
        font-size: rems(12);
      }
    }
	}

	.actions > div {
		gap: rems(12) !important;

		button {
			i {
				font-size: rems(16) !important;
			}

			span {
				font-size: rems(12) !important;
			}
		}
	}
}

.transfer {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	gap: rems(16);

	.direction {
		--transfer-change-button-width: #{rems(52)};
		--transfer-direction-gap: #{rems(8)};
		--transfer-type-width: calc(
			(100% - var(--transfer-change-button-width) - var(--transfer-direction-gap) * 2) / 2
		);

		display: grid;
		grid-template-columns: var(--transfer-type-width) auto var(--transfer-type-width);
		align-items: flex-end;
		gap: var(--transfer-direction-gap);

		.switch {
			width: var(--transfer-change-button-width);
			height: var(--transfer-change-button-width);
			border-radius: rems(12);
			background-color: var(--alpha-ui-color-background-base-1);
		}
	}

	footer {
		display: flex;
		flex-direction: row;
		align-items: stretch;
		gap: rems(16);

		button {
			flex-grow: 1;
		}
	}
}
