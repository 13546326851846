@import "src/assets/styles/common/mixins";

.custom_select {
	position: relative;
	border-radius: 8px;
	min-width: 100px;
	width: 100%;
}

.custom_select_label {
	width: 100%;
	color: var(--color-secondary);
	height: 14px;
	min-height: 14px;
	margin-bottom: 6px;
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
}

.custom_select_selected_label {
	@include flexbox(space-between, center);
	width: 100%;
	height: 40px;
	text-align: center;
	color: var(--color-primary);
	align-self: stretch;
	background: var(--input-background);
	font-size: 14px;
	cursor: pointer;
	padding: 12px 16px;
	border-radius: 8px;

	svg {
		margin-left: 6px;
		transform: translateY(-2px);

		path {
			fill: var(--icon-primary);
		}
	}
}

.custom_select_option_menus {
	@include flexbox(flex-start, center, column);
	position: absolute;
	top: calc(100% + 1px);
	z-index: 999;
	left: 0;
	right: 0;
	margin-top: 0;
	border-radius: 6px;
	overflow: hidden;
	background: var(--navmenu-background);
	box-shadow: var(--navmenu-box-shadow);
	width: 100%;

	>button {
		height: 32px;
		width: 100%;
		padding: 0 16px;
		font-size: 14px;
		text-align: left;
		color: var(--color-primary);

		&:hover {
			background-color: var(--navmenu-item-hover);
		}
	}
}

.custom_select_selected_opened {
	svg {
		transform: translateY(2px);
		rotate: 180deg;
	}
}

.coin_block {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.row {
	@include flexbox(space-between, center);
	position: relative;
	width: auto;
}

.currency_code {
	display: flex;
	align-items: center;
	font-weight: normal;
	font-size: 14px;
	color: var(--color-primary);
	white-space: nowrap;

	i {
		font-size: 13px;
		line-height: 13px;
		margin: 0 15px 1px 0;
		width: 16px;
	}

	img {
		width: 13px;
		height: 13px;
		margin: 0 8px 1px 0;
	}
}

.currency_available {
	margin-left: auto;
	color: var(--color-primary);

	&.secondary {
		color: var(--color-secondary);
	}
}

.currency_name {
	color: var(--color-secondary);
	margin-left: 5px;
	font-size: 12px;
	width: auto;
	word-break: break-all;
}