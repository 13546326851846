@import "src/assets/styles/common/mixins";

.page_content_container {
	@include page-content-outer();
	background: #101014;
	gap: 10px;

	@include max-w(xl) {
		padding: 16px;
	}
}
