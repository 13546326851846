.precision {
	position: relative;
	margin-left: auto;
	min-width: 86px;

	&__checkbox {
		&:checked {
			& + .precision__toggleButton + .precision__list {
				opacity: 1;
				pointer-events: unset;
			}
		}
	}

	&__toggleButton {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-radius: 8px;
		background: #2c2c2e;
		padding: 8px 16px;
		height: 32px;
		cursor: pointer;
		width: 100%;

		@media (hover: hover) and (pointer: fine) {
			transition: 0.3s ease-in-out;

			&:hover {
				opacity: 0.7;
			}
		}
	}

	&__toggleButtonText {
		color: #fff;
		font-size: 14px;
		font-weight: 500;
		margin-right: 10px;
	}

	&__list {
		position: absolute;
		z-index: 10;
		border-radius: 8px;
		background: #2c2c2e;
		list-style: none;
		padding: 0;
		margin: 0;
		width: 100%;
		margin-top: 2px;

		opacity: 0;
		pointer-events: none;
		transition: 0.4s ease-in-out;
	}

	&__item {
	}

	&__itemButton {
		color: #fff;
		font-size: 14px;
		font-weight: 500;
		padding: 8px 16px;
		width: 100%;
		display: flex;

		@media (hover: hover) and (pointer: fine) {
			transition: 0.3s ease-in-out;

			&:hover {
				opacity: 0.7;
			}
		}
	}
}
