@import "~@btc-alpha/ui-components/dist/styles/functions";
@import "~@btc-alpha/ui-components/dist/styles/media";
@import "~@btc-alpha/ui-components/dist/styles/mixins";

.root {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: rems(8);
	padding: rems(16);
	border-radius: rems(16);
	border: rems(1) solid var(--alpha-ui-color-separator-opaque);
	background-color: var(--alpha-ui-color-background-base-1);

	.item {
		display: flex;
		align-items: center;
		gap: rems(9);

		svg {
			width: rems(16);
			height: rems(16);
		}

		span {
			color: var(--alpha-ui-color-text-primary);
			@include font(14, 500, 16);
		}
	}
}
