@import "styles/mixins";
@import "~@btc-alpha/ui-components/dist/styles/functions";

:root {
  --vh: 1vh;
  --safe-area-inset-bottom: env(safe-area-inset-bottom, 0);
}

.wrapper {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1000; // High z-index to ensure it appears above other content

	// Multiple height declarations for better cross-browser support
	height: 100vh; /* Fallback */
	height: calc(var(--vh, 1vh) * 100); /* Dynamic viewport height */

	display: flex;
	flex-direction: column;
	justify-content: space-between;
	overflow: hidden;

	// Background color to ensure it covers the entire viewport
	background-color: var(--alpha-ui-color-background-base-3, #000);

	/* This is critical - apply to body when this component mounts */
	//:global(body) {
	//	overflow: hidden;
	//	position: fixed;
	//	width: 100%;
	//	height: 100%;
	//}
}

.container {
	padding: 0 rems(36);
}

.title {
	color: #fff;
	font-size: rems(24);
	font-weight: 500;
	line-height: rems(32);
	letter-spacing: -1px;
	text-align: center;
	padding: 0 rems(55);
	margin-top: rems(58);
	margin-bottom: rems(12);

	&__highlighted {
		display: inline-block;
		border-radius: rems(12);
		padding: rems(2) rems(10);

		&:nth-child(1) {
			transform: rotate(2deg);
			background-color: rgba(32, 178, 108, 1);
		}

		&:nth-child(2) {
			transform: rotate(-2deg);
			background-color: rgba(124, 89, 255, 1);
			margin-left: rems(6);
		}
	}
}

.description {
	color: rgba(235, 235, 245, 0.64);
	font-size: 14px;
	font-weight: 400;
	line-height: 125%;
	text-align: center;
	padding: 0 rems(65) rems(58) rems(65);
}

.mobile_app_download {
	@include home-page-main-button;

	width: 100%;
	min-width: 115px;
	height: rems(52);
	font-weight: 500;

	&.disabled {
		pointer-events: none;
		cursor: default;
		opacity: 0.75;
	}

	i {
		font-size: 22px;
		color: #fff;
	}
}

.header {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: rems(8);
	height: var(--redesigned-header-height);
	padding: 0 rems(16);
	overflow: hidden;
	border-bottom: rems(1) solid var(--alpha-ui-color-separator-opaque);
	background-color: var(--alpha-ui-color-background-base-3);

	& > .logo {
		display: flex;
		margin-right: rems(8);
		min-width: rems(110);

		svg {
			width: 100%;
		}
	}

	& > .space {
		flex-grow: 1;
	}

	& > hr {
		min-width: rems(1);
		height: rems(20);
		border: 0;
		background-color: var(--alpha-ui-color-separator-opaque);
	}

	& > button,
	& > a {
		&:not(:hover):not(.active):not(.primary) {
			background-color: transparent;

			&:not(:has(> span)) {
				border-color: var(--alpha-ui-color-separator-opaque);
			}
		}

		&:not(:has(> span)) {
			min-width: rems(32);
			max-width: rems(32);
		}
	}

	& > button {
		gap: rems(8);
	}
}

.footer {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	min-height: rems(228);
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
	padding: rems(16);
}
