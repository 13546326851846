@import "~@btc-alpha/ui-components/dist/styles/functions";
@import "~@btc-alpha/ui-components/dist/styles/media";
@import "~@btc-alpha/ui-components/dist/styles/mixins";

.root {
  position: sticky;
  top: 0;
  z-index: 10;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: rems(8);
	height: var(--redesigned-header-height);
	padding: 0 rems(16);
	overflow: hidden;
	border-bottom: rems(1) solid var(--alpha-ui-color-separator-opaque);
	background-color: var(--alpha-ui-color-background-base-3);

	& > .logo {
		display: flex;
		margin-right: rems(8);
		min-width: rems(110);

		svg {
			width: 100%;
		}
	}

	& > .search {
		min-width: rems(120);
		max-width: rems(120);
		transition: max-width 0.3s;

		&:has(input:focus),
		&:has(input:not(:placeholder-shown)) {
			max-width: rems(300);
		}

		input::placeholder {
			color: var(--alpha-ui-color-text-primary);
		}
	}

	& > .space {
		flex-grow: 1;
	}

	& > hr {
		min-width: rems(1);
		height: rems(20);
		border: 0;
		background-color: var(--alpha-ui-color-separator-opaque);
	}

	& > button,
	& > a {
		&:not(:hover):not(.active):not(.primary) {
			background-color: transparent;

			&:not(:has(> span)) {
				border-color: var(--alpha-ui-color-separator-opaque);
			}
		}

		&:not(:has(> span)) {
			min-width: rems(32);
			max-width: rems(32);
		}
	}

	& > button {
		gap: rems(8);

		.chevron {
			margin-bottom: rems(-3);
      transition: 0.5s;
		}

    &.active .chevron {
      transform: rotateX(180deg);
    }
	}
}

.items {
	position: fixed;
	min-width: rems(300);
	max-width: rems(350);
	z-index: 20000;
	top: var(--items-top);
	left: min(var(--items-left), 100% - var(--items-width));

	&:not(.showed):not(:hover) {
		& > div {
			opacity: 0;
			visibility: hidden;
			transform: translateY(#{rems(20)});
		}
	}

	&.showed {
		z-index: 21000;
	}

	& > div {
		position: relative;
		display: flex;
		flex-direction: column;
		gap: rems(6);
		padding: rems(16);
		margin: 0 rems(4);
		margin-top: rems(4);
		transform: translateY(0);
		opacity: 1;
		visibility: visible;
		overflow: hidden;
		border-radius: rems(28);
		border: rems(1) solid var(--alpha-ui-color-separator-opaque);
		backdrop-filter: blur(32px);
		transition: 0.5s;

		&:before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			opacity: 0.24;
			background-color: var(--alpha-ui-color-background-base-3);
			z-index: -1;
		}

		.item {
			align-items: flex-start;
			gap: rems(8);
			height: auto;
			padding: rems(12);
			border-radius: rems(12);
			opacity: 1;

			&:not(:hover) {
				background-color: transparent;
			}

			&.disabled div {
				span,
				p {
					opacity: 0.32;
				}
			}

			& > div {
				flex-grow: 1;
				text-align: left;
				@include font(14, 500, 20);

				& > div {
					display: flex;
					flex-direction: row;
					align-items: center;

					span {
						flex-grow: 1;
					}

					.label {
						float: right;
						padding: rems(3) rems(6);
						border-radius: rems(4);
						color: var(--alpha-ui-color-text-primary);
						background-color: var(--alpha-ui-color-background-base-2);
						@include font(10, 500, 10);

						&.labelPrimary {
							background-color: var(--alpha-ui-color-accent-purple);
						}

						&.labelSecondary {
							position: relative;
							overflow: hidden;
							color: var(--alpha-ui-color-accent-purple);
							background-color: transparent;

							&:before {
								content: "";
								position: absolute;
								left: 0;
								top: 0;
								width: 100%;
								height: 100%;
								opacity: 0.24;
								background-color: var(--alpha-ui-color-accent-purple);
								z-index: -1;
							}
						}
					}
				}

				p {
					padding: 0;
					margin: 0;
          @include font(14, 400, 20);
					color: var(--alpha-ui-color-label-secondary);
				}
			}

			.chevron {
				align-self: center;
			}
		}

		hr {
			width: 100%;
			height: rems(1);
			border: 0;
			background-color: var(--alpha-ui-color-separator-opaque);
		}

		.group {
			padding: 0 rems(12);
			text-transform: uppercase;
			color: var(--alpha-ui-color-label-secondary);
			@include font(12, 500, 16);
		}
	}
}

button:has(.character) {
	overflow: hidden;

	.character {
		margin-top: rems(10);
		min-width: rems(34);
		min-height: rems(34);
	}
}
