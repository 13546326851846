.chart {
	display: flex;
	justify-content: center;
	padding: 0;
	flex: 1 0;
	flex-basis: 0;
	width: calc(100% - 2px);
	height: 100%;
	position: relative;

	&__loader {
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		z-index: 9;
		background-color: var(--main-background-color);
	}

	&__loaderText {
		text-transform: uppercase;
		background-image: linear-gradient(-225deg, #ebebeb 0%, #ebebeb 29%, #323234 67%, #545457 100%);
		background-size: auto auto;
		background-clip: border-box;
		background-size: 200% auto;
		color: #fff;
		background-clip: text;
		text-fill-color: transparent;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		animation: textclip 4s linear infinite;
		display: inline-block;
		font-size: 96px;
	}

	@keyframes textclip {
		to {
			background-position: 200% center;
		}
	}

	&__chartError {
		position: absolute;
		bottom: -8px;
		height: 50px;
		opacity: 0.9;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		z-index: 99;
		padding: 20px 10px;
		background-color: #333;
		border-radius: 0 0 8px 8px;

		> i {
			color: var(--color-yellow);
			font-size: 16px;
			margin-right: 8px;
		}

		> span {
			color: #fff;
			font-size: 14px;
		}
	}
}
