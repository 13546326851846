@import "~@btc-alpha/ui-components/dist/styles/functions";
@import "~@btc-alpha/ui-components/dist/styles/media";
@import "~@btc-alpha/ui-components/dist/styles/mixins";

.root {
	width: 100%;
	max-width: rems(440);

	.delay {
		margin: 0 auto;
		margin-top: rems(24);
		opacity: 0.32;
		text-align: center;
		color: var(--alpha-ui-color-text-primary);
		@include font(14, 500, 16, true);
	}

	.resend {
		margin: 0 auto;
		margin-top: rems(24);
		padding: 0;
		height: auto;
	}

	.buttons {
		display: flex;
		flex-direction: row;
		gap: rems(8);
		margin-top: rems(48);

		button:last-child {
			flex-grow: 1;
		}
	}
}
