@import "styles/mixins";

.supports {
	margin-top: 80px;

	@media screen and (max-width: 1199px) {
		margin-top: 60px;
	}

	@media screen and (max-width: 993px) {
		margin-top: 40px;
	}

	&__container {
		@include home-page-container;

		&___content {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-column-gap: 32px;
			margin-top: 40px;

			@media screen and (max-width: 1199px) {
				margin-top: 32px;
				grid-column-gap: 24px;
			}

			@media screen and (max-width: 993px) {
				margin-top: 24px;
				grid-column-gap: 16px;
			}

			@media screen and (max-width: 767px) {
				overflow: auto;
			}

			@media screen and (max-width: 630px) {
				grid-template-columns: 300px 300px;
				overflow: auto;
				grid-column-gap: 24px;
			}
		}
	}

	&__headline {
		@include home-page-headline;

		margin: 0;
	}

	&__headlineContent {
		@include home-page-content;

		max-width: 830px;
		margin: 16px auto 0 auto;
	}

	&__card {
		display: flex;
		flex-direction: column;
		border-radius: 12px;
		background: var(--support-background-color);
		padding: 24px 32px 0 32px;

		@media screen and (max-width: 1199px) {
			padding: 20px 24px 0 24px;
		}

		@media screen and (max-width: 993px) {
			padding: 16px 18px 0 18px;
		}

		@media screen and (max-width: 767px) {
			align-items: flex-end;
			flex-direction: column-reverse;
			padding: 0;
		}
	}

	&__cardContentWrapper {
		display: flex;
		flex-direction: column;
		flex: 1;

		@media screen and (max-width: 767px) {
			padding: 24px;
		}
	}

	&__cardHeadline {
		color: var(--support-headline-color);
		font-size: 32px;
		font-weight: 600;
		line-height: 132%;
		letter-spacing: -0.4px;
		margin-bottom: 0;
		margin-top: 0;

		@media screen and (max-width: 1199px) {
			font-size: 26px;
		}

		@media screen and (max-width: 993px) {
			font-size: 22px;
		}
	}

	&__cardContent {
		margin-top: 12px;
		color: var(--support-text-color);
		font-size: 18px;
		font-weight: 400;
		line-height: 132%;
		letter-spacing: 0.06px;
		margin-bottom: 20px;

		@media screen and (max-width: 1199px) {
			font-size: 16px;
		}

		@media screen and (max-width: 993px) {
			margin-top: 8px;
			margin-bottom: 16px;
		}
	}

	&__cardButton {
		@include home-page-main-button;

		margin-top: auto;
		align-self: flex-start;
		min-width: 115px;

		@media screen and (max-width: 767px) {
			width: 100%;
		}
	}

	&__cardImg {
		width: 100%;
		margin-top: 32px;

		@media screen and (max-width: 1199px) {
			margin-top: 26px;
		}

		@media screen and (max-width: 993px) {
			margin-top: 22px;
		}

		@media screen and (max-width: 767px) {
			margin-top: 0;
			border-top-right-radius: 12px;
			border-top-left-radius: 12px;
		}
	}
}
