.openOrders {
	&__list {
		display: flex;
		flex-direction: column;
		list-style: none;
		padding: 0;
		margin: 0;
	}

	&__disabled {
		display: none;
	}
}
