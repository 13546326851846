.orderValue {
	display: flex;
	flex-direction: column;
	padding: 12px 16px;

	&__labelText {
		display: flex;
		color: rgba(235, 235, 245, 0.6);
		font-size: 12px;
		font-weight: 400;
		line-height: 14px;
		margin-bottom: 6px;
	}
}
