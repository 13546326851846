@import "assets/styles/common/mixins";

.container {
	@include flexbox(stretch, stretch, column);

	max-width: 615px;
	width: 100%;
	align-self: center;
}

.form_wrapper {
	@include flexbox(stretch, stretch, column);

	gap: 40px;

	@include max-w(xs) {
		gap: 20px;
	}
}

.currency_appender {
	height: 40px;
	top: 3px;
	right: 3px;
}

.form_container {
	@include flexbox(stretch, stretch, column);
	@include card();

	overflow: unset;
	border-radius: 12px;
	padding: 20px;
	gap: 20px;
	max-height: 1000px;
	transition: 0.5s cubic-bezier(0, 1.05, 0, 1);
	transition-timing-function: cubic-bezier(0.15, 0.01, 1, 0.88);

	@include max-w(xs) {
		padding: 10px;
	}

	&.disabled {
		overflow: hidden;
		max-height: 78px;
		transition: 0.5s cubic-bezier(0, 1.05, 0, 1);
		pointer-events: none;
		filter: grayscale(100);
		opacity: 0.55;

		@include max-w(xs) {
			max-height: 58px;
		}
	}
}

.form_title {
	font-weight: 500;
	font-size: 24px;
	line-height: 36px;
	color: var(--color-text-primary);

	&.margin {
		margin-bottom: 20px;
	}
}

.form_secondary_text {
	font-size: 16px;
	line-height: 24px;
	color: var(--color-text-tertiary);

	strong {
		font-weight: 500;
	}
}

.payment_methods_radio_container {
	@include flexbox(stretch, stretch, column);

	gap: 20px;
	margin-top: 6px;

	.method_item {
		@include flexbox(stretch, stretch, column);
		@include card();

		border-radius: 12px;
		cursor: pointer;
		background-color: var(--background-color);
		padding: 20px;

		.title {
			@include flexbox();

			gap: 6px;
			font-size: 16px;
			line-height: 24px;
			color: var(--color-text-primary);
			margin-bottom: 10px;

			img {
				width: 24px;
				height: 24px;
				flex-shrink: 0;
			}
		}

		.checkbox {
			position: absolute;
			width: 22px;
			height: 22px;
			right: 22px;
			top: 50%;
			transform: translateY(-50%);
			border: 2px solid var(--color-blue);
			background-color: var(--color-white);
			border-radius: 50%;
		}

		&.active {
			border: 2px solid var(--color-blue);

			.checkbox {
				&::before {
					content: "";
					position: absolute;
					width: 10px;
					height: 10px;
					right: 4px;
					top: 4px;
					background-color: var(--color-blue);
					border-radius: 50%;
				}
			}
		}
	}
}

.controls_container {
	@include flexbox();

	margin-top: 20px;
	gap: 20px;
}

.your_price {
	@include flexbox(stretch, center, column);
	@include card();

	padding: 10px;
	border-radius: 12px;
	background-color: var(--background-color);

	.price {
		font-weight: 500;
		font-size: 24px;
		color: var(--color-text-primary);
	}
}

.form_double_row {
	display: grid;
	gap: 20px;
	grid-template-columns: repeat(2, 1fr);
	align-items: flex-start;
}

.floating_input_container {
	@include flexbox(space-between, center);

	border: 1px solid var(--input-normal);
	position: relative;
	height: 46px;
	width: 100%;
	padding: 0 16px;
	border-radius: 12px;
	font-size: 16px;
	color: var(--color-text-primary);

	.label {
		position: absolute;
		left: 16px;
		top: 0;
		transform: translateY(-50%);
		z-index: 1;
		background-color: var(--card-background-color);
		padding: 0 4px;
		font-size: 12px;
		line-height: 14px;
		color: var(--color-text-tertiary);
	}

	i {
		font-size: 14px;
		cursor: pointer;
		color: var(--color-text-primary);
	}
}

.summary_container {
	display: grid;
	grid-gap: 20px 10px;
	grid-template-columns: repeat(2, 1fr);

	@include max-w(xs) {
		grid-gap: 10px;
	}

	.summary_item {
		@include flexbox(stretch, stretch, column);

		gap: 2px;

		.summary_value {
			font-weight: 500;
			font-size: 16px;
			line-height: 24px;
			color: var(--color-text-primary);
			text-transform: uppercase;

			&.sell {
				color: var(--color-red);
			}

			&.buy {
				color: var(--color-green);
			}
		}
	}
}
