@import "assets/styles/common/mixins";

.container {
	@include flexbox(stretch, stretch, column);
}

.timer {
	color: var(--color-blue) !important;
}

.copy_btn {
	color: var(--color-text-tertiary);
	font-size: 22px;
	margin-left: 7px;
	cursor: pointer;
}

.order_link {
	text-decoration: underline;
}
