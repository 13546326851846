.main {
	flex: 1;
	display: grid;
	grid-template-areas:
		"favorites favorites favorites"
		"tradingView orderTrades terminal"
		"history history terminal";
	grid-gap: 2px;
	padding-top: 2px;
	background-color: var(--page-background-color);
	grid-template-rows: 30px 611px auto;
	grid-template-columns: calc(100% - 906px) 602px 300px;

	@media (max-width: 1925px) {
		grid-template-columns: calc(100% - 604px) 300px 300px;
	}

	@media (max-width: 1099px) {
		grid-template-areas:
			"favorites favorites favorites"
			"tradingView tradingView tradingView"
			"orderTrades orderTrades terminal"
			"history history terminal";
		grid-template-rows: 30px 400px 611px auto;
	}

	@media (max-width: 899px) {
		grid-template-areas:
			"favorites"
			"tradingView"
			"terminal"
			"orderTrades"
			"history";
		grid-template-rows: 30px 400px auto 611px 300px;
		grid-template-columns: 100%;
	}

	.blur > * > * {
		filter: blur(10px);
		opacity: 0.6;
		pointer-events: none;
	}

	&__portalRoot {
		position: relative;
		z-index: 100000;
	}

	&__favorites {
		display: flex;
		grid-area: favorites;
		background-color: var(--main-background-color);
	}

	&__tradingView {
		display: flex;
		grid-area: tradingView;
		background-color: var(--main-background-color);
		overflow: hidden;
	}

	&__orderTrades {
		display: grid;
		grid-area: orderTrades;
		background-color: black;
		grid-template-columns: 1fr 1fr;
		grid-gap: 2px;
		grid-template-rows: 1fr;

		@media ((max-width: 1925px) and (min-width: 1100px)) or (max-width: 610px) {
			position: relative;
			z-index: 1;
			grid-template-columns: 1fr;
			grid-gap: 0;
			grid-template-rows: 41px 1fr;
		}
	}

	&__tabRadio {
		@media (min-width: 1926px) {
			display: none;
		}

		@media ((max-width: 1925px) and (min-width: 1100px)) or (max-width: 610px) {
			&:nth-child(1):checked + input + div {
				label:nth-child(1) {
					color: #fff;
					border-bottom: 2px solid #7c59ff;

					&:hover {
						color: #fff;
					}
				}

				& + div + div {
					opacity: 0;
					pointer-events: none;
				}
			}

			&:nth-child(2):checked + div {
				label:nth-child(2) {
					color: #fff;
					border-bottom: 2px solid #7c59ff;

					&:hover {
						color: #fff;
					}
				}

				& + div {
					opacity: 0;
					pointer-events: none;
				}
			}
		}
	}

	&__ordersHeader {
		display: flex;
		padding: 0 16px 0 16px;
		border-bottom: 1px solid #28282d;
		background-color: var(--main-background-color);
		position: relative;
		z-index: 3;

		@media (min-width: 1926px) {
			display: none;
		}

		@media (max-width: 1099px) {
			display: none;
		}

		@media (max-width: 610px) {
			display: flex;
		}
	}

	&__history {
		min-height: 243px;
	}

	&__ordersHeaderTabButton {
		color: rgba(235, 235, 245, 0.6);
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		padding-top: 14px;
		padding-bottom: 8px;
		border-bottom: 2px solid #7c59ff00;
		cursor: pointer;

		@media ((max-width: 1925px) and (min-width: 1100px)) or (max-width: 610px) {
			margin-right: 16px;

			@media (hover: hover) and (pointer: fine) {
				transition: 0.3s ease-in-out;

				&:hover {
					color: rgba(235, 235, 245, 0.8);
					border-bottom: 2px solid #7c59ff30;
				}
			}
		}
	}

	&__orders {
		display: flex;
		background-color: var(--main-background-color);

		@media ((max-width: 1925px) and (min-width: 1100px)) or (max-width: 610px) {
			opacity: 1;
			pointer-events: auto;
			transition: 0.3s ease-in-out;
		}
	}

	&__trades {
		display: flex;
		background-color: var(--main-background-color);

		@media ((max-width: 1925px) and (min-width: 1100px)) or (max-width: 610px) {
			opacity: 1;
			pointer-events: auto;
			position: absolute;
			width: 100%;
			height: calc(100% - 40px);
			bottom: 0;
			transition: 0.3s ease-in-out;
		}
	}

	&__terminal {
		display: flex;
		grid-area: terminal;
		background-color: var(--main-background-color);
		flex-direction: column;
	}

	&__history {
		display: flex;
		grid-area: history;
		background-color: var(--main-background-color);
	}
}
