@import "src/assets/styles/common/mixins";

.header_container {
	background-image: url(../../assets/images/staking/header-mask.png);

	@include max-w(lg) {
		background: url(../../assets/images/staking/header-mask-mobile.png);
	}
}

.container {
	@include flexbox(center, center);

	width: 100%;
	border-bottom: 1px solid var(--divider-border);
}

.tabs_content {
	@include flexbox(flex-start, center);
	width: 100%;
	max-width: 1240px;

	&.page {
		@include max-w(xxl) {
			max-width: 1200px;
		}
	}
}

.tabs {
	@include flexbox(flex-start, center);
	@include tiny-scrollbar();

	height: 32px;
	min-height: 32px;
	width: 100%;
	margin: 0 auto;
	border-bottom: 1px solid var(--divider-border);
	white-space: nowrap;
	z-index: 8;
	border-bottom: 0;

	&:first-child a {
		border-top-left-radius: 5px;
	}

	&:last-child a {
		border-top-right-radius: 5px;
	}

	a {
		z-index: 2;
	}

	@include max-w(lg) {
		a {
			flex: 1;
		}
	}

	@include max-w(xs) {
		height: 45px;
		min-height: 45px;
		padding-bottom: 5px;
	}

	&.overflowAuto {
		overflow: auto hidden;
	}
}

.tab {
	width: 180px;
}

.plans_container {
	@include flexbox(flex-start, center, column);

	width: 100%;
	max-width: 1240px;
	padding: 20px 0;
	margin: 0 auto;

	@include max-w(xxl) {
		padding: 20px;
	}
}

.plan_list {
	@include flexbox(flex-start, center, column);

	width: 100%;
	margin-bottom: 20px;
}

.how_it_works {
	@include flexbox(flex-start, flex-start, column);

	width: 100%;
	margin-bottom: 30px;

	.title {
		font-weight: bold;
		font-size: 24px;
		line-height: 28px;
		color: var(--color-primary);
		margin-bottom: 10px;
	}

	.description {
		font-weight: normal;
		font-size: 16px;
		line-height: 23px;
		color: var(--color-secondary);
		max-width: 860px;
	}
}

.steps {
	@include flexbox(center, flex-start);

	margin-bottom: 35px;
	width: 100%;

	&:hover {
		.step {
			&:nth-of-type(1):after,
			&:nth-of-type(2):after {
				filter: drop-shadow(0 0 0.75rem var(--color-gold));
			}
		}

		.step_icon {
			i {
				filter: drop-shadow(0 0 0.75rem var(--color-gold));
			}
		}
	}

	@include max-w(lg) {
		flex-direction: column;
		margin-bottom: 0;
	}
}

.step {
	@include flexbox(flex-start, center, column);

	max-width: 390px;
	margin: 0 19px;
	position: relative;
	z-index: 9;
	flex: 1;

	&:nth-of-type(1):after,
	&:nth-of-type(2):after {
		content: "";
		position: absolute;
		width: 90%;
		height: 1px;
		left: calc(50% + 40px);
		top: 40px;
		background: linear-gradient(to right, var(--card-background-color) 0%, var(--color-gold) 31%);
	}

	@include max-w(lg) {
		margin: 0 auto 35px;
		width: 100%;
		padding: 0 30px;

		&:nth-of-type(1):after,
		&:nth-of-type(2):after {
			width: 1px;
			height: 90%;
			left: 5px;
			background: linear-gradient(to bottom, #ffffff 0%, #c69b15 31%);
		}
	}

	.title {
		font-weight: bold;
		font-size: 18px;
		line-height: 24px;
		text-align: center;
		color: var(--color-primary);
		margin-bottom: 5px;
	}

	.description {
		font-size: 16px;
		line-height: 23px;
		text-align: center;
		color: var(--color-secondary);
	}
}

.step_num {
	font-size: 14px;
	line-height: 19px;
	text-align: center;
	color: var(--color-disabled);

	@include max-w(lg) {
		text-align: left;
		position: absolute;
		left: 0;
		top: 8px;
	}
}

.step_icon {
	@include flexbox(center, center);

	width: 60px;
	color: var(--color-gold) !important;

	[class~="ai-clock"] {
		font-size: 30px;
		margin: 11px 0;
	}

	[class~="ai-chevron_up_mini"] {
		font-size: 24px;
		margin: 14px 0;
	}

	i {
		font-size: 52px;
		margin-bottom: 3px;
		color: var(--color-gold) !important;
	}
}

.profit {
	@include flexbox(flex-start, center);

	margin: 0.5rem 0 1rem 0;
	border-radius: 8px;
	position: relative;
	width: 100%;
	background: url("../../assets/images/staking/profit-mask.svg"), var(--card-background-color);
	background-repeat: no-repeat;
	background-position: center right;
	min-height: 147px;
	padding: 36px 52px;
	border: 1px solid var(--card-border-color);

	&:hover {
		.icons {
			filter: drop-shadow(0 0 0.75rem var(--color-gold));
		}
	}

	> i {
		color: var(--icon-primary);
		position: absolute;
		top: 30px;
		left: 20px;
		font-size: 20px;
	}

	@include max-w(lg) {
		background: var(--card-background-color);
		padding: 53px 15px 30px;
		flex-direction: column;

		> i {
			top: 18px;
			left: 20px;
		}
	}

	.title {
		font-weight: bold;
		font-size: 24px;
		line-height: 28px;
		color: var(--color-primary);
		max-width: 330px;
		margin-right: 40px;

		@include max-w(lg) {
			margin-right: 0;
		}
	}

	.icons {
		width: 190px;
		height: 60px;
		background: url("../../assets/images/staking/profit-icons.png") no-repeat center / contain;
		margin-right: 40px;

		&:hover {
			filter: drop-shadow(0 0 0.75rem var(--color-gold));
		}

		@include max-w(lg) {
			margin: 25px auto;
		}
	}

	.description {
		font-weight: normal;
		font-size: 16px;
		line-height: 23px;
		color: var(--color-secondary);
		max-width: 530px;

		a {
			color: var(--color-coral);
		}
	}
}

.qa_section {
	@include flexbox(center, center);

	width: 100%;
}

.plan_container {
	@include flexbox(flex-start, stretch);

	width: 100%;
	min-height: 226px;
	height: auto;
	margin: 5px 0;
	border-radius: 5px;
	padding: 25px;
	background-color: var(--card-background-color);
	position: relative;

	border: 1px solid var(--card-border-color);

	@include max-w(xl) {
		align-items: flex-start;
		flex-direction: column;
		padding: 18px;
	}

	.icon {
		@include flexbox(center, flex-start);

		width: 30%;
		height: auto;
		border-right: 1px solid var(--divider-border);
		padding-right: 25px;
		padding-top: 35px;
		position: relative;

		i {
			font-size: 96px;
			color: var(--icon-primary);
		}

		@include max-w(xl) {
			border-right: none;
			margin-right: 26px;
			padding-top: 0;
			padding-bottom: 5px;

			i {
				font-size: 65px;
			}
		}
	}

	.right_content {
		@include flexbox(flex-start, center, column);

		width: 70%;
		height: 100%;
	}

	.right_content_section {
		@include flexbox(flex-start, stretch);

		width: 100%;
		height: auto;
	}

	.about {
		@include flexbox(flex-start, flex-start, column);

		width: 70%;
		min-height: 180px;
		padding: 15px 50px;
	}

	.label {
		@include flexbox(flex-start, flex-start);

		font-weight: bold;
		font-size: 36px;
		line-height: 130%;
		color: var(--color-primary);
		margin-bottom: 6px;
		width: 100%;

		@include max-w(xl) {
			font-size: 18px;
			line-height: 24px;
		}

		> span {
			margin-top: 4px;
			margin-left: 10px;
		}
	}

	.more_button {
		@include flexbox(flex-start, center);

		font-size: 16px;
		color: var(--color-blue);
		margin-left: auto;
		font-weight: normal;
		cursor: pointer;
		align-self: center;

		i {
			font-size: 13px;
			margin-left: 10px;
			color: var(--color-blue);
		}
	}

	.more_info_container {
		width: 100%;
		display: grid;
		align-items: flex-start;
		grid-template: auto / 1fr 1fr;
		margin-top: 40px;
		grid-gap: 10px;
		padding-left: 50px;

		&.column {
			grid-template: auto / 1fr;
		}

		@media screen and (max-width: 1400px) {
			justify-content: flex-start;
			grid-template: auto / 1fr;
		}
	}

	.more_info_table {
		@include flexbox(flex-start, flex-start, column);

		width: auto;

		.row {
			@include flexbox(space-between, center);

			width: 100%;
			min-height: 30px;
		}

		.cell {
			@include flexbox(flex-start, center);

			text-align: left;
			border: none;
			min-width: 60px;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			width: 70px;
			flex: 1;
			font-size: 12px;
			line-height: 14px;
			padding: 0 10px;
			color: var(--color-secondary);

			&.big {
				width: 240px;
			}

			&.percent {
				color: var(--color-primary);
			}

			&.percent {
				color: var(--color-primary);
			}
		}
	}

	.more_info_table_body {
		@include flexbox(flex-start, flex-start, column);

		width: 100%;
	}

	.more_info_table_total_percent {
		color: var(--color-green);
		font-size: 14px;
	}

	.more_info_table_total_percent_progress {
		width: 240px;
		height: 5px;
		background: var(--stripped-normal);
		border-radius: 5px;
		position: relative;
		flex: 1;

		.progress_line {
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			border-radius: 5px;
			background: var(--color-red);
		}
	}

	.more_info_about {
		padding: 10px 24px;
		border-radius: 5px;
		position: relative;
		color: var(--color-secondary);
		width: auto;
		margin-top: 5px;

		> i {
			position: absolute;
			top: 12px;
			left: 0;
			font-size: 14px;
			color: var(--icon-primary);
		}

		@include max-w(xxl) {
			margin-top: 20px;
			margin-left: 0;
		}
	}

	.description {
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 23px;
		color: var(--color-secondary);
		margin-bottom: 10px;
		word-break: break-word;
	}

	.subscription_limit {
		@include flexbox(flex-start, flex-start, column);

		width: 100%;
		margin: 10px 0;
	}

	.subscription_limit_info {
		@include flexbox(flex-end, center);

		width: 100%;
		margin-bottom: 10px;
		flex-wrap: wrap;

		@include max-w(xs) {
			margin-bottom: 5px;
		}

		.sub_label {
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 23px;
			color: var(--color-secondary);
		}

		.value {
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 23px;
			color: var(--color-secondary);
			margin-left: 10px;

			b {
				font-style: normal;
				font-weight: bold;
				font-size: 24px;
				line-height: 28px;
				color: var(--color-primary);
			}

			@include max-w(xs) {
				font-size: 14px;

				b {
					font-size: 18px;
				}
			}
		}
	}

	.subscription_limit_progress {
		width: 100%;
		height: 5px;
		position: relative;
		border-radius: 5px;
		background: var(--divider-border);

		.progress_line {
			position: absolute;
			height: 100%;
			background: var(--color-coral);
			border-radius: 5px;
		}

		&.referral .progress_line {
			background: var(--color-blue);
		}
	}

	.meta {
		@include flexbox(space-between, flex-end);

		width: 100%;
		margin-top: auto;
		flex-wrap: wrap;
	}

	.meta_badge {
		padding: 0 8px;
		text-align: center;
		font-size: 12px;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		line-height: 1.3;
		height: 20px;
		box-sizing: border-box;
		border-radius: 5px;
		vertical-align: bottom;
		font-weight: 400;
		overflow: hidden;
		width: fit-content;
		background-color: rgba(83, 185, 135, 0.1);
		color: var(--color-green);
		white-space: nowrap;
		margin-bottom: 2px;

		&.fixed {
			background-color: rgba(32, 148, 255, 0.1);
			color: var(--color-blue);
		}
	}

	.meta_percentage {
		@include flexbox(flex-start, center);

		font-weight: bold;
		font-size: 24px;
		line-height: 28px;
		color: #00c853;
		margin-right: 14px;

		span {
			font-weight: normal;
			font-size: 16px;
			line-height: 23px;
			color: var(--color-secondary);
			margin-left: 8px;
		}

		@include max-w(xxl) {
			font-size: 20px;
			line-height: 24px;
		}

		@include max-w(xl) {
			font-size: 18px;
		}
	}

	.meta_min_amount {
		font-size: 16px;
		line-height: 23px;
		color: var(--color-secondary);
		margin-bottom: 1px;

		span {
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 19px;
			text-align: right;
			color: var(--color-primary);
			text-transform: uppercase;
		}

		@include max-w(xl) {
			margin-top: 16px;
		}
	}

	.actions {
		@include flexbox(flex-start, flex-start, column);

		height: auto;
		width: 31%;
		padding: 15px 0;
	}

	.actions_type {
		@include flexbox(flex-start, flex-start, column);

		margin-bottom: auto;

		span {
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 16px;
			color: var(--color-secondary);
			margin-bottom: 10px;
		}
	}

	.actions_type_list {
		@include flexbox(flex-start, flex-start);

		flex-wrap: wrap;
	}

	.actions_type_list_item {
		font-size: 14px;
		line-height: 16px;
		display: flex;
		align-items: center;
		text-align: center;
		padding: 8px 10px;
		color: var(--color-primary);
		border: 1px solid var(--color-gold);
		border-radius: 5px;
		background: var(--card-background-color);
		margin: 0 14px 14px 0;

		&.ref {
			color: #4e55a2;
			border: 1px solid #4e55a2;
		}
	}

	.available_time_limit {
		margin: 0 2px 10px auto;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 23px;
		text-align: right;
		color: var(--color-secondary);
	}

	.mobile_info {
		@include flexbox(flex-start, center);

		margin-bottom: 16px;
		padding: 5px 3px;
		border-bottom: 1px solid var(--divider-border);
		width: 100%;
	}

	.mobile_info_group {
		@include flexbox(flex-start, flex-start, column);
	}

	.mobile_meta {
		@include flexbox(flex-start, center);

		width: 100%;
		margin-bottom: 20px;
	}

	.meta_row {
		@include flexbox(flex-start, center);

		flex-wrap: wrap;
		justify-content: flex-end;

		&.marginTop {
			margin-top: 20px;
		}
	}

	.vefirication_row {
		@include flexbox(flex-start, center);

		font-size: 16px;
		line-height: 23px;
	}

	.vefirication_text {
		color: var(--color-secondary);
		margin-right: 5px;
	}

	.vefirication_icon {
		font-size: 14px;
		color: #f79319;
		margin-right: 5px;
	}

	.badge_new {
		color: #fff;
		background: linear-gradient(270deg, #9f0d0c 0%, #6b0480 100%);
		border-radius: 4px;
		min-width: 31px;
		min-height: 13px;
		padding: 1px 4px;
		font-size: 12px;
		line-height: 14px;
		text-align: center;
		position: absolute;
		right: 32px;
		top: 20px;
	}
}

.add_more_funds_button {
	margin-top: 25px;
	font-size: 16px;
}

.close_staking_modal_icon {
	color: var(--color-red) !important;
}

.modal_project_icon {
	img {
		width: 60px;
		height: 60px;
	}
}

.subscribe_modal_container {
	.modal_icon {
		color: var(--color-gold) !important;
	}

	.info_item_group {
		@include flexbox(center, center, column);

		border-bottom: 1px solid var(--divider-border);
		padding: 7px 0;
		width: 100%;

		&.disabled {
			div {
				color: var(--color-disabled) !important;
			}
		}
	}

	.info_item {
		@include flexbox(space-between, center);

		width: 100%;

		&:nth-of-type(2) {
			margin-top: 8px;
		}

		&:nth-of-type(3) {
			margin-top: 8px;
		}

		&:nth-of-type(4) {
			margin-top: 8px;
		}

		&:nth-of-type(5) {
			margin-top: 8px;
		}
	}

	.info_secondary {
		font-weight: normal;
		font-size: 14px;
		line-height: 19px;
		color: var(--color-secondary);

		&.referral {
			text-align: right;
			white-space: nowrap;
		}
	}

	.total_left {
		font-weight: normal;
		font-size: 14px;
		line-height: 19px;
		text-align: right;
		color: var(--color-primary);
	}

	.stake_type_row {
		@include flexbox();
	}

	.stake_type_item {
		@include flexbox(stretch, center);

		font-size: 14px;
		line-height: 16px;
		text-align: center;
		padding: 1px 9px;
		color: var(--color-primary);
		border: 1px solid var(--color-gold);
		border-radius: 5px;
		background: var(--card-background-color);
		margin: 0 0 0 14px;

		&.referral {
			color: #4e55a2;
			border: 1px solid #4e55a2;
		}
	}

	.input_group_container {
		@include flexbox(center, center, column);

		width: 100%;
		margin: 20px 0;
	}

	.display_amount_text {
		color: var(--color-secondary);
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 14px;
		text-align: right;
	}

	.display_amount_row {
		@include flexbox(flex-end);
		width: 100%;
		margin-bottom: 8px;
	}

	.not_enough_funds_container {
		@include flexbox(center, center, column);

		padding: 30px 25px;
		background: var(--stripped-normal);
		border-radius: 0 0 5px 5px;
		margin: auto 0 0;

		> i {
			color: var(--icon-disabled);
			margin-bottom: 14px;
			font-size: 32px;
		}

		> span {
			color: var(--color-secondary);
			font-weight: normal;
			font-size: 14px;
			line-height: 19px;
			text-align: center;
			margin-bottom: 20px;
			padding: 0 15px;
		}
	}

	.not_enough_funds_button_group {
		@include flexbox(flex-start, flex-start, column);

		max-width: 340px;
		width: 100%;

		> a {
			width: 100%;
			margin-bottom: 5px;
		}

		> span {
			font-weight: normal;
			font-size: 12px;
			line-height: 120%;
			color: var(--color-disabled);
		}
	}

	.promo_container {
		@include flexbox(flex-start, flex-start, column);
		width: 100%;
		margin-bottom: 15px;
	}

	.toggle_button {
		@include flexbox(flex-start, center);

		cursor: pointer;
		margin-bottom: 12px;

		i {
			font-size: 12px;
			color: var(--color-secondary);
			margin-right: 6px;
			transition: 0.12s ease;
		}

		span {
			font-size: 14px;
			line-height: 19px;
			color: var(--color-secondary);
			transition: 0.12s ease;
			border-bottom: 1px dashed var(--color-secondary);
		}

		&.active {
			i,
			span {
				color: var(--color-blue);
			}

			span {
				border-bottom: 1px dashed var(--color-blue);
			}
		}

		&.disabled {
			i,
			span {
				color: var(--color-disabled);
			}

			span {
				border-bottom: 1px dashed var(--color-disabled);
			}
		}

		&:hover {
			i,
			span {
				color: var(--color-blue);
				border-color: var(--color-blue);
			}
		}
	}

	.promo_code_help_text {
		@include flexbox(flex-start, flex-start);

		font-size: 12px;
		line-height: 14px;
		color: var(--color-secondary);

		i {
			color: var(--icon-primary);
			font-size: 10px;
			margin-right: 8px;
			margin-top: 1px;
		}
	}
}

.table_container {
	padding: 28px 0;
	width: 100%;
	display: block;
	min-height: 645px;
	height: 645px;
	overflow: hidden;

	@include max-w(xl) {
		padding: 0 0 28px 0;
		margin: 0;
		border: none;
		height: auto;
		min-height: 150px;
	}
}

.table_title {
	margin-bottom: 35px;
	padding-left: 27px;
	font-weight: bold;
	font-size: 24px;
	line-height: 28px;
	color: var(--color-primary);
	width: 100%;
	text-align: left;
}

.table_row {
	height: 40px;

	.logo {
		i {
			margin-right: 18px;
		}
	}

	.add_funds {
		> i {
			font-size: 22px;
			color: var(--color-blue);
		}

		&:hover > i {
			color: var(--color-blue);
		}
	}
}

.interest_row {
	border-bottom: 1px solid;
	border-color: var(--table-stripped-border-color);

	&:first-child {
		border-top: 1px solid var(--table-stripped-border-color);
	}

	&:last-child {
		border-color: transparent;
	}
}

.payment_history_link {
	color: var(--color-blue);
	font-size: 14px;

	i {
		color: var(--color-blue) !important;
		margin-left: 14px;
		font-size: 10px;
	}
}

.profitability {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: max-content;
	position: relative;
	cursor: pointer;
}

.income {
	color: var(--color-green);
}

.get_interest_button {
	max-width: 156px;
	height: 28px !important;
	min-height: 28px !important;
	align-items: center;
	line-height: 1;
	font-size: 12px;
	padding: 0 14px;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-weight: normal;
	overflow: hidden;

	> div {
		padding: 0;
	}

	span {
		font-size: 12px !important;
	}

	&.coral {
		background-color: var(--color-coral);
	}

	@include max-w(xl) {
		width: 100%;
		max-width: 100%;
		margin-bottom: 8px;
	}
}

.close_position_button {
	cursor: pointer;
	color: var(--color-red);

	i {
		font-size: 20px;
		color: var(--color-red) !important;
		transition: 0.12s ease;
	}

	&:hover i {
		color: var(--color-red);
	}
}

.flexible_staking_type {
	font-size: 14px;
	line-height: 16px;
	display: inline-flex;
	align-items: center;
	text-align: center;
	padding: 4px 10px;
	color: var(--color-primary);
	border: 1px solid var(--color-gold);
	border-radius: 5px;
	background: var(--card-background-color);
	width: max-content;
}

.promo_sign {
	@include flexbox(center, center);

	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 120%;
	text-align: center;
	color: var(--color-red);
	border: 1px solid var(--color-red);
	box-sizing: border-box;
	border-radius: 5px;
	width: 20px;
	height: 20px;
	min-width: 20px;
	min-height: 20px;
	position: relative;
	cursor: pointer;

	> div {
		display: none;
	}

	&:hover div,
	&:focus div {
		display: flex;
	}
}

.positions {
	padding: 20px;
}

.mobile_expanded_container {
	@include flexbox(flex-start, flex-start, column);

	padding: 15px 20px;

	.expanded_group {
		@include flexbox(space-between, center);

		width: 100%;
		margin-bottom: 10px;
	}

	.expanded_group_label {
		font-weight: normal;
		font-size: 12px;
		line-height: 1.2;
		color: var(--color-secondary);
	}

	.expanded_group_value {
		@include flexbox(center, center);

		font-weight: normal;
		font-size: 14px;
		line-height: 1.3;
		text-align: right;
		color: var(--color-primary);
	}

	.close_position_text {
		cursor: pointer;
		color: var(--color-secondary);
		text-decoration: underline;
	}
}

.mobile_card {
	border-radius: 5px;
	border: 1px solid var(--card-outlined-border-color);
	font-size: 16px;
	line-height: 1.3;
	background: none;
	margin-bottom: 10px;
	width: 100%;

	&.expanded {
		box-shadow: var(--select-shadow);

		.mobile_header {
			background-color: var(--table-hover-background);
		}
	}

	.mobile_header {
		@include card-header();

		padding: 10px 15px 10px 90px;
		position: relative;
		background-color: var(--card-header-background);
		width: 100%;
		border-radius: 5px 5px 0 0;
		min-height: 38px;

		@include max-w(xs) {
			padding: 10px 15px 10px 60px;
		}
	}

	.mobile_currency {
		@include flexbox(center, center);

		color: var(--color-primary);
		font-weight: normal;
		font-size: 12px;
		line-height: 22px;
		position: absolute;
		left: 10px;
		text-align: center;

		@media screen and (max-width: 350px) {
			width: 35px;
			max-width: 35px;
		}

		span {
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			width: 50px;
			max-width: 50px;
			display: block;
			text-align: left;
		}
	}

	.mobile_date_time {
		@include flexbox(center, center);

		margin-right: auto;
	}

	.mobile_date_time_item {
		display: flex;

		margin-left: 3px;
		font-size: 12px;
		line-height: 14px;
		color: var(--color-secondary);

		&:first-child {
			color: var(--color-primary);
		}
	}

	.mobile_action {
		margin: 0 0 0 auto;

		i {
			font-size: 12px;
			color: var(--icon-primary);
		}

		&.active {
			color: var(--icon-pressed);
			transform: rotate(180deg);
		}

		&:last-child i {
			font-size: 10px;
		}
	}

	.mobile_content {
		@include flexbox(stretch, stretch, column);

		flex: 1 0 auto;
		font-size: 14px;
		width: 100%;
		padding: 14px 20px 14px 20px;
		position: relative;

		> i:first-child {
			position: absolute;
			left: 23px;
			font-size: 24px;
		}
	}

	.mobile_content_info {
		@include flexbox(space-between, flex-start);

		width: 100%;
		margin-bottom: 16px;
	}

	.mobile_content_icon {
		width: 34px;

		i {
			font-size: 32px;
			color: var(--icon-primary);
		}
	}

	.mobile_content_number {
		@include flexbox(flex-start, flex-start, column);

		width: calc(100% - 55px);
	}

	.mobile_content_number_item {
		@include flexbox(space-between, center);

		width: 100%;
		margin-bottom: 5px;

		span {
			font-size: 12px;
			line-height: 120%;
			color: var(--color-secondary);

			&:last-child {
				font-size: 14px;
				margin-left: 4px;
			}
		}

		&:nth-of-type(2) {
			span:last-child {
				color: var(--color-primary);
			}
		}
	}
}
