@import "styles/mixins";

.mobileApp {
	margin-top: 80px;

	@media screen and (max-width: 993px) {
		margin-top: 60px;
	}

	&__container {
		@include home-page-container;

		position: relative;
	}

	&__containerWrapper {
		display: flex;
		padding: 48px 50px 0 50px;
		border-radius: 12px;
		background: var(--mobile-app-background);

		position: relative;
		z-index: 2;

		@media screen and (max-width: 1399px) {
			padding: 24px 50px 0 50px;
		}

		@media screen and (max-width: 1199px) {
			padding: 16px 26px 0 34px;
		}

		@media screen and (max-width: 993px) {
			padding: 12px 22px 0 26px;
		}

		@media screen and (max-width: 599px) {
			flex-direction: column-reverse;
			padding: 24px 24px 0 24px;
		}
	}

	&__phoneImage {
		display: flex;
		margin-top: auto;
		max-width: 100%;
		position: relative;
		z-index: 2;

		@media screen and (max-width: 767px) {
			display: none;
		}
	}

	&__mobileImage {
		max-width: 100%;
		display: none;

		@media screen and (max-width: 767px) {
			display: flex;
		}
	}

	&__donutImage {
		position: absolute;
		max-width: 330px;
		top: 20px;
		right: 120px;
		z-index: 1;

		@media screen and (max-width: 993px) {
			max-width: 200px;
			right: 60px;
		}

		@media screen and (max-width: 767px) {
			display: none;
		}
	}

	&__images {
		max-width: 58%;
		position: relative;
		padding-right: 50px;
		align-self: flex-end;

		@media screen and (max-width: 1199px) {
			max-width: 60%;
			padding-right: 20px;
		}

		@media screen and (max-width: 993px) {
			max-width: 56%;
			padding-right: 12px;
		}

		@media screen and (max-width: 767px) {
			max-width: 45%;
			padding-right: 30px;
		}

		@media screen and (max-width: 599px) {
			max-width: 235px;
			padding-right: 0;
			margin-left: auto;
			margin-right: auto;
			margin-top: 34px;
		}
	}

	&__contentWrapper {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding-top: 21px;
		padding-bottom: 60px;

		@media screen and (max-width: 1199px) {
			padding-top: 12px;
			padding-bottom: 30px;
		}

		@media screen and (max-width: 993px) {
			padding-bottom: 22px;
		}

		@media screen and (max-width: 599px) {
			padding: 0;
		}
	}

	&__headline {
		@include home-page-headline;

		margin: 0;
		text-align: left;

		@media screen and (max-width: 1199px) {
			font-size: 32px;
		}

		@media screen and (max-width: 993px) {
			font-size: 28px;
		}
	}

	&__content {
		@include home-page-content;

		margin: 16px 0 0 0;
		text-align: left;
		max-width: 330px;

		@media screen and (max-width: 993px) {
			margin: 8px 0 0 0;
		}

		@media screen and (max-width: 599px) {
			max-width: 100%;
			margin-top: 16px;
		}
	}

	&__list {
		list-style: none;
		margin: 32px 0 32px 0;
		padding: 0;

		@media screen and (max-width: 1199px) {
			margin: 16px 0 24px 0;
		}

		@media screen and (max-width: 993px) {
			margin: 8px 0 18px 0;
		}

		@media screen and (max-width: 599px) {
			margin: 16px 0 0 0;
		}
	}

	&__item {
		display: flex;
		align-items: center;
		padding: 10px 0;

		@media screen and (max-width: 993px) {
			padding: 6px 0;
		}

		svg {
			margin-right: 12px;

			@media screen and (max-width: 1199px) {
				margin-right: 8px;
			}
		}

		span {
			color: var(--mobile-app-item-text-color);
			font-size: 18px;
			font-weight: 400;
			line-height: 24px;

			@media screen and (max-width: 1199px) {
				font-size: 16px;
			}
		}
	}

	&__buttonWrapper {
		display: flex;
		gap: 8px;
		margin-top: 32px;

		@media screen and (max-width: 599px) {
			margin-top: 24px;
			width: 100%;
		}
	}

	&__button {
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 16px;
		border: 1px solid #fff;
		margin-top: auto;
		min-width: 161px;
		transition: 0.3s ease-in-out;

		&___disabled {
			opacity: 0.3;
			pointer-events: none;
		}

		&:hover {
			opacity: 0.6;
		}

		@media screen and (max-width: 599px) {
			margin-top: 24px;
			width: 100%;
		}
	}

	&__circle {
		position: absolute;
		bottom: -105px;
		left: 185px;
		border: 1px solid rgba(210, 232, 181, 0.8);
		border-radius: 100%;
		width: 115px;
		height: 115px;
		backdrop-filter: blur(7px);
		background: rgba(206, 240, 163, 0.6);
		z-index: 1;
	}

	&__triangle {
		position: absolute;
		bottom: -180px;
		right: 220px;
		z-index: 1;
	}

	&__circleBlue {
		backdrop-filter: blur(7px);
		background: rgba(163, 212, 240, 0.2);
		border: 1px solid rgba(181, 202, 232, 0.4);
		border-radius: 100%;
		width: 35px;
		height: 35px;
		position: absolute;
		bottom: 56px;
		right: -20px;
	}
}
