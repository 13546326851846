@import "styles/mixins";

.footer {
	margin-top: 32px;
	padding-bottom: 80px;

	@media screen and (max-width: 1199px) {
		padding-bottom: 40px;
	}

	@media screen and (max-width: 993px) {
		padding-bottom: 24px;
	}

	&__container {
		@include main-container;

		&___home {
			@include home-page-container;
		}
	}

	&__data {
		@media screen and (max-width: 993px) {
			border-top: 1px solid #c5cbe1;
			margin-top: 24px;
			padding-top: 24px;
			display: flex;
			justify-content: space-between;
		}

		@media screen and (max-width: 599px) {
			flex-direction: column;
			align-items: center;
		}
	}

	&__content {
		display: flex;
		padding: 54px 0 68px 70px;
		border-radius: 12px;
		background: var(--footer-background);

		@media screen and (max-width: 1199px) {
			padding: 32px 0 40px 40px;
		}

		@media screen and (max-width: 993px) {
			flex-direction: column-reverse;
			padding: 32px;
		}

		@media screen and (max-width: 767px) {
			padding: 24px;
		}

		@media screen and (max-width: 599px) {
			overflow: hidden;
		}
	}

	&__logoWrapper {
		height: 34px;
		font-size: 28px;

		@media screen and (max-width: 993px) {
			display: none;
		}
	}

	&__copy_license_wrapper {
		color: var(--footer-column-copy-color);
		margin-top: 8px;
		width: 230px;
		font-size: 14px;
		line-height: 148%;
		letter-spacing: -0.08px;

		@media screen and (max-width: 993px) {
			margin: 0;
		}

		@media screen and (max-width: 599px) {
			text-align: center;
		}
	}

	&__license {
		margin-top: 8px;

		p {
			margin: 0;
		}
	}

	&__socialList {
		display: flex;
		align-items: center;
		margin: 46px 0 0 0;
		padding: 0;
		list-style: none;
		max-width: 250px;
		width: 100%;
		column-gap: 24px;
		row-gap: 12px;
		flex-wrap: wrap;

		@media screen and (max-width: 993px) {
			margin: 0;
			max-width: unset;
			width: unset;
		}

		@media screen and (max-width: 599px) {
			margin-top: 16px;
			justify-content: center;
		}
	}

	&__socialItem {
		&___instagram svg path {
			fill: var(--footer-svg-fill);
		}
	}

	&__columns {
		flex: 1;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 30px;
		list-style: none;
		padding: 0;
		margin: 0 0 0 60px;

		@media screen and (max-width: 1199px) {
			margin: 0 0 0 40px;
		}

		@media screen and (max-width: 993px) {
			margin: 0;
		}

		@media screen and (max-width: 767px) {
			grid-gap: 24px;
		}

		@media screen and (max-width: 599px) {
			grid-gap: unset;
			display: flex;
			flex-direction: column;
			grid-template-columns: unset;
		}
	}

	&__columnHeadlineButton {
		pointer-events: none;

		svg {
			display: none;
		}

		@media screen and (max-width: 599px) {
			pointer-events: unset;
			padding-top: 14px;
			padding-bottom: 14px;
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;

			svg {
				transition: 0.3s ease-in;
				display: flex;
			}
		}
	}

	&__columnHeadline {
		color: var(--footer-column-headline-color);
		font-size: 18px;
		font-weight: 600;
		line-height: 132%;
		letter-spacing: 0.2px;
		margin: 0;

		@media screen and (max-width: 599px) {
			font-size: 20px;
		}
	}

	&__columnList {
		list-style: none;
		padding: 0;
		margin: 0;

		@media screen and (max-width: 599px) {
			height: 0;
			opacity: 0;
			pointer-events: none;
		}
	}

	&__columnItem {
		margin-top: 12px;

		@media screen and (max-width: 599px) {
			margin-top: 24px;

			&:first-child {
				margin-top: 0;
				padding-top: 12px;
			}

			&:last-child {
				padding-bottom: 12px;
			}
		}
	}

	&__column {
		@media screen and (max-width: 599px) {
			&___active {
				.footer__columnHeadlineButton svg {
					transform: rotate(180deg);
				}

				.footer__columnList {
					height: auto;
					opacity: 1;
					pointer-events: auto;
				}
			}
		}
	}

	&__link {
		color: var(--footer-column-link-color);
		font-size: 16px;
		line-height: 132%;
		letter-spacing: 0.06px;

		@media screen and (max-width: 599px) {
			color: var(--footer-column-link-color--mobile);
		}

		@media (hover: hover) and (pointer: fine) {
			transition: 0.3s ease-in;

			&:hover {
				color: var(--footer-column-link-color--hover);
			}
		}
	}
}
