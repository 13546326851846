.orderPriceQty {
	display: flex;
	flex-direction: column;
	padding: 12px 16px;

	&__qty {
		margin-top: 12px;
	}

	&__orderPrice {
		display: flex;
		margin-top: 12px;
	}

	&__orderPriceWrapper {
		margin-top: auto;
	}

	&__orderPriceButton {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-radius: 8px;
		background: #2c2c2e;
		padding: 12px 16px;
		height: 40px;
		cursor: pointer;
		width: 100px;
		margin-top: auto;
		flex: 1;

		&___disabled {
			opacity: 0.5;
			pointer-events: none;
		}

		@media (hover: hover) and (pointer: fine) {
			transition: 0.3s ease-in-out;

			&:hover {
				opacity: 0.7;
			}
		}
	}

	&__orderPriceButtonText {
		color: #fff;
		font-size: 14px;
		font-weight: 400;
		line-height: 16px;
	}

	&__price {
		&___order {
			display: flex;
			flex-direction: column;
			flex: 1;
			margin-right: 6px;
			max-width: 162px;
		}
	}

	&__labelText {
		display: flex;
		color: rgba(235, 235, 245, 0.6);
		font-size: 12px;
		font-weight: 400;
		line-height: 14px;
		margin-bottom: 6px;
	}
}
