@import "src/assets/styles/common/mixins";

.card {
	@include card();
	padding: 0 0 5px 0;
	margin: 0;
	position: relative;
}

.card_title {
	@include card-title();
	@include flexbox(flex-start, center);
	margin: 0;

	i {
		font-size: 24px;
		margin-right: 10px;
	}

	a {
		cursor: pointer;
		margin-left: auto;
		color: var(--color-blue);

		i {
			font-size: 16px;
			margin-left: 15px;
		}
	}

	@include max-w(xxl) {
		i {
			font-size: 24px;
			margin-right: 15px;
		}
	}

	@include max-w(md) {
		width: 100%;

		i {
			font-size: 24px;
			margin-right: 10px;
		}

		> a i {
			font-size: 14px;
			margin-left: 15px;
		}
	}
}

.card_header {
	@include card-header();
	width: 100%;
	min-height: 60px;
	padding: 18px 24px;
	border-bottom: 1px solid var(--table-header-border-color);
	background-color: var(--card-background-color);

	&.link {
		background-color: #3a3a3c30;
		&:hover {
			background-color: var(--stripped-normal);
		}
	}

	&.no_border {
		border-bottom-color: transparent;
	}

	> a,
	> span {
		&:last-child {
			@include flexbox(flex-start, center);
			cursor: pointer;
			margin-left: 25px;
			color: var(--color-blue);
			i {
				font-size: 16px;
				margin-left: 15px;
			}
		}
	}

	@include max-w(xxl) {
		min-height: 55px;
	}

	@include max-w(md) {
		padding: 16px;

		> a,
		> span {
			&:last-child {
				margin: 0;
			}
		}
	}
}
