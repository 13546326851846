.wallet {
	padding-top: 60px;
	padding-bottom: 30px;

	@media screen and (max-width: 679px) {
		padding-top: 40px;
		padding-bottom: 20px;
	}

	&__container {
		width: 100%;
		max-width: 1024px;
		margin-left: auto;
		margin-right: auto;
		padding: 0 24px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 40px;

		@media screen and (max-width: 679px) {
			flex-direction: column-reverse;
			gap: 0;
		}
	}

	&__banner {
	}

	&__imageMobile {
		display: none;

		@media screen and (max-width: 767px) {
			display: flex;
		}
	}

	&__image {
		@media screen and (max-width: 767px) {
			display: none;
		}
	}

	&__content {
		width: 100%;
		max-width: 410px;
		display: flex;
		flex-direction: column;
	}

	&__title {
		margin: 0;
		color: #fff;
		font-size: 40px;
		font-weight: 600;
		line-height: 132%;
		letter-spacing: -0.45px;

		@media screen and (max-width: 993px) {
			font-size: 32px;
		}
	}

	&__description {
		margin: 25px 0 0;
		color: #fff;
		font-size: 18px;
		font-weight: 400;
		line-height: 132%;
		letter-spacing: 0.06px;

		@media screen and (max-width: 993px) {
			font-size: 16px;
			margin-top: 16px;
		}
	}
}
