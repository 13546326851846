@import "~@btc-alpha/ui-components/dist/styles/functions";
@import "~@btc-alpha/ui-components/dist/styles/media";
@import "~@btc-alpha/ui-components/dist/styles/mixins";

.header {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: rems(48);

	.logo {
		width: rems(100);
		height: auto;
	}

	.title {
		margin-top: rems(32);
		margin-bottom: rems(16);
		color: var(--alpha-ui-color-text-primary);
		@include font(44, 500, 44);
	}

	.description {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: rems(8);
		text-align: center;

		* {
			@include font(16, 400, 20);
		}

		span {
			color: var(--alpha-ui-color-label-secondary);
		}

		a {
			color: var(--alpha-ui-color-accent-purple);
			text-decoration: underline transparent;
			transition: 0.2s;

			@include hover {
				text-decoration-color: var(--alpha-ui-color-accent-purple);
			}
		}
	}

	.email {
		color: var(--alpha-ui-color-text-primary);
		@include font(16, 500, 20);
	}
}
