@import "~@btc-alpha/ui-components/dist/styles/functions";
@import "~@btc-alpha/ui-components/dist/styles/media";
@import "~@btc-alpha/ui-components/dist/styles/mixins";

.root {
	display: flex;
	flex-direction: column;
	border-radius: rems(12);
	overflow-y: hidden;
	background-color: var(--alpha-ui-color-background-base-3);

	* {
		color: var(--alpha-ui-color-text-primary);
	}

	.name {
		position: relative;
		display: flex;
		flex-direction: row;
		gap: rems(8);
		padding: rems(12) rems(16);
		transition: background-color 0.2s;

		& > div {
			width: rems(20);
			height: rems(20);
			border-radius: 50%;
			overflow: hidden;
			background-color: var(--alpha-ui-color-background-base-1);
			background-size: rems(22);
			background-repeat: no-repeat;
			background-position: center rems(2);
		}

		span {
			@include font(14, 500, 20);
		}

		.chevron {
      width: rems(20);
      height: rems(20);
			position: absolute;
			right: rems(26);
			top: 50%;
			transform: translateY(-50%);
			opacity: 0;
			transition: 0.2s;
		}

		@include hover {
			background-color: var(--alpha-ui-color-background-base-1);

			.chevron {
				right: rems(16);
				opacity: 1;
			}
		}
	}

	.info {
		display: flex;
		flex-direction: row;
		align-items: stretch;
		border-top: rems(1) solid var(--alpha-ui-color-separator-opaque);

		& > div {
			flex-grow: 1;
			display: flex;
			flex-direction: column;
			gap: rems(2);
			padding: rems(12) rems(16);

			& > span {
				color: var(--alpha-ui-color-label-secondary);
				@include font(10, 500, 10);
			}

			& > div {
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: rems(6);

				& > span {
					@include font(14, 400, 20);
				}

				& > button {
					width: rems(16);
					height: rems(16);
          transition: filter 0.2s;

          @include hover {
            filter: brightness(1000);
          }
				}
			}
		}

		hr {
			align-self: center;
			width: rems(1);
			height: rems(24);
			background-color: var(--alpha-ui-color-separator-opaque);
		}
	}
}
