@import "~@btc-alpha/ui-components/dist/styles/functions";
@import "~@btc-alpha/ui-components/dist/styles/media";

.menuButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: rems(44);
  padding: 0 rems(12);
  gap: rems(8);
  border-radius: rems(12);
  border: rems(1) solid transparent;
  cursor: pointer;
  transition: 0.2s;

  &.active {
    background-color: var(--alpha-ui-color-background-base-1);
  }

  &:disabled, &.disabled {
    opacity: 0.3;
  }

  &.active, &:disabled, &.disabled {
    pointer-events: none;
  }

  @include hover {
    border-color: var(--alpha-ui-color-separator-opaque);
    background-color: var(--alpha-ui-color-background-base-1);
  }

  &:active {
    box-shadow: inset 0 0 0 rems(1) var(--alpha-ui-color-separator-opaque);
  }

  img {
    width: rems(20);
    height: rems(20);
    object-fit: contain;
  }

  span {
    font-size: rems(14);
    font-weight: 500;
    line-height: rems(20);
    color: var(--alpha-ui-color-text-primary);
  }
}
