@import "~@btc-alpha/ui-components/dist/styles/functions";
@import "~@btc-alpha/ui-components/dist/styles/media";

.root {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	gap: rems(72);

	.main {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		gap: rems(12);

		.info {
			display: flex;
			flex-direction: column;
			align-items: stretch;
			gap: rems(12);
			width: 50%;
			max-width: 440px;

			@include tablet-portrait {
				width: 100%;
				max-width: none;
			}

			& > header {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;

				& > span {
					font-size: rems(16);
					font-weight: 500;
					line-height: rems(20);
				}
			}

			& > .faq {
				display: flex;
				flex-direction: column;
				align-items: stretch;
				gap: rems(8);

				&:not(:last-child) {
					padding-bottom: rems(12);
					border-bottom: rems(1) solid var(--alpha-ui-color-separator-opaque);
				}
			}
		}

		@include tablet-portrait {
			flex-direction: column;
			gap: rems(50);
		}
	}

	.history {
		h2 {
			padding: 0;
			margin: 0;
			margin-bottom: rems(20);
			font-size: rems(20);
			font-weight: 500;
			line-height: rems(28);
		}
	}
}

.form {
	width: 100%;
	flex-grow: 1;

	.step {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		gap: rems(16);
	}

	.confirmation {
		display: flex;
		flex-direction: row;
		align-items: flex-end;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-top: rems(32);
		gap: rems(16);

		p {
			padding: 0;
			margin: 0;
			max-width: rems(220);
		}
	}

  .security {
    display: flex;
    flex-direction: row;
    gap: rems(8);

    & > :first-child {
      flex-grow: 1;
    }
  }
}

.success {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;

	img {
		width: rems(170);
		height: rems(170);
		object-fit: contain;
		margin-bottom: rems(20);
	}

	strong {
		font-size: rems(24);
		font-weight: 500;
		line-height: rems(32);
	}

	span {
		font-size: rems(14);
		font-weight: 400;
		line-height: rems(20);
		color: var(--alpha-ui-color-label-secondary);
	}
}

.confirmation {
	display: flex;
	flex-direction: column;
	gap: rems(20);

	.content {
		display: flex;
		flex-direction: column;
		gap: rems(20);

		.code > div {
			gap: rems(8);

			& > div {
				flex-grow: 1;
			}
		}

		.fieldIcon {
			width: rems(20);
			height: rems(20);
		}

		.resend button {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: rems(8);
			font-size: rems(14);
			color: var(--alpha-ui-color-accent-purple);
			transition: opacity 0.2s;

			&:disabled {
				pointer-events: none;
				opacity: 0.5;
			}
		}
	}

	.footer {
		display: flex;
		flex-direction: row;
		gap: rems(16);

		button {
			flex-grow: 1;
		}
	}
}
