@import "styles/mixins";

.banner {
	min-height: calc(100vh - var(--redesigned-header-height));
	position: relative;
	display: flex;
	align-items: center;

	@media screen and (max-width: 1199px) {
		padding: 80px 0;
	}

	@media screen and (max-width: 993px) {
		padding: 60px 0;
	}

	@media screen and (max-width: 767px) {
		padding: 0;
		overflow: hidden;
	}

	&__bigCircle {
		top: 200px;
		left: 51%;
		position: absolute;
		border-radius: 100%;
		border: 1px solid rgba(210, 232, 181, 0.8);
		width: 274px;
		height: 274px;
		backdrop-filter: blur(7px);
		background: rgba(206, 240, 163, 0.6);
		z-index: 1;

		@media screen and (max-width: 1199px) {
			top: 160px;
			left: 48%;
		}

		@media screen and (max-width: 993px) {
			top: 100px;
			left: 38%;
		}

		@media screen and (max-width: 767px) {
			right: -120px;
			left: unset;
			top: 370px;
		}
	}

	&__smallCircle {
		top: 400px;
		left: 51%;
		position: absolute;
		border-radius: 100%;
		border: 1px solid rgba(181, 202, 232, 0.4);
		width: 40px;
		height: 40px;
		backdrop-filter: blur(3.5px);
		background: rgba(163, 212, 240, 0.3);
		z-index: 1;

		@media screen and (max-width: 1199px) {
			top: 350px;
			left: 48%;
		}

		@media screen and (max-width: 993px) {
			top: 300px;
			left: 38%;
		}

		@media screen and (max-width: 767px) {
			right: 82px;
			top: 600px;
			left: unset;
		}
	}

	&__container {
		display: flex;
		justify-content: space-between;
		position: relative;
		z-index: 2;

		@include home-page-container;

		@media screen and (max-width: 767px) {
			display: flex;
			flex-direction: column-reverse;
		}
	}

	&__content {
		padding: 58px 0 26px 0;
		margin-right: 90px;
		max-width: 651px;

		@media screen and (max-width: 993px) {
			padding: 32px 0 26px 0;
			margin-right: 32px;
		}

		@media screen and (max-width: 767px) {
			margin-right: 0;
			max-width: 100%;
		}

		@media screen and (max-width: 480px) {
			padding-top: 0;
			padding-bottom: 0;
		}
	}

	&__headline {
		color: transparent;
		font-size: 64px;
		font-style: normal;
		font-weight: 600;
		line-height: 108%;
		letter-spacing: -3px;
		background: var(--banner-headline-background);
		background-clip: text;
		max-width: 624px;

		margin: 0;

		@media screen and (max-width: 1199px) {
			font-size: 52px;
		}

		@media screen and (max-width: 993px) {
			max-width: 450px;
			font-size: 42px;
		}

		@media screen and (max-width: 767px) {
			max-width: 100%;
			letter-spacing: -2px;
		}
	}

	&__text {
		color: var(--banner-content-color);
		font-size: 18px;
		font-style: normal;
		font-weight: 400;
		line-height: 28px;
		letter-spacing: -0.3px;
		max-width: 490px;

		margin-top: 24px;
		margin-bottom: 0;

		@media screen and (max-width: 1199px) {
			font-size: 16px;
			max-width: 410px;
		}

		@media screen and (max-width: 993px) {
			margin-top: 16px;
		}

		@media screen and (max-width: 767px) {
			max-width: 100%;
		}

		@media screen and (max-width: 480px) {
			margin-top: 24px;
		}
	}

	&__inputWrapper {
		display: flex;

		margin-top: 44px;

		@media screen and (max-width: 993px) {
			flex-direction: column;
		}

		@media screen and (max-width: 993px) {
			margin-top: 28px;
		}

		@media screen and (max-width: 767px) {
			flex-direction: row;
		}

		@media screen and (max-width: 480px) {
			flex-direction: column;
			margin-top: 24px;
		}
	}

	&__estimatedBalance {
		display: flex;
		flex-direction: column;
		margin-top: 14px;
	}

	&__estimatedBalanceHeadline {
		font-weight: 400;
		font-size: 18px;
		line-height: 132%;
		margin: 0;
		color: var(--secondary-text-color);
	}

	&__estimatedBalanceContent {
		font-weight: 600;
		font-size: 32px;
		line-height: 125%;
		letter-spacing: -0.03px;
		margin-top: 5px;
		color: var(--main-text-color);
		margin-bottom: 0;
	}

	&__buttonsWrapper {
		display: flex;
		margin-top: 32px;

		@media screen and (max-width: 579px) {
			width: 100%;
		}
	}

	&__depositButton {
		@include home-page-main-button;

		margin-right: 12px;
		min-width: 150px;

		@media screen and (max-width: 579px) {
			flex: 1;
		}
	}

	&__tradeButton {
		@include home-page-main-button(true);

		min-width: 150px;

		@media screen and (max-width: 579px) {
			flex: 1;
		}
	}

	&__input {
		@include home-page-input;

		max-width: 356px;

		@media screen and (max-width: 767px) {
			max-width: 100%;
		}

		@media screen and (max-width: 480px) {
			min-height: 54px;
		}
	}

	&__signUpButton {
		@include home-page-main-button;

		min-width: 131px;
		margin-left: 10px;
		max-width: 356px;

		@media screen and (max-width: 993px) {
			margin-left: 0;
			margin-top: 8px;
		}

		@media screen and (max-width: 767px) {
			margin-left: 10px;
			margin-top: 0;
		}

		@media screen and (max-width: 480px) {
			margin-left: 0;
			margin-top: 16px;
			max-width: 100%;
			height: 54px;
		}
	}

	&__partnersList {
		margin-top: 12px;
		margin-bottom: 0;
		padding: 0;
		display: flex;
		list-style: none;
	}

	&__partnersItem {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 32px;

		&:last-child {
			margin-right: 0;
		}

		@media screen and (max-width: 1199px) {
			svg {
				max-width: 90px;
				max-height: 90px;
			}
		}

		@media screen and (max-width: 993px) {
			svg {
				max-width: 80px;
				max-height: 80px;
			}
		}
	}

	&__illustration {
		position: relative;
		z-index: 1;
		display: flex;
		justify-content: center;
		align-items: center;
		pointer-events: none;
		max-width: 500px;

		canvas {
			width: 500px !important;
			height: 500px !important;
		}

		@media screen and (max-width: 1199px) {
			max-width: 400px;

			canvas {
				width: 400px !important;
				height: 400px !important;
			}
		}

		@media screen and (max-width: 993px) {
			max-width: 320px;

			canvas {
				width: 320px !important;
				height: 320px !important;
			}
		}

		@media screen and (max-width: 767px) {
			max-width: 400px;
			margin-left: auto;
			margin-right: auto;

			canvas {
				width: 400px !important;
				height: 400px !important;
			}
		}

		@media screen and (max-width: 480px) {
			max-width: 375px;
			margin-left: auto;
			margin-right: auto;

			canvas {
				width: 375px !important;
				height: 375px !important;
			}
		}

		@media screen and (max-width: 450px) {
			max-width: 330px;
			margin-left: auto;
			margin-right: auto;

			canvas {
				width: 330px !important;
				height: 330px !important;
			}
		}
	}

	&__slider {
		position: relative;
		width: 34%;
		min-width: 380px;
		height: 532px;

		@media screen and (max-width: 1199px) {
			min-width: 320px;
			height: 500px;
		}

		@media screen and (max-width: 993px) {
			height: 430px;
		}

		@media screen and (max-width: 767px) {
			width: 100%;
			height: 370px;
		}
	}
}
