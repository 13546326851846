@import "~@btc-alpha/ui-components/dist/styles/functions";
@import "~@btc-alpha/ui-components/dist/styles/media";

.closedOrders {
	.table {
		margin-top: rems(20);
		margin-bottom: rems(70);

		tr:has(.cancelled) {
			opacity: 0.5;
		}
	}
}
