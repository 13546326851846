@import "styles/mixins";

.partners {
	margin-top: 80px;
	position: relative;
	z-index: 2;

	@media screen and (max-width: 993px) {
		margin-top: 40px;
	}

	@media screen and (max-width: 767px) {
		margin-top: 48px;
	}

	&__container {
		@include home-page-container;
	}

	&__headline {
		@include home-page-headline;

		margin: 0;
		letter-spacing: -0.45px;
	}

	&__content {
		@include home-page-content;

		letter-spacing: 0.06px;
		margin: 16px 0 0 0;

		@media screen and (max-width: 993px) {
			margin: 12px 0 0 0;
		}
	}

	&__contentLink {
		color: #7c59ff;
	}

	&__slider {
		@include home-page-container;

		margin-top: 40px;
		position: relative;
		padding-left: 0 !important;
		padding-right: 0 !important;

		@media screen and (max-width: 993px) {
			margin-top: 24px;
		}

		&:before {
			content: "";
			display: flex;
			width: 138px;
			height: 100%;
			position: absolute;
			left: 0;
			bottom: 0;
			top: 0;
			background: var(--partners-before-background);
			z-index: 2;

			@media screen and (max-width: 767px) {
				width: 100px;
			}
		}

		&:after {
			content: "";
			display: flex;
			width: 138px;
			height: 100%;
			position: absolute;
			right: 0;
			bottom: 0;
			top: 0;
			background: var(--partners-after-background);
			z-index: 2;

			@media screen and (max-width: 767px) {
				width: 100px;
			}
		}
	}

	&__sliderItem {
		display: flex !important;
		justify-content: center;
		align-items: center;
		height: 138px;
		border-radius: 12px;
		border: 1px solid var(--partners-item-border-color);
		margin: 0 12px !important;
		padding: 0 12px;

		@media (hover: hover) and (pointer: fine) {
			transition: 0.3s ease-in-out;

			&:hover {
				opacity: 0.6;
			}
		}

		@media screen and (max-width: 1299px) {
			height: 120px;
		}

		@media screen and (max-width: 993px) {
			height: 110px;
		}

		@media screen and (max-width: 767px) {
			height: 100px;
			margin: 0 7.5px !important;
		}
	}

	&__sliderControls {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 12px;
		margin-top: 32px;

		@media screen and (max-width: 993px) {
			margin-top: 24px;
		}
	}

	&__leftControl {
		@include home-page-control-buttons;
	}

	&__rightControl {
		@include home-page-control-buttons;
	}
}
