.confirmModal {
	&__label {
		font-size: 20px;
		font-weight: 600;
		color: #ffffff;

		&___buy {
			color: #20b26c;
			font-weight: 500;
		}

		&___sell {
			color: #ef454a;
			font-weight: 500;
		}
	}

	&__content {
		padding: 20px;
		border-top: 1px solid rgba(84, 84, 88, 0.36);
	}

	&__item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 16px;
	}

	&__headline {
		font-size: 16px;
		font-weight: 400;
		color: #ffffff80;
	}

	&__value {
		font-size: 16px;
		font-weight: 500;
		color: #ffffff;
	}

	&__buttons {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 16px;
		margin-top: 32px;
	}

	&__button {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 48px;
		border-radius: 8px;
		background-color: #2c2c2e;
		color: #fff;
		font-size: 14px;
		font-weight: 500;
		line-height: 18px;
		transition: 0.3s ease-in-out;

		&___save {
			background-color: #7c59ff;
		}

		@media (hover: hover) and (pointer: fine) {
			&:hover {
				cursor: pointer;
				opacity: 0.7;
			}
		}
	}
}
