.authenticateButtons {
	display: flex;
	flex-direction: column;
	padding: 8px 16px;

	&__button {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 40px;
		border-radius: 8px;
		background: #7c59ff;
		border-radius: 8px;
		color: #fff;
		font-size: 14px;
		font-weight: 500;
		line-height: 16px;
		transition: 0.3s ease-in-out;

		&___signIn {
			margin-top: 8px;
			background: #2c2c2e;
		}

		@media (hover: hover) and (pointer: fine) {
			&:hover {
				opacity: 0.8;
			}
		}
	}
}
