@import "~@btc-alpha/ui-components/dist/styles/functions";
@import "~@btc-alpha/ui-components/dist/styles/media";
@import "~@btc-alpha/ui-components/dist/styles/mixins";

.root {
	width: 100%;
	max-width: rems(440);

	form {
		display: flex;
		flex-direction: column;
		gap: rems(16);
	}

	.captcha {
		align-self: center;
		max-height: 100px;
		transition: 0.3s ease-in-out;

		&.closed {
			max-height: 0;
			overflow: hidden;
		}
	}

	.agreement {
		max-width: rems(340);

		span,
		a {
			@include font(16, 400, 20, true);
		}
	}

	.submit {
		margin-top: rems(32);
	}
}

.confirmation {
	display: flex;
	flex-direction: column;
	gap: rems(20);

	.content {
		display: flex;
		flex-direction: column;
		gap: rems(20);

		.code > div {
			gap: rems(8);

			& > div {
				flex-grow: 1;
			}
		}

		.fieldIcon {
			width: rems(20);
			height: rems(20);
		}

		.resend button {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: rems(8);
			font-size: rems(14);
			color: var(--alpha-ui-color-accent-purple);
			transition: opacity 0.2s;

			&:disabled {
				pointer-events: none;
				opacity: 0.5;
			}
		}
	}

	.footer {
		display: flex;
		flex-direction: row;
		gap: rems(16);

		button {
			flex-grow: 1;
		}
	}

	.help {
		display: block;
		margin: 0 auto;

		@include hover {
			span {
				text-decoration: underline;
			}
		}

		span {
			color: var(--alpha-ui-color-accent-purple);
		}
	}
}
