@import "assets/styles/common/mixins";

.p2p_modal_container {
	width: 615px;
	max-height: 85%;

	@include max-w(xs) {
		width: 100%;
	}
}

.requirement_item {
	@include flexbox(space-between, center);

	font-weight: 500;
	font-size: 20px;
	line-height: 36px;
	color: var(--color-text-primary);
}

.terms_checkbox_content {
	@include flexbox();

	a {
		text-decoration: underline;
		margin-left: 5px;
	}
}

.payment_confirm_method {
	margin-top: -20px;
	margin-bottom: 20px;
}

.warning_container {
	@include flexbox(stretch, stretch, column);

	background-color: rgba(255, 169, 64, 0.12);
	border-radius: 12px;
	padding: 20px;
	gap: 20px;
	width: 100%;

	span {
		font-weight: 400;
		font-size: 16px;
		line-height: 19px;
		color: var(--color-text-primary);
	}
}

.modal_title {
	@include flexbox();

	font-weight: 500;
	font-size: 20px;
	line-height: 24px;
	color: var(--color-text-primary);

	gap: 15px;

	i {
		font-size: 22px;
		color: var(--color-text-primary);
	}
}

.add_method_modal {
	&.overflow {
		overflow-y: unset;
	}
}

.funding_transfer_modal {
	&.overflow {
		overflow-y: unset;
	}
}

.chosen_payment_method {
	@include flexbox(stretch, center);

	padding: 20px;
	border-radius: 12px;
	background-color: var(--color-fill-primary);
	gap: 11px;

	img {
		height: 24px;
		width: 24px;
	}
}

.appeal_container {
	@include flexbox(stretch, stretch, column);

	margin-top: 20px;
	padding-top: 20px;
	gap: 6px;
}

.notification_container {
	@include flexbox(stretch, stretch, column);

	gap: 10px;
}

.feedback_selector {
	width: 100%;
}

.button_link {
	padding: 10px 12px;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	border-radius: 12px;
	border: 1px solid var(--color-blue);
	color: var(--color-blue);
	cursor: pointer;
}

.modal_description {
}
