@import "src/assets/styles/common/mixins";

.container {
	height: 46px;
	min-height: 46px;
	pointer-events: auto;
	width: max-content;
	border: none;
	border-radius: 8px;
	font-family: "Roboto", sans-serif;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	user-select: none;
	cursor: pointer;
	outline: none;
	padding: 0;
	text-decoration: none;

	&.medium {
		span {
			font-weight: 500;
		}
	}

	&.bold {
		span {
			font-weight: bold;
		}
	}

	&.mini {
		height: 36px;
		min-height: 36px;
		border-radius: 6px;
	}

	&.defaultTerminalBtn {
		height: 40px;
		min-height: 40px;
		border-radius: 8px;
		justify-content: center;
		align-items: center;
		padding: 12px 16px;

		span {
			font-size: 14px;
			line-height: 16px;
		}
	}

	&.loading {
		pointer-events: none;

		>.button_content {
			opacity: 0;
		}
	}

	&.full_width {
		width: 100%;
	}

	&.text {
		color: var(--button-text-primary-color);
		background-color: transparent;

		&:hover {
			background-color: transparent;
		}

		&:active {
			background-color: transparent;
		}

		path {
			stroke: var(--button-text-primary-color);
		}

		i {
			color: var(--button-text-primary-color);
		}

		&.disabled {
			color: var(--button-text-disabled-color) !important;
			background-color: var(--button-text-disabled-background) !important;

			path {
				stroke: var(--button-text-disabled-color) !important;
			}

			circle {
				fill: var(--button-text-disabled-color) !important;
			}

			i {
				color: var(--button-text-disabled-color) !important;
			}
		}

		&.primary {
			color: var(--button-text-primary-color);

			circle {
				fill: var(--button-text-primary-color);
			}
		}

		&.secondary {
			color: var(--button-text-secondary-color);

			circle {
				fill: var(--button-text-secondary-color);
			}
			i {
				color: var(--button-text-secondary-color);
			}
		}

		&.tertiary {
			color: var(--button-text-tertiary-color);

			circle {
				fill: var(--button-text-tertiary-color);
			}
			i {
				color: var(--button-text-tertiary-color);
			}
		}

		&.quaternary {
			color: var(--button-text-quaternary-color);

			circle {
				fill: var(--button-text-quaternary-color);
			}
			i {
				color: var(--button-text-quaternary-color);
			}
		}

		&.quinary {
			color: var(--button-text-quinary-color);

			circle {
				fill: var(--button-text-quinary-color);
			}
			i {
				color: var(--button-text-quinary-color);
			}
		}

		&.senary {
			color: var(--button-text-senary-color);

			circle {
				fill: var(--button-text-senary-color);
			}
			i {
				color: var(--button-text-senary-color);
			}
		}

		&.darkness {
			color: var(--button-text-darkness-color);

			circle {
				fill: var(--button-text-darkness-color);
			}
		}
	}

	&.outlined {
		color: var(--button-text-primary-color);
		border: 1px solid var(--button-text-primary-color);
		background-color: transparent;

		&:hover {
			background-color: transparent;
		}

		&:active {
			background-color: transparent;
		}

		path {
			stroke: var(--button-text-primary-color);
		}

		i {
			color: var(--button-text-primary-color);
		}

		&.disabled {
			color: var(--button-text-disabled-color) !important;
			border-color: var(--button-text-disabled-color) !important;
			background-color: var(--button-text-disabled-background) !important;

			path {
				stroke: var(--button-text-disabled-color) !important;
			}

			circle {
				fill: var(--button-text-disabled-color) !important;
			}

			i {
				color: var(--button-text-disabled-color) !important;
			}
		}

		&.primary {
			color: var(--button-text-primary-color);
			border-color: var(--button-text-primary-color);

			circle {
				fill: var(--button-text-primary-color);
			}
		}

		&.secondary {
			color: var(--button-text-secondary-color);
			border-color: var(--button-text-secondary-color);

			circle {
				fill: var(--button-text-secondary-color);
			}
		}

		&.tertiary {
			color: var(--button-text-tertiary-color);
			border-color: var(--button-text-tertiary-color);

			circle {
				fill: var(--button-text-tertiary-color);
			}
		}

		&.quaternary {
			color: var(--button-text-quaternary-color);
			border-color: var(--button-text-quaternary-color);

			circle {
				fill: var(--button-text-quaternary-color);
			}
		}

		&.quinary {
			color: var(--button-text-quinary-color);
			border-color: var(--button-text-quinary-color);

			circle {
				fill: var(--button-text-quinary-color);
			}
		}

		&.senary {
			color: var(--button-text-senary-color);
			border-color: var(--button-text-senary-color);

			circle {
				fill: var(--button-text-senary-color);
			}
		}

		&.darkness {
			color: var(--button-text-darkness-color);
			border-color: var(--button-text-darkness-color);

			circle {
				fill: var(--button-text-darkness-color);
			}
		}
	}

	&.filled {
		color: var(--button-filled-color);

		path {
			stroke: var(--button-filled-color);
		}

		i {
			color: var(--button-filled-color);
		}

		&.disabled {
			pointer-events: none;
			color: var(--button-filled-disabled-color) !important;
			background-color: var(--button-filled-disabled-background) !important;

			path {
				stroke: var(--button-filled-disabled-color) !important;
			}

			circle {
				fill: var(--button-filled-disabled-background) !important;
			}

			i {
				color: var(--button-filled-disabled-color) !important;
			}
		}

		&.primary {
			background-color: var(--button-filled-primary-background);

			&:hover {
				background-color: var(--button-filled-primary-background-hover);
			}

			&:active {
				background-color: var(--button-filled-primary-background-active);
			}

			circle {
				fill: var(--button-filled-primary-background);
			}
		}

		&.secondary {
			background-color: var(--color-red);

			&:hover {
				background-color: var(--button-filled-secondary-background-hover);
			}

			&:active {
				background-color: var(--button-filled-secondary-background-active);
			}

			circle {
				fill: var(--color-red);
			}
		}

		&.tertiary {
			background-color: var(--color-green);
			&:hover {
				background-color: var(--button-filled-tertiary-background-hover);
			}

			&:active {
				background-color: var(--button-filled-tertiary-background-active);
			}

			circle {
				fill: var(--color-green);
			}
		}

		&.quaternary {
			background-color: var(--button-filled-quaternary-background);

			&:hover {
				background-color: var(--button-filled-quaternary-background-hover);
			}

			&:active {
				background-color: var(--button-filled-quaternary-background-active);
			}

			circle {
				fill: var(--button-filled-quaternary-background);
			}
		}

		&.quinary {
			background-color: var(--button-filled-quinary-background);

			&:hover {
				background-color: var(--button-filled-quinary-background-hover);
			}

			&:active {
				background-color: var(--button-filled-quinary-background-active);
			}

			circle {
				fill: var(--button-filled-quinary-background);
			}
		}

		&.senary {
			background-color: var(--button-filled-senary-background);

			&:hover {
				background-color: var(--button-filled-senary-background-hover);
			}

			&:active {
				background-color: var(--button-filled-senary-background-active);
			}

			circle {
				fill: var(--button-filled-senary-background);
			}
		}

		&.darkness {
			background-color: var(--button-filled-darkness-background);
			color: var(--button-text-darkness-background);

			&:hover {
				background-color: var(--button-filled-darkness-background-hover);
			}

			&:active {
				background-color: var(--button-filled-darkness-background-active);
			}

			circle {
				fill: var(--button-filled-darkness-background);
			}
		}
	}

	&:focus,
	>div:focus {
		outline: none;
	}

	&:focus {
		>div:before {
			display: block;
		}
	}
}

.button_content {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	padding: 4px 22px;
	z-index: 1;
	outline: none;
	position: relative;
	border-radius: 8px;
	color: inherit;
	user-select: none;
	text-decoration: none;

	&.loading {
		opacity: 0;
	}

	&.mini {
		border-radius: 6px;

		>span {
			font-size: 14px;
		}
	}

	>span {
		color: inherit;
		font-weight: 400;
		font-size: 16px;
		text-decoration: none;
	}

	&:before {
		content: "";
		position: absolute;
		width: calc(100% + 8px);
		height: calc(100% + 8px);
		left: -4px;
		top: -4px;
		display: none;
		border-radius: 14px;
		box-sizing: border-box;
		border: 3px solid var(--color-focus);
	}

	&.iconRight {
		> i {
			margin-right: 0;
			margin-left: 10px;
			margin-bottom: 0;
		}
	}

	> i {
		font-size: 16px;
		margin-right: 10px;
		margin-bottom: 2px;
	}
}

.button_loader {
	z-index: 9;
	position: absolute;
}

.buttons_group {
	display: grid;
	grid-template: auto / 1fr;
	justify-content: center;
	align-items: center;
	grid-gap: 10px;
	width: 100%;
	max-width: 340px;
	margin: 20px auto 0;

	&.fullWidth {
		max-width: unset;
	}

	>a {
		width: 100%;
	}
}

.button_flat {
	color: var(--color-secondary);
	background-color: transparent;

	:hover {
		background-color: var(--navmenu-item-hover);
	}

	:active {
		background-color: var(--navmenu-item-hover);
	}

	:focus {
		background-color: var(--navmenu-item-hover);
	}

	:disabled {
		background-color: var(--button-flat-disabled);
	}

	i {
		color: #787878;
	}
}

.button_micro {
	width: 24px;
	height: 24px;
	line-height: 24px;
	background-color: transparent;
	border-radius: 50%;
	min-width: 0;
	color: #9ca9b5;
	padding: 0;
	margin: 0;
	position: relative;
	cursor: pointer;
	border: none;
	display: inline-block;
	outline: none;
	user-select: none;

	&:hover {
		background: unset;
		cursor: pointer;
	}

	&:active,
	&:focus {
		background: unset;
	}

	>i {
		line-height: 1;
		font-size: 18px;
	}

	&.small {
		>i {
			font-size: 14px !important;
		}
	}
}