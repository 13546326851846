.recentTrades {
	display: flex;
	flex: 1;
	flex-direction: column;
	max-height: 100%;
	content-visibility: auto;

	&__listEx {
		display: flex;
		flex-direction: column-reverse;
	}

	&__header {
		display: flex;
		padding: 0 16px 0 16px;
		border-bottom: 1px solid #28282d;
		background-color: var(--main-background-color);

		@media ((max-width: 1925px) and (min-width: 1100px)) or (max-width: 610px) {
			display: none;
		}
	}

	&__headerTabButton {
		color: #fff;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		padding-top: 14px;
		padding-bottom: 8px;
		border-bottom: 2px solid #7c59ff;
	}

	&__container {
		display: flex;
		flex-direction: column;
		flex: 1;
		max-height: 608px;
	}

	&__containerHeader {
		display: flex;
		justify-content: space-between;
		padding: 8px 16px;
		align-items: center;
		background-color: var(--main-background-color);
	}

	&__headerText {
		display: inline-flex;
		color: rgba(235, 235, 245, 0.6);
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		flex: 1;
		text-align: center;

		&:nth-child(2) {
			justify-content: center;
		}

		&:nth-child(3) {
			justify-content: flex-end;
		}
	}

	&__list {
		flex: 1;
		list-style: none;
		padding: 0;
		margin: 0;
	}

	&__item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 6px 16px 5px;

		@media (hover: hover) and (pointer: fine) {
			transition: 0.1s ease-in-out;

			&:hover {
				cursor: pointer;
				background-color: #2c2c2e80;
			}
		}
	}

	&__price {
		flex: 1;
		color: #ef454a;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		text-align: left;

		&___buy {
			color: #20b26c;
		}
	}

	&__amount {
		flex: 1;
		color: #fff;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		text-align: center;
	}

	&__time {
		flex: 1;
		color: #fff;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		text-align: right;
	}
}
