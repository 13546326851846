.team {
	margin-top: 80px;

	@media screen and (max-width: 767px) {
		margin-top: 32px;
	}

	&__listWrapper {
		display: flex;
	}

	&__headline {
		color: #fff;
		text-align: center;
		font-size: 40px;
		font-weight: 600;
		line-height: 132%;
		letter-spacing: -0.45px;
		padding: 0 24px;
		margin: 0;

		@media screen and (max-width: 767px) {
			text-align: left;
			font-size: 32px;
		}
	}

	&__list {
		margin: 60px auto 0 auto;
		list-style: none;
		padding: 0 24px;
		display: flex;
		gap: 32px;
		overflow: auto;

		@media screen and (max-width: 767px) {
			gap: 24px;
			margin: 32px 0 0 0;
		}
	}

	&__item {
		min-width: 260px;
		width: 260px;
		padding: 16px 16px 24px 16px;
		border-radius: 12px;
		background: #202028;

		@media screen and (max-width: 767px) {
			padding: 24px;
		}
	}

	&__image {
		width: 100%;
		border-radius: 16px;

		canvas {
			width: 100% !important;
			height: auto !important;
		}
	}

	&__name {
		color: #fff;
		font-size: 24px;
		font-weight: 600;
		line-height: 132%;
		letter-spacing: -0.3px;
		margin: 12px 0 0 0;
	}

	&__position {
		margin: 8px 0 0 0;
		color: #fff;
		font-size: 18px;
		font-weight: 400;
		line-height: 132%;
		letter-spacing: 0.06px;

		@media screen and (max-width: 767px) {
			font-size: 16px;
		}
	}
}
