@import "~@btc-alpha/ui-components/dist/styles/functions";
@import "~@btc-alpha/ui-components/dist/styles/media";
@import "~@btc-alpha/ui-components/dist/styles/mixins";

.root {
	display: flex;
	flex-direction: column;
	gap: rems(12);

	.header {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: rems(4);
		padding: rems(12) rems(16);
		border-radius: rems(12);
		color: var(--alpha-ui-color-text-primary);
		background-color: var(--alpha-ui-color-background-base-3);

		div {
			border-radius: rems(4);
			padding: rems(1) rems(4);
			background-color: var(--alpha-ui-color-background-base-2);
			@include font(12, 600, 16);
		}

		span {
			@include font(14, 500, 20);
		}
	}

	.list {
		display: flex;
		flex-direction: column;
		gap: rems(4);
		max-height: rems(360);
		margin-right: rems(-14);
		padding-right: rems(3);
		overflow-y: scroll;
		@include scroll(5, false, transparent);
		scrollbar-width: thin;

		.item {
			display: flex;
			flex-direction: column;
			align-items: stretch;
			gap: rems(4);
			padding: rems(12);
			height: auto;
			text-align: left;

			&:not(:hover) {
				background-color: transparent;
			}

			.title {
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: rems(4);
        @include font(14, 500, 20);
			}

			.text {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				color: var(--alpha-ui-color-label-secondary);
				@include font(14, 400, 20);
			}

			.date {
				color: var(--alpha-ui-color-label-tertiary);
				@include font(12, 400, 16);
			}
		}
	}

	.empty {
		padding: rems(20) 0;
		text-align: center;
		color: var(--alpha-ui-color-text-primary);
	}

	.all {
		border-color: var(--alpha-ui-color-separator-opaque);

		&:not(:hover) {
			background-color: transparent;
		}
	}
}

.icon {
  position: relative;
  display: flex;

  img {
    width: var(--size);
    height: var(--size);
  }

  span {
    position: absolute;
    top: rems(-4);
    right: rems(-4);
    padding: rems(1) rems(4);
    font-size: rems(8);
    font-weight: bold;
    border-radius: rems(20);
    background-color: var(--alpha-ui-color-accent-purple);
  }
}
