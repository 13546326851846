@import "assets/styles/common/mixins";

.container {
	@include flexbox(stretch, stretch, column);
}

.ad_overlay {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.4);
	z-index: 100;
}

.filters {
	padding: 0 20px;

	&__top {
		display: flex;
		margin-top: 26px;
		padding-bottom: 20px;
		border-bottom: 1px solid #28282d;
	}

	&__bottom {
		display: flex;
		margin-top: 32px;
	}

	.side_selector {
		width: 200px;
		flex-shrink: 0;
		margin-right: 16px;
	}

	.amount_currency {
		background: #2c2c2e;
		border-radius: 0 8px 8px 0;
		border-left: 1px solid #101014;
	}

	.payment__method {
		display: flex;
		position: relative;
	}

	.payment__methodSelect {
		margin-right: 16px;
	}

	.payment__countrySelect {
		margin-right: 16px;
		width: 100%;
		max-width: 240px;
	}

	.payment__merchantToggle {
		display: flex;
		align-items: center;
		margin-left: auto;
	}

	.input_label {
		font-weight: normal;
		z-index: 2;
		color: var(--color-secondary);
		transition: 0.12s ease;
		position: absolute;
		top: -19px;
		left: 0;
		font-size: 12px;
		line-height: 14px;
		padding: 0 4px;
		width: max-content !important;
		z-index: 2;
		margin-bottom: 2px;
	}

	.currency_dropdown_indicator {
		position: absolute;
		right: 0;
		padding: 10px;
		width: 46px;
		height: 46px;
		@include flexbox(center, center);
		pointer-events: none;

		i {
			font-size: 12px;
			color: var(--icon-primary);
		}

		&.mini {
			width: 32px;
			height: 32px;

			i {
				font-size: 9px;
			}
		}
	}
	.input_country {
		width: 100%;
		max-width: 240px;
	}

	.input_block {
		width: 100%;
		max-width: 200px;
		min-width: 175px;

		&.currency {
			max-width: 140px;
			min-width: 140px;
		}

		&.currencyInput {
			max-width: 110px;
			min-width: 110px;
		}

		&.amount_input {
			max-width: 268px;
			margin-right: 16px;
			position: relative;
			z-index: 3;

			input {
				border-radius: 8px 0 0 8px;

				&::placeholder {
					opacity: 1 !important;
				}
			}
		}

		@include max-w(xxl) {
			max-width: 160px;
			min-width: 160px;
		}
	}

	.item_label {
		font-size: 16px;
		color: var(--color-text-primary);
	}

	.dropdown_container {
		@include max-w(xxl) {
			margin-left: auto;
		}
	}

	.filter {
		@include flexbox(stretch, center);

		font-size: 14px;
		line-height: 19px;
		color: var(--color-text-primary);
		gap: 6px;
		border: none;
		padding: 0;
		margin: 0;

		svg {
			width: 24px;
			height: 24px;
			stroke: var(--color-fill-primary);
			margin-left: 0;
		}

		i {
			color: var(--color-grey-secondary);
			font-size: 8px;
			margin-left: 5px;
		}

		@include max-w(xxl) {
			font-weight: 500;

			i {
				display: none;
			}
		}
	}

	.filter_content {
		@include card();
		@include flexbox(stretch, stretch, column);

		overflow: unset;
		padding: 20px;
		border-radius: 12px;
		width: max-content;
		max-width: 240px;
		transform: translate(-100px, 10px);
		gap: 20px;
		box-shadow: var(--navmenu-box-shadow);

		> div {
			width: 100%;
			max-width: 100% !important;
		}

		@include max-w(xxl) {
			width: 260px;
			max-width: 260px;
			transform: translateX(-155px);
		}

		@include max-w(xs) {
			transform: translateX(-205px);
		}
	}

	.refresh {
		margin-left: auto;
		height: 40px;
		min-height: 40px;

		span {
			font-size: 14px;
		}

		@include max-w(xxl) {
			display: none;
		}
	}
}

.orders_list {
	@include flexbox(stretch, stretch, column);

	margin-top: 24px;
	padding: 0 16px;

	&__header {
		font-size: 32px;
		line-height: 37px;
		color: var(--color-text-primary);
		font-weight: 500;
		text-align: center;
	}
}

.order {
	@include flexbox(stretch, stretch, row);
	@include card();

	min-height: 130px;
	border: 1px solid #28282d;
	border-radius: 8px;
	margin-top: 16px;

	&__userInfo {
		@include flexbox(flex-start, center, row);

		width: 290px;
		padding: 16px;
		border-right: 1px solid #28282d;
	}

	&__userEmptyPhoto {
		@include flexbox(center, center, row);

		width: 36px;
		height: 36px;
		border-radius: 50%;
		background: #2c2c2e;
		margin-right: 8px;
		font-weight: bold;
		color: #fff;
		font-size: 16px;
		text-align: center;
	}

	&.isActive {
		z-index: 200;

		.middle {
			grid-template-areas:
				"available limit payment-method payment-method"
				"payment-time terms terms terms"
				"trade-form trade-form trade-form trade-form";

			@include max-w(md) {
				grid-template-areas: "available" "limit" "payment-method" "payment-time" "terms";
			}
		}
	}
}

.order__action {
	@include flexbox(stretch, center);

	padding: 16px;
	margin-left: auto;
}

.middle {
	display: grid;
	grid-template-areas: "available limit payment-method payment-method";
	grid-gap: 20px 10px;

	padding: 10px 20px;
	border-bottom: 1px solid var(--card-border-color);

	@include max-w(md) {
		padding: 10px;
		grid-template-areas: "available" "limit" "payment-method";
		grid-gap: 8px;

		.info_block {
			display: grid;
			align-content: center;
			grid-template-columns: 2fr 3fr;
			width: 100% !important;

			> span {
				margin-bottom: 0;
			}
		}
	}
}

.bottom {
	@include flexbox(stretch, center);

	padding: 10px 20px;

	@include max-w(md) {
		padding: 10px;
	}
}

.buy_button {
	margin-left: auto;
	height: 40px;
	min-height: 40px;

	span {
		font-size: 14px;
		font-weight: 500;
	}
}

.info_block {
	@include flexbox(stretch, stretch, column);

	overflow: hidden;

	&.available {
		grid-area: available;
		width: 185px;
	}

	&.limit {
		grid-area: limit;
		width: 315px;
	}

	&.payment_method {
		grid-area: payment-method;
		max-width: 750px;
	}

	&.payment_time {
		grid-area: payment-time;
	}

	&.terms {
		grid-area: terms;
	}
}

.trade_form {
	@include flexbox(center, flex-start);

	grid-area: trade-form;
	gap: 20px;
	padding: 20px 20px 10px;
	border-top: 1px solid var(--divider-border);
	width: calc(100% + 40px);
	margin-left: -20px;

	div {
		flex: 1;
	}

	.payment_method_btn {
		flex: 1;
	}

	.confirm_btn {
		width: 315px;
	}

	@include max-w(md) {
		padding: 20px 10px 10px;
		flex-direction: column;
		width: 100%;
		margin-left: 0;
		gap: 10px;

		.confirm_btn {
			width: 100%;
		}
	}
}

.order__finance {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 16px;
	flex: 1;
	border-right: 1px solid #28282d;
}

.order__financeBlock {
	margin-bottom: 8px;

	&:last-child {
		margin-bottom: 0;
	}
}

.order__paymentMethod {
	display: flex;
	flex-direction: column;
	justify-content: center;
	border-right: 1px solid #28282d;
	padding: 16px;
	min-width: 240px;
}

.order__paymentMethodLabel {
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	color: var(--color-text-tertiary);
	margin-right: 4px;
}

.order__paymentMethodValue {
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: var(--color-text-primary);
}

.order__financeLabel {
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	color: var(--color-text-tertiary);
	margin-right: 4px;
}

.order__financeCurrency {
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	color: var(--color-text-tertiary);
}

.order__financeValue {
	font-weight: 500;
	font-size: 18px;
	line-height: 24px;
	color: var(--color-text-primary);
}

.price__block {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 16px;
	width: 200px;
	border-right: 1px solid #28282d;
}

.price {
	font-weight: 600;
	font-size: 24px;
	line-height: 28px;
	color: var(--color-text-primary);

	&__currency {
		font-weight: 500;
		font-size: 14px;
		line-height: 24px;
		color: var(--color-text-tertiary);
	}
}

.value {
	font-size: 14px;
	line-height: 22px;
	color: var(--color-text-primary);
	overflow: hidden;
	text-overflow: ellipsis;
}

.merchant {
	@include flexbox(stretch, center);

	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
	color: var(--color-text-primary);
	gap: 6px;

	a {
		color: var(--color-text-primary);
		max-width: 350px;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	img {
		height: 24px;
		width: 24px;
	}

	.icon {
		margin-right: 6px;
		color: var(--color-blue);
		font-size: 14px;
	}
}

.orders_stats {
	@include flexbox();

	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	color: var(--color-text-tertiary);

	> span {
		&:first-child {
			margin-right: 6px;
		}
	}
}

.close_btn {
	@include flexbox(center, center);

	width: 42px;
	height: 42px;
	background-color: var(--color-fill-secondary);
	border-radius: 12px;
	cursor: pointer;
	margin-left: auto;

	i {
		color: var(--color-text-primary);
		font-size: 24px;
	}
}
