.depositTransfer {
	display: flex;
	flex-direction: row;
	padding: 12px 16px;
	width: 100%;

	&__button {
		color: #fff;
		font-size: 14px;
		font-weight: 500;
		line-height: 16px;
		border-radius: 8px;
		background-color: #2c2c2e;
		display: flex;
		flex: 1;
		height: 36px;
		padding: 10px 16px;
		justify-content: center;
		align-items: center;
		margin-right: 8px;

		&:last-child {
			margin-right: 0;
		}

		@media (hover: hover) and (pointer: fine) {
			transition: 0.3s ease-in-out;

			&:hover {
				opacity: 0.7;
			}
		}
	}
}
