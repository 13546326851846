.buySellToggle {
	padding: 12px 16px;

	&__toggleRadio {
		&:nth-child(1):checked + input + div {
			label:nth-child(1) {
				color: #fff;

				&:hover {
					color: #fff;
				}
			}

			.buySellToggle__background {
				left: 2px;
				background-color: #20b26c;
			}
		}

		&:nth-child(2):checked + div {
			label:nth-child(2) {
				color: #fff;

				&:hover {
					color: #fff;
				}
			}

			.buySellToggle__background {
				left: 50%;
				background-color: #ef454a;
			}
		}
	}

	&__wrapper {
		position: relative;
		height: 40px;
		display: flex;
		align-items: center;
		padding: 2px;
		border-radius: 8px;
		background: #2c2c2e;
	}

	&__background {
		position: absolute;
		z-index: 1;
		border-radius: 5px;
		top: 2px;
		bottom: 2px;
		width: calc(50% - 2px);
		transition: 0.3s ease-in-out;
	}

	&__button {
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		z-index: 2;
		width: 50%;
		height: 100%;
		color: rgba(235, 235, 245, 0.6);
		font-size: 14px;
		font-weight: 500;
		line-height: 16px;
		cursor: pointer;

		@media (hover: hover) and (pointer: fine) {
			transition: 0.3s ease-in-out;

			&:hover {
				color: #fff;
			}
		}
	}
}
