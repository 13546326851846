@import "assets/styles/common/mixins";
@import "styles/mixins";

.container {
	@include main-container;

	width: 100%;
	margin: 2px auto 0;
	padding-top: 32px;
	padding-bottom: 32px;
	background-color: #101014;

	h1 {
		margin: 0 0 20px;
		font-size: 36px;
	}
}

.list {
	display: grid;
	grid-template: auto / repeat(auto-fill, minmax(320px, 1fr));
	grid-gap: 12px;
	align-items: stretch;
	justify-items: center;
}

.list_item {
	@include flexbox(flex-start, flex-start, column);
	box-shadow: none;
	border-radius: 8px;
	background-color: #3a3a3c30;
	padding: 0;
	margin: 0;
	min-height: 350px;
	position: relative;
	padding-bottom: 50px;
	width: 100%;
	padding: 0 0 16px;
	border: 1px solid var(--divider-border);

	> a {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 5;
	}

	object,
	picture,
	img {
		width: 100%;
		overflow: hidden;
		height: 175px;
		border-radius: 8px 8px 0 0;
		object-fit: cover;
	}

	@include max-w(xs) {
		min-height: unset;
	}

	&:hover {
		border-color: #7c59ff;
		h2 {
			color: #7c59ff;
		}
	}
}

.list_item_picture_link {
	width: 100%;
	height: 175px;
}

.list_item_date {
	padding: 8px 0;
	margin-left: 8px;
	font-size: 12px;
	float: right;
	color: #787878;
}

.list_item_share {
	padding: 6px 6px 0;
	z-index: 6;

	a:hover > i {
		color: var(--news-color-primary);
	}
}

.network_icon {
	margin: 8px;
	font-size: 16px;
}

.list_item_view_counter {
	@include flexbox(center, center);
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 8px 15px;
	font-size: 12px;
	float: right;
	color: #787878;

	i {
		font-size: 16px;
		margin-right: 2px;
	}
}

.list_item_content {
	padding: 4px 10px;

	h2 {
		margin: 0 0 14px;
		font-weight: bold;
		color: var(--color-primary);
	}
}

.list_item_text {
	max-height: 80px;
	max-lines: 2;
	-webkit-line-clamp: 2;
	font-size: 15px;
	color: var(--color-secondary);
	//display: box;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
	white-space: normal;
	overflow: hidden;

	p {
		margin: 0;
	}
}

.list_item_learn_more {
	bottom: 16px;
	right: 16px;
	text-align: right;
	padding: 4px 14px;
	margin-left: auto;
	margin-top: auto;
	z-index: 6;

	i {
		font-size: 14px;
		line-height: 14px;
		transition: transform 0.2s ease;
	}

	a {
		font-weight: 500;
		font-size: 14px;
	}

	&:hover {
		i {
			transform: translateX(8px);
		}
	}
}
