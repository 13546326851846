@import "src/assets/styles/common/mixins";

.container {
	@include card(false);
	@include flexbox(flex-start, flex-start, column);
	padding: 20px 32px;
	width: 100%;
	flex: 1;
}

.meta {
	@include flexbox(space-between, center);
	width: 100%;
	margin-bottom: 26px;

	> i {
		font-size: 28px;
		color: var(--icon-primary);
	}

	> span {
		font-size: 12px;
		line-height: 14px;
		text-align: right;
		color: var(--color-secondary);
	}
}

.title {
	font-weight: bold;
	font-size: 24px;
	line-height: 28px;
	color: var(--color-primary);
	margin-bottom: 16px;
	width: 100%;
}

.body {
	font-weight: normal;
	font-size: 16px;
	line-height: 23px;
	color: var(--color-primary);
	width: 100%;
}
