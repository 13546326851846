@import "~@btc-alpha/ui-components/dist/styles/functions";
@import "~@btc-alpha/ui-components/dist/styles/media";

.currency > div:first-child {
	--currency-icon-size: #{rems(21)};
	--currency-gap: #{rems(4)};

	gap: var(--currency-gap);

	img {
		width: var(--currency-icon-size) !important;
		height: var(--currency-icon-size) !important;
	}

	&:not(:has(img)) {
		padding-left: calc(var(--currency-icon-size) + var(--currency-gap));
	}
}

.receiver > div {
	gap: rems(8) !important;
}

.outgoing {
	color: var(--alpha-ui-color-accent-red);
}

.income {
	color: var(--alpha-ui-color-accent-green);
}

.success {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;

	img {
		width: rems(170);
		height: rems(170);
		object-fit: contain;
		margin-bottom: rems(20);
	}

	strong {
		font-size: rems(24);
		font-weight: 500;
		line-height: rems(32);
	}

	span {
		font-size: rems(14);
		font-weight: 400;
		line-height: rems(20);
		color: var(--alpha-ui-color-label-secondary);
	}
}

.confirmation {
	display: flex;
	flex-direction: column;
	gap: rems(20);

	.content {
		display: flex;
		flex-direction: column;
		gap: rems(20);
	}

	.footer {
		display: flex;
		flex-direction: row;
		gap: rems(16);

		button {
			flex-grow: 1;
		}
	}
}
