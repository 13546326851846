.orders {
	display: flex;
	flex: 1;
	flex-direction: column;
	overflow: hidden;

	&__precisionSkeleton {
		display: flex;
		margin-left: auto;
		min-width: 86px;
		height: 32px;

		span {
			display: flex;
			flex: 1;
		}
	}

	&__header {
		display: flex;
		padding: 0 16px 0 16px;
		border-bottom: 1px solid #28282d;
		background-color: var(--main-background-color);
		position: relative;
		z-index: 3;

		@media ((max-width: 1925px) and (min-width: 1100px)) or (max-width: 610px) {
			display: none;
		}
	}

	&__headerTabButton {
		color: #fff;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		padding-top: 14px;
		padding-bottom: 8px;
		border-bottom: 2px solid #7c59ff;
	}

	&__filterRadio {
		&:nth-child(1):checked + input + input + div {
			& label:nth-child(1) {
				opacity: 1;
			}

			// & + div .orders__lists {
			// 	transform: translateY(0);
			// }

			// & + div .orders__greenList {
			// 	opacity: 1;
			// }
		}

		&:nth-child(2):checked + input + div {
			& label:nth-child(2) {
				opacity: 1;
			}

			& + div .orders__listWrapper___red {
				height: 100%;
				overflow-y: auto;
			}

			& + div .orders__listWrapper:not(.orders__listWrapper___red) {
				height: 0;
				margin: 0;
			}
		}

		&:nth-child(3):checked + div {
			& label:nth-child(3) {
				opacity: 1;
			}

			& + div .orders__containerHeader:not(.orders__containerHeader___list) {
				opacity: 0;
				height: 0;
				min-height: 0;
				padding: 0 16px;
			}

			& + div .orders__containerHeader___list {
				opacity: 1;
				height: 30px;
				min-height: 30px;
				padding: 8px 16px;
			}

			& + div .orders__lists {
				height: 100%;
			}

			& + div .orders__listWrapper___red {
				height: 0;
				margin: 0;
			}

			& + div .orders__currentPrice {
				padding: 16px;
			}

			& + div .orders__listWrapper:not(.orders__listWrapper___red) {
				height: calc(100% - 32px);
				overflow-y: auto;
			}

			// & + div .orders__greenList {
			// 	opacity: 0;
			// }
		}
	}

	&__content {
		display: flex;
		flex: 1;
		flex-direction: column;
	}

	&__lists {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		overflow: hidden;
		position: relative;
		z-index: 1;
		transition: 0.3s ease-in-out;
		background-color: var(--main-background-color);
		height: calc(100% - 30px);
	}

	&__filters {
		display: flex;
		flex-direction: row;
		padding: 8px 16px;
		height: 48px;
		align-items: center;
		position: relative;
		z-index: 3;
		background-color: var(--main-background-color);
	}

	&__filterLabel {
		display: flex;
		width: 16px;
		height: 16px;
		opacity: 0.6;
		cursor: pointer;
		margin-right: 8px;
		transition: 0.3s ease-in-out;
	}

	&__container {
		position: relative;
		z-index: 2;
		flex: 1;
	}

	&__containerHeader {
		display: flex;
		padding: 8px 16px;
		position: relative;
		z-index: 2;
		height: 30px;
		min-height: 30px;
		background-color: var(--main-background-color);
		overflow: hidden;
		transition: 0.3s linear;

		&___list {
			opacity: 0;
			height: 0;
			min-height: 0;
			padding: 0 16px;
		}
	}

	&__headerText {
		display: flex;
		flex: 1;
		color: rgba(235, 235, 245, 0.6);
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;

		&:nth-child(2) {
			justify-content: center;
		}

		&:nth-child(3) {
			justify-content: flex-end;
		}
	}

	&__reverseList {
		background-color: var(--main-background-color);
		margin: 0;
		padding: 0;
		list-style: none;
		position: relative;
		z-index: 1;
	}

	&__skiletonWrapper {
		height: 27px;
	}

	&__listWrapper {
		height: 215px;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		transition: 0.3s linear;
		content-visibility: auto;

		&___loader {
			align-items: center;
			justify-content: center;
		}

		&___red {
			margin-bottom: 2px;
			flex-direction: column-reverse;
		}
	}

	&__list {
		z-index: 2;
		margin: 0;
		padding: 0;
		list-style: none;
		background-color: var(--main-background-color);
		margin-top: 2px;
		transition: 0.3s linear;
		display: flex;
		flex-direction: column-reverse;
		content-visibility: auto;

		&___red {
			flex-direction: column;

			& .orders__listButton {
				color: #ef454a;
			}

			& .orders__listItem {
				margin-top: 3px;
				margin-bottom: 0;

				&:first-child {
					margin-top: auto;
				}

				& .orders__itemIndicator {
					background-color: rgba(255, 69, 58, 0.16);
				}

				& .orders__itemUserIndicator {
					background-color: rgba(255, 69, 58, 1);
				}

				&::after {
					top: 0px;
					background-color: rgba(255, 69, 58, 0.7);
				}

				@media (hover: hover) and (pointer: fine) {
					transition: 0.3s ease-in-out;
					position: relative;

					&:hover,
					&:hover ~ li {
						background-color: rgba(255, 69, 58, 0.16);

						&::before {
							background-color: rgba(255, 69, 58, 0.5);
						}
					}
				}
			}
		}
	}

	&__listButton {
		display: flex;
		flex: 1;
		justify-content: space-between;
		align-items: center;
		padding: 0 16px;
		cursor: pointer;
		text-align: unset;
		max-width: 100%;
		color: #20b26c;
	}

	&__listItem {
		display: flex;
		margin-bottom: 3px;
		position: relative;
		background: var(--main-background-color);
		cursor: pointer;
		padding-top: 2px;
		min-height: 24px;
		height: 24px;
		max-height: 24px;

		&:first-child {
			margin-bottom: 0;
		}

		& .orders__itemIndicator {
			display: flex;
			min-height: 24px;
			height: 24px;
			max-height: 24px;
			background-color: rgba(50, 215, 75, 0.16);
			position: absolute;
			right: 0;
			top: 0;
			z-index: 1;
			transition: 0.3s ease;
			width: 3px;
			transform-origin: right center;
		}

		& .orders__itemUserIndicator {
			display: flex;
			height: 8px;
			border-radius: 100%;
			width: 8px;
			background-color: rgba(50, 215, 75, 1);
			position: absolute;
			left: 4px;
			top: 8px;
			z-index: 2;
			transition: 0.3s ease;
			opacity: 0;

			&___active {
				opacity: 1;
			}
		}

		&::after {
			content: "";
			height: 1px;
			width: 100%;
			position: absolute;
			right: 0;
			left: 0;
			z-index: 4;
			transition: 0.3s ease-in-out;
			background-color: rgba(50, 215, 75, 0.7);
			bottom: 0px;
			opacity: 0;
		}

		@media (hover: hover) and (pointer: fine) {
			transition: 0.3s ease-in-out;

			&:hover,
			&:hover ~ li {
				background-color: rgba(50, 215, 75, 0.16);

				&::before {
					background-color: rgba(50, 215, 75, 0.5);
				}
			}

			&:hover {
				&::after {
					opacity: 1;
				}
			}
		}
	}

	&__lists {
		position: absolute;
		bottom: 0;
		width: 100%;
	}

	&__itemPrice {
		position: relative;
		width: 33.33333333333%;
		z-index: 1;
		text-align: left;
		margin: 0;
		padding: 0;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 16px;
	}

	&__itemQty {
		position: relative;
		width: 33.33333333333%;
		z-index: 1;
		color: #fff;
		text-align: center;
		margin: 0;
		padding: 0;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 16px;
	}

	&__itemTotal {
		position: relative;
		width: 33.33333333333%;
		z-index: 1;
		color: #fff;
		text-align: right;
		margin: 0;
		padding: 0;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 16px;
	}

	&__currentPrice {
		padding: 20px 16px;
		display: flex;
		align-items: center;
		transition: 0.3s linear;
	}

	&__itemTooltip {
		display: flex;
		flex-direction: column;
		position: fixed;
		background-color: rgb(54, 24, 26);
		padding: 12px;
		border-radius: 8px;
		transform: translateY(-50%);
		width: 240px;

		&::after {
			content: "";
			position: absolute;
			left: -11px;
			top: 50%;
			border: 6px solid transparent;
			transform: translateY(-50%);
			border-right: 6px solid rgb(54, 24, 26);
		}

		&___green {
			background-color: rgb(21, 48, 29);

			&::after {
				border-right: 6px solid rgb(21, 48, 29);
			}
		}
	}

	&__itemTooltipContent {
		position: relative;
		z-index: 1;
	}

	&__itemTooltipText {
		margin: 0;
		margin-top: 8px;
		color: #fff;
		font-size: 14px;
		font-weight: 500;
		line-height: 18px;

		&:first-child {
			margin-top: 0;
		}
	}

	&__itemTooltipTextRight {
		display: inline-flex;
		margin-left: 5px;
	}

	&__currentPriceIcon {
		transition: 0.1s ease;
		fill: #20b26c;
		d: path(
			"M10.59 2.744a.833.833 0 00-1.179 0l-5 5A.833.833 0 105.59 8.923l3.577-3.578v11.322a.833.833 0 001.667 0V5.345l3.577 3.578a.833.833 0 101.179-1.179l-5-5z"
		);
		opacity: 0;

		&___green {
			opacity: 1;
		}

		&___red {
			fill: #ef454a;
			d: path(
				"M10.834 3.333a.833.833 0 00-1.667 0v11.322L5.59 11.077a.833.833 0 00-1.179 1.179l5 5a.833.833 0 001.179 0l5-5a.833.833 0 00-1.179-1.179l-3.577 3.578V3.333z"
			);
			opacity: 1;
		}
	}

	&__currentPriceText {
		display: flex;
		color: rgba(235, 235, 245, 1);
		font-size: 18px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px;
		padding-right: 8px;

		span {
			display: flex;
		}

		&___green {
			color: #20b26c;
		}

		&___red {
			color: #ef454a;
		}
	}

	&__currentPriceText2 {
		display: flex;
		color: rgba(235, 235, 245, 0.6);
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px;

		span {
			display: flex;
		}
	}
}
