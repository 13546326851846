@import "~@btc-alpha/ui-components/dist/styles/functions";
@import "~@btc-alpha/ui-components/dist/styles/media";

.currency > div:first-child {
	--currency-icon-size: #{rems(21)};
	--currency-gap: #{rems(4)};

	gap: var(--currency-gap);

	img {
		width: var(--currency-icon-size) !important;
		height: var(--currency-icon-size) !important;
	}

	&:not(:has(img)) {
		padding-left: calc(var(--currency-icon-size) + var(--currency-gap));
	}
}
