.listHeadline {
	display: flex;
	width: 100%;
	max-width: 100%;
	padding: 0 16px;
	align-items: center;

	&__listWrapper {
		display: flex;
		align-items: center;
		list-style: none;
		margin: 0;

		&:first-child .listHeadline__list {
			padding: 8px 0 8px 0;
		}

		&___scroll {
			overflow: hidden;
			flex: 1;

			-ms-overflow-style: none;

			&::-webkit-scrollbar {
				width: 0;
				height: 0;
			}
		}
	}

	&__list {
		display: flex;
		list-style: none;
		padding: 8px 0 8px 8px;
		margin: 0;
		background-color: var(--main-background-color);
	}

	&__item {
		color: rgba(235, 235, 245, 0.6);
		font-size: 12px;
		font-weight: 400;
		line-height: 14px;
		margin-right: 8px;
		white-space: nowrap;

		&:last-child {
			margin-right: 0;
		}
	}
}
