.favorites {
	display: flex;
	flex-direction: row;
	align-items: center;
	flex: 1;
	width: 100%;
	max-width: 100%;
	padding: 0 0 0 16px;

	&__listContainer {
		display: flex;
		margin-left: 8px;
		overflow: hidden;
		position: relative;
		padding: 4px 0;
	}

	&__iconContainer {
		width: 16px;
		height: 16px;
	}

	&__listButtonLeft {
		position: absolute;
		width: 40px;
		left: 0;
		top: 0;
		padding-top: 3px;
		height: 100%;
		background: linear-gradient(
			90deg,
			var(--main-background-color),
			var(--main-background-color),
			transparent
		);
		color: #fff;
		opacity: 0;
		pointer-events: none;
		transition: 0.3s ease-in-out;
		padding-right: 16px;

		svg {
			transform: rotate(90deg);
			height: 20px;
			width: 20px;
		}

		&___show {
			opacity: 1;
			pointer-events: auto;
		}
	}

	&__listButtonRight {
		position: absolute;
		width: 40px;
		right: 0;
		top: 0;
		height: 100%;
		padding-top: 3px;
		background: linear-gradient(
			90deg,
			transparent,
			var(--main-background-color),
			var(--main-background-color)
		);
		color: #fff;
		opacity: 0;
		pointer-events: none;
		transition: 0.3s ease-in-out;
		padding-left: 16px;

		svg {
			transform: rotate(-90deg);
			height: 20px;
			width: 20px;
		}

		&___show {
			opacity: 1;
			pointer-events: auto;
		}
	}

	&__skeletonWrapper {
		display: flex;
		align-items: center;
		height: 22px;
		grid-column-gap: 8px;

		span {
			height: 22px;
			display: flex;
		}
	}

	&__listWrapper {
		flex: 1;
		overflow: auto;
		padding: 0 16px 0 8px;

		-ms-overflow-style: none;

		&::-webkit-scrollbar {
			width: 0;
			height: 0;
		}
	}

	&__list {
		display: flex;
		flex-direction: row;
		list-style: none;
		margin: 0;
		padding: 0;
		align-items: center;
	}

	&__item {
		padding-right: 12px;
		white-space: nowrap;

		&:last-child {
			padding-right: 20px;
		}
	}

	&__name {
		padding-right: 4px;
		color: #fff;
		font-family: "Roboto", sans-serif;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		text-transform: uppercase;
	}

	&__count {
		display: inline-flex;
		min-width: 40px;
		color: rgba(235, 235, 245, 0.6);
		font-family: "Roboto", sans-serif;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;

		&___red {
			color: #ef454a;
		}

		&___green {
			color: #20b26c;
		}
	}

	&__listEmpty {
		display: flex;
		padding-left: 8px;
		margin: 0;
		color: #fff;
		font-size: 14px;
		font-weight: 400;
		line-height: 16px;
		white-space: pre;

		button {
			color: #7c59ff;
			font-family: "Roboto", sans-serif;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			padding-left: 3px;
			transition: 0.3s ease-in-out;

			&:hover {
				opacity: 0.7;
			}
		}

		span {
			padding-left: 3px;
		}
	}

	&__dotsWrapper {
		margin-left: auto;
		position: relative;

		@media (hover: hover) and (pointer: fine) {
			&:hover {
				.favorites__dotsButton svg path {
					fill-opacity: 1;
				}

				.favorites__dotsList {
					pointer-events: unset;
					opacity: 1;
				}
			}
		}
	}

	&__dotsButton {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0 16px 0 8px;
		height: 30px;
	}

	&__dotsButton svg path {
		transition: 0.3s ease;
	}

	&__dotsList {
		display: flex;
		flex-direction: column;
		padding: 8px;
		margin: 0;
		list-style: none;
		position: absolute;
		right: 10px;
		top: 30px;
		background-color: #242424;
		z-index: 10;
		border-radius: 8px;
		width: 140px;
		transition: 0.3s ease-in-out;
		opacity: 0;
		pointer-events: none;

		&___open {
			pointer-events: unset;
			opacity: 1;
		}

		&::after {
			content: "";
			position: absolute;
			right: 9px;
			top: -5px;
			border: 6px solid transparent;
			transform: translateY(-50%);
			border-bottom: 6px solid #242424;
		}
	}

	&__dotsItem {
		display: flex;
		width: 100%;
		margin-top: 6px;

		&___active {
			.favorites__dotsItemButton {
				background-color: #7c59ff;
			}
		}

		&:first-child {
			margin-top: 0;
		}
	}

	&__dotsItemButton {
		flex: 1;
		display: flex;
		justify-content: center;
		padding: 8px;
		border-radius: 8px;
		color: #fff;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 16px;
		transition: 0.3s ease-in-out;
	}
}
