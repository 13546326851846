.actionButton {
	display: flex;
	padding: 8px 16px;

	&__button {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 40px;
		border-radius: 8px;
		background: #20b26c;
		border-radius: 8px;
		color: #fff;
		font-size: 14px;
		font-weight: 500;
		line-height: 16px;
		transition: 0.3s ease-in-out;
		position: relative;

		span {
			transition: 0.3s ease-in-out;
		}

		.actionButton__loading {
			transition: 0.3s ease-in-out;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			opacity: 0;
		}

		&:disabled {
			background: #2c2c2e;

			.actionButton__loading {
				opacity: 1;
			}

			span {
				opacity: 0;
			}
		}

		&___sell {
			background-color: #ef454a;
		}

		@media (hover: hover) and (pointer: fine) {
			&:hover {
				opacity: 0.8;
			}
		}
	}
}
