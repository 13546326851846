.footer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 12px 16px;
	background-color: var(--main-background-color);
	border-top: 1px solid rgba(84, 84, 88, 0.36);

	@media (max-width: 767px) {
		flex-direction: column;
	}

	&__list {
		display: flex;
		align-items: center;
		padding: 0;
		margin: 0;
		list-style: none;

		@media (max-width: 767px) {
			flex-direction: column;
		}
	}

	&__item {
		margin-right: 16px;
	}

	&__link {
		color: rgba(235, 235, 245, 0.6);
		font-size: 14px;
		font-weight: 500;
		line-height: 16px;

		@media (hover: hover) and (pointer: fine) {
			transition: 0.3s ease-in-out;

			&:hover {
				opacity: 0.7;
			}
		}
	}

	&__copy {
		color: rgba(235, 235, 245, 0.6);
		font-size: 14px;
		font-weight: 500;
		line-height: 16px;
		margin: 0;

		@media (max-width: 767px) {
			margin-top: 16px;
		}
	}
}
