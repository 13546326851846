.orderPriceQty {
	display: flex;
	flex-direction: column;
	padding: 12px 16px;

	&__qty {
		margin-top: 12px;
	}

	&__labelText {
		display: flex;
		color: rgba(235, 235, 245, 0.6);
		font-size: 12px;
		font-weight: 400;
		line-height: 14px;
		margin-bottom: 6px;
	}

	&__buttons {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
		grid-gap: 8px;
		list-style: none;
		margin: 8px 0 0 0;
		width: 100%;
		padding: 0;
	}

	&__buttonItem {
		display: flex;
		flex: 1;
	}

	&__button {
		flex: 1;
		border-radius: 8px;
		background: #2c2c2e;
		display: flex;
		height: 28px;
		justify-content: center;
		align-items: center;
		color: #fff;
		font-size: 10px;
		font-weight: 600;
		transition: 0.3s ease-in-out;
		line-height: 16px;

		span {
			padding-right: 2px;
		}

		&:hover {
			background: #7c59ff;
		}
	}
}
