@import "~@btc-alpha/ui-components/dist/styles/functions";
@import "~@btc-alpha/ui-components/dist/styles/media";

:root {
  --vertical-page-padding: #{rems(24)};
  --horizontal-page-padding: #{rems(40)};

  --page-padding: var(--vertical-page-padding) var(--horizontal-page-padding);

  --redesigned-header-height: #{rems(52)};

  --header-height: 47px;

  @media screen and (max-width: 1024px) {
    --header-height: 65px;
  }
}
