.functionalPanel {
	display: flex;
	flex-direction: column;
	width: 100%;

	@media (min-height: 760px) {
		position: sticky;
		top: 0;
	}

	&__modalTrigger {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: black;
	}

	&__header {
		display: flex;
		padding: 0 16px;
		width: 100%;
		border-bottom: 1px solid rgba(84, 84, 88, 0.36);
		height: 41px;
	}

	&__headerList {
		display: flex;
		list-style: none;
		padding: 0;
		margin: 0;
	}

	&__headerItem {
		padding: 0;
		margin: 0;
		margin-right: 16px;

		&:last-child {
			margin-right: 0;
		}
	}

	&__tabButton {
		color: rgba(235, 235, 245, 0.6);
		font-size: 14px;
		font-weight: 500;
		line-height: 16px;
		padding-top: 14px;
		padding-bottom: 8px;
		border-bottom: 2px solid #7c59ff00;
		cursor: pointer;

		&___active {
			border-bottom: 2px solid #7c59ff;
			color: rgba(235, 235, 245, 1);
		}

		@media (hover: hover) and (pointer: fine) {
			transition: 0.3s ease-in-out;

			&:hover {
				color: rgba(235, 235, 245, 0.8);
				border-bottom: 2px solid #7c59ff30;
			}

			&___active {
				&:hover {
					border-bottom: 2px solid #7c59ff;
					color: rgba(235, 235, 245, 1);
				}
			}
		}
	}

	&__dots {
		display: flex;
		align-items: center;
		padding-top: 12px;
		padding-bottom: 12px;
		margin-left: auto;
		cursor: pointer;

		@media (hover: hover) and (pointer: fine) {
			transition: 0.3s ease-in-out;

			&:hover {
				opacity: 0.8;
			}
		}
	}

	&__marginHeader {
		display: flex;
		align-items: center;
		padding: 12px 16px 0;

		svg {
			margin-top: -2px;
		}
	}

	&__marginHeaderText {
		margin: 0 8px 0 0;
		padding: 0;
		color: #fff;
		font-size: 12px;
		font-weight: 500;
		line-height: 14px;
	}
}
