.marginSelector {
	padding: 12px 16px;

	&__selectorWrapper {
		background-color: #2c2c2e;
		border-radius: 8px;
		display: flex;
		flex-direction: column;
		overflow: hidden;

		button svg {
			transition: 0.2s ease;
		}

		&___open {
			button svg {
				transform: rotate(-90deg);
			}

			.marginSelector__selectorRange {
				opacity: 1;
				height: 64px;
				pointer-events: unset;
			}
		}
	}

	&__selectorSkeleton {
		height: 40px;

		span {
			display: flex;
			height: 40px;
		}
	}

	&__selector {
		display: flex;
		align-items: center;
		height: 40px;
		width: 100%;
		padding: 0 16px;
		border-radius: 8px;

		@media (hover: hover) and (pointer: fine) {
			transition: 0.3s ease-in-out;

			&:hover {
				opacity: 0.7;
			}
		}
	}

	&__selectorContent {
		display: flex;
		flex: 1;
	}

	&__selectorText {
		color: #fff;
		font-size: 14px;
		font-weight: 400;
		line-height: 16px;
		padding-right: 8px;
		margin-right: 8px;
		border-right: 1px solid rgba(84, 84, 88, 0.36);
	}

	&__selectorValueText {
		color: #7c59ff;
		font-size: 14px;
		font-weight: 400;
		line-height: 16px;
		text-transform: uppercase;
	}

	&__selectorRange {
		height: 0;
		opacity: 0;
		pointer-events: none;
		transition: 0.3s ease;
	}
}
