@import "assets/styles/common/mixins";

.container {
	@include flexbox(stretch, stretch, column);
	@include card();
	margin-top: 8px;
}

.image {
	background-size: contain;
	background-position: top center;
	height: 240px;
}

.control_panel {
	@include flexbox(stretch, center);

	height: 60px;
	position: relative;
	border-bottom: 1px solid #28282d;

	padding: 0 20px;
	z-index: 20;
}

.dropdown {
	z-index: 99;
}

.user_center_dropdown_content {
	@include flexbox(stretch, stretch, column);

	border-bottom-left-radius: 12px;
	border-bottom-right-radius: 12px;
	background-color: var(--card-background-color);
	border: 1px solid var(--card-border-color);
	box-shadow: var(--navmenu-box-shadow);

	.item {
		padding: 12px;
		border-bottom: 1px solid var(--card-border-color);
		font-size: 14px;
		line-height: 16px;
		color: var(--color-text-primary);

		&:last-of-type {
			border-bottom: none;
		}
	}
}
