@import "~@btc-alpha/ui-components/dist/styles/functions";
@import "~@btc-alpha/ui-components/dist/styles/media";

.header {
	position: sticky;
	top: 0;
}

.layout {
	--sidebar-width: #{rems(280)};

  &:not(:has(.sideBar)) {
    --sidebar-width: 0px;
  }

	--content-width: calc(100% - var(--sidebar-width));

	//@media screen and (max-width: 1365px) {
	//	--content-width: 100%;
	//}

	display: flex;
	flex-direction: row;
	align-items: flex-start;

	.sideBar {
		position: sticky;
		top: var(--redesigned-header-height);
		min-width: var(--sidebar-width);
		max-width: var(--sidebar-width);
		height: calc(100vh - var(--redesigned-header-height));
		padding: rems(24) rems(16);
		display: flex;
		flex-direction: column;
		align-items: stretch;
		gap: rems(12);
		border-right: rems(1) solid var(--alpha-ui-color-separator-opaque);
		background-color: var(--alpha-ui-color-background-base-3);
	}

	.content {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		min-width: var(--content-width);
		max-width: var(--content-width);
		min-height: calc(100vh - var(--redesigned-header-height));
		padding: var(--page-padding);

		& > div {
			flex-grow: 1;
		}

		& > footer:last-child {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			flex-wrap: wrap;
			gap: rems(20);
			margin-top: rems(70);

			span {
				font-size: rems(14);
				font-weight: 500;
				line-height: rems(20);
				text-align: center;
				color: var(--alpha-ui-color-label-tertiary);
			}
		}
	}
}
