.availableBalance {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 12px 16px;

	&__text {
		color: rgba(235, 235, 245, 0.6);
		font-size: 12px;
		font-weight: 400;
		line-height: 14px;
		text-decoration-line: underline;
		padding: 0;
		margin: 0;
	}

	&__textWrapper {
		position: relative;
	}

	&__amount {
		color: #fff;
		font-size: 12px;
		font-weight: 500;
		line-height: 14px;
		padding: 0;
		margin: 0;
	}

	&__currency {
		padding-left: 2px;
	}
}
