@import "src/assets/styles/common/mixins";

.openerWrapper {
	position: relative;

	&.hint {
		margin-left: 5px;
	}
}

.question {
	position: relative;

	color: var(--hint-icon-normal) !important;
	font-size: 12px !important;

	&:hover {
		color: var(--hint-icon-pressed) !important;
	}
}

.tooltip {
	max-width: 312px;
	border: none !important;
	padding: 0 !important;

	&::before {
		display: none !important;
	}

	&.isDefaultMode {
		padding: 5px 8px !important;
	}

	&.transparent {
		background-color: transparent !important;
	}
}

.content {
	opacity: 1;
	word-break: break-word;
	white-space: normal;
	font-size: 12px;
	font-weight: normal;
	text-transform: initial;
	text-align: initial;
	line-height: 1.15;
	padding: 5px 8px;
	border-radius: 5px;

	color: var(--tooltip-color);

	@include max-w(xs) {
		position: relative;
		z-index: 1;
	}
}