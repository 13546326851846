.tooltipModal {
	position: relative;
	z-index: 10;

	&__modalChildren {
		cursor: pointer;

		&:hover + .tooltipModal__modal {
			opacity: 1;
			pointer-events: auto;
		}
	}

	&__modal {
		display: flex;
		flex-direction: column;
		padding: 8px;
		margin: 0;
		list-style: none;
		position: absolute;
		background-color: #242424;
		border-radius: 8px;
		width: 140px;
		min-height: 50px;
		transition: 0.3s ease-in-out;
		opacity: 0;
		pointer-events: none;
		z-index: 10;

		color: #fff;
		font-size: 12px;
		font-weight: 400;
		line-height: 18px;

		&::after {
			content: "";
			position: absolute;
		}

		&___rightTop {
			top: 0;
			bottom: 0;
			transform: translate(calc(-100% - 10px), -8px);

			&::after {
				top: 9px;
				right: -11px;
				border: 6px solid transparent;
				border-left: 6px solid #242424;
			}
		}

		&___rightCenter {
			top: 50%;
			bottom: 0;
			transform: translate(calc(-100% - 10px), -50%);

			&::after {
				top: 50%;
				right: -11px;
				border: 6px solid transparent;
				transform: translate(0, -50%);
				border-left: 6px solid #242424;
			}
		}

		&___rightBottom {
			left: 0;
			bottom: 0;
			transform: translate(calc(-100% - 10px), 8px);

			&::after {
				bottom: 9px;
				right: -11px;
				border: 6px solid transparent;
				border-left: 6px solid #242424;
			}
		}

		&___leftBottom {
			left: 100%;
			bottom: 0;
			transform: translate(10px, 8px);

			&::after {
				bottom: 9px;
				left: -11px;
				border: 6px solid transparent;
				border-right: 6px solid #242424;
			}
		}

		&___leftCenter {
			left: 100%;
			top: 50%;
			transform: translate(10px, -50%);

			&::after {
				top: 50%;
				left: -11px;
				transform: translate(0, -50%);
				border: 6px solid transparent;
				border-right: 6px solid #242424;
			}
		}

		&___leftTop {
			left: 100%;
			top: 0;
			transform: translate(10px, -6px);

			&::after {
				top: 9px;
				left: -11px;
				border: 6px solid transparent;
				border-right: 6px solid #242424;
			}
		}

		&___bottomCenter {
			left: 50%;
			top: 0;
			transform: translate(-50%, calc(-100% - 10px));

			&::after {
				right: 50%;
				bottom: -5px;
				border: 6px solid transparent;
				transform: translate(-50%, 50%);
				border-top: 6px solid #242424;
			}
		}

		&___bottomRight {
			right: 0;
			top: 0;
			transform: translate(0, calc(-100% - 10px));

			&::after {
				right: 9px;
				bottom: -5px;
				border: 6px solid transparent;
				transform: translate(0, 50%);
				border-top: 6px solid #242424;
			}
		}

		&___bottomLeft {
			left: 0;
			top: 0;
			transform: translate(0, calc(-100% - 10px));

			&::after {
				left: 9px;
				bottom: -5px;
				border: 6px solid transparent;
				transform: translate(0, 50%);
				border-top: 6px solid #242424;
			}
		}

		&___topCenter {
			left: 50%;
			bottom: 0;
			transform: translate(-50%, calc(100% + 10px));

			&::after {
				left: 50%;
				top: -5px;
				border: 6px solid transparent;
				transform: translate(-50%, -50%);
				border-bottom: 6px solid #242424;
			}
		}

		&___topRight {
			right: 0;
			bottom: 0;
			transform: translateY(calc(100% + 10px));

			&::after {
				right: 9px;
				top: -5px;
				border: 6px solid transparent;
				transform: translateY(-50%);
				border-bottom: 6px solid #242424;
			}
		}

		&___topLeft {
			left: 0;
			bottom: 0;
			transform: translateY(calc(100% + 10px));

			&::after {
				left: 9px;
				top: -5px;
				border: 6px solid transparent;
				transform: translateY(-50%);
				border-bottom: 6px solid #242424;
			}
		}
	}
}
