@import "styles/mixins";

.history {
	margin-top: 80px;
	width: 100%;

	@media screen and (max-width: 767px) {
		margin-top: 80px;
	}

	&__headline {
		color: #fff;
		text-align: center;
		font-size: 40px;
		font-weight: 600;
		line-height: 132%;
		letter-spacing: -0.45px;
		margin: 0;
		padding: 0 24px;

		@media screen and (max-width: 679px) {
			font-size: 32px;
			line-height: 140%;
			text-align: left;
		}
	}

	&__description {
		width: 668px;
		max-width: 100%;
		color: #fff;
		padding: 0 24px;
		text-align: center;
		font-size: 18px;
		font-style: normal;
		font-weight: 400;
		line-height: 132%;
		letter-spacing: 0.06px;
		margin: 32px auto 0 auto;

		@media screen and (max-width: 679px) {
			font-size: 16px;
			line-height: 160%;
			margin: 16px 0 0 0;
			text-align: left;
		}
	}

	&__container {
		@include main-container;

		max-width: 1320px;

		@media screen and (max-width: 679px) {
			padding: 0;
		}
	}

	.arrow {
		position: absolute;
		z-index: 3;
		top: 50%;
		transform: translateY(-50%);
		padding: 16px 18px;
		background-color: #202028;
		border-radius: 42px;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		transition: 0.3s ease-in-out;

		@media screen and (max-width: 679px) {
			top: unset;
			bottom: -70px;
		}

		&.prev {
			left: 0;

			@media screen and (max-width: 679px) {
				left: 50%;
				transform: translateX(calc(-50% - 42px));
			}
		}

		&.next {
			right: 0;

			@media screen and (max-width: 679px) {
				right: 50%;
				transform: translateX(calc(50% + 42px));
			}
		}
	}

	.arrow_list {
		right: 0;
		transition: transform 0.3s ease;
		&.arrow_open {
			transform: rotate(180deg);
		}
	}

	&__sliderNavItemWrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 160px;
		width: 100%;
	}

	&__sliderNav {
		margin-top: 63px;
		position: relative;
		z-index: 2;
	}

	&__sliderNavItem {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 92px;
		height: 92px;
		background-color: #202028;
		border: 4px solid #202028;
		border-radius: 100%;
		margin: auto;
		transition: 0.3s ease-in-out;
	}

	&__sliderNavItemText {
		color: #fff;
		text-align: center;
		font-size: 16px;
		font-weight: 600;
		line-height: 138%;
		letter-spacing: -0.17px;
		margin: 0;
	}

	&__slider {
		position: relative;
		z-index: 2;

		@media screen and (max-width: 679px) {
			margin-bottom: 136px;
		}

		:global(.slick-center) {
			.history__sliderNavItem {
				transform: scale(1.7);
				border: 4px solid #7c59ff;

				@media screen and (max-width: 993px) {
					transform: scale(1.54);
				}

				.history__sliderNavItemText {
					color: #fff;
				}
			}
		}

		:global(.slick-slide) {
			height: 160px;
		}
	}

	&__sliderContent {
		position: relative;
		z-index: 2;
	}

	&__content {
		@include main-container;

		max-width: 1320px;
		margin-top: 80px;
	}

	&__line {
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		z-index: 1;
		max-width: 100%;
		top: 50%;
		transform: translateY(-50%);
		width: 100%;
		overflow: hidden;

		svg {
			width: 100%;
		}
	}

	&__sliderContentNavWrapper {
		position: relative;
		margin: 49px auto 160px;
		max-width: 1280px;
		padding: 83px 80px;
		background-image: linear-gradient(360deg, rgba(124, 89, 255, 0.41) 0%, rgba(124, 89, 255, 0) 100%);
		@media screen and (max-width: 993px) {
			margin: 16px auto 160px;
			padding: 0 24px 95px;
			background-image: linear-gradient(180deg, rgba(74, 53, 153, 0) 0%, rgba(124, 89, 255, 0.3) 100%);
		}
	}

	&__sliderItemWrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
		@media screen and (max-width: 993px) {
			flex-wrap: wrap;
			justify-content: center;
			& > div {
				width: 100%;
				margin-bottom: 42px;
			}

			& > img {
				max-width: 243px;
				height: auto;
			}
		}
	}

	&__sliderItemHeadline {
		margin-top: 0;
		margin-bottom: 24px;
		max-width: 323px;
		font-size: 24px;
		font-weight: 600;
		line-height: 31.68px;
		letter-spacing: -0.45px;

		@media screen and (max-width: 993px) {
			margin-bottom: 8px;
			text-align: left;
			font-size: 20px;
			font-weight: 600;
			line-height: 27.2px;
			letter-spacing: -0.0044em;
		}
	}

	&__sliderItemDescription {
		font-size: 18px;
		max-width: 457px;
		line-height: 23.76px;
		letter-spacing: -0.06px;

		@media screen and (max-width: 993px) {
			font-size: 16px;
			line-height: 25.6px;
			letter-spacing: -0.0024em;
			text-align: left;
		}
	}

	&__sliderContainer {
		position: absolute;
		bottom: -80px;
		left: 50%;
		width: calc(100% + 64px);
		transform: translateX(-50%);

		@media screen and (max-width: 993px) {
			bottom: -215px;
			width: 100%;
		}
	}

	&__itemWrapper {
		border-radius: 12px;
		background: #202028;
		padding: 40px 32px;
		display: flex;
		justify-content: space-between;
		gap: 80px;

		@media screen and (max-width: 767px) {
			gap: 24px;
		}

		@media screen and (max-width: 679px) {
			flex-direction: column-reverse;
		}
	}

	&__itemHeadline {
		margin: 0;
		color: #fff;
		font-size: 36px;
		font-weight: 600;
		line-height: 132%;

		@media screen and (max-width: 767px) {
			font-size: 24px;
		}
	}

	&__itemDescription {
		margin: 16px 0 0 0;
		color: #fff;
		font-size: 18px;
		font-weight: 400;
		line-height: 132%;
		letter-spacing: 0.06px;

		@media screen and (max-width: 767px) {
			font-size: 16px;
		}
	}

	&__sliderRoadmapWrapper {
		width: 360px;

		@media screen and (max-width: 993px) {
			width: 279px;
		}

		:global(.slick-slide img) {
			border-radius: 12px;
		}

		:global(.slick-dots) {
			top: 12px;
			display: flex !important;
			padding: 0 12px;

			li {
				position: relative;
				height: 4px;
				margin: 0 3px;
				flex-grow: 1;
				border-radius: 20px;
				background-color: #FFFFFF80;
				overflow: hidden;
				pointer-events: none;

				&:after {
					content: "";
					position: absolute;
					top: 0;
					width: 100%;
					height: 100%;
					display: block;
					background-color: #fff;
					transform: translateX(-200%);
					transition: transform 0.5s linear;
				}
			}
		}
		:global(.slick-dots li.slick-active) {
			&:after {
				transform: translateX(0);
			}
		}
	}

	&__qList {
		list-style: none;
		padding: 0;
		margin: 40px 0 0 0;

		@media screen and (max-width: 767px) {
			margin: 24px 0 0 0;
		}
	}

	&__qTextWrapper {
		flex-grow: 1;
		position: relative;
	}

	&__qItem {
		display: flex;
		align-items: start;
		margin: 0 0 16px 0;
		cursor: pointer;
	}

	&__qDeskList {
		max-height: 0;
		overflow: hidden;
		transition: max-height 0.3s ease;
	}

	&__qDeskList_open {
		max-height: 500px;
	}

	&__qItemIcon {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 12px;
		position: relative;
	}

	&__checkLine {
		position: absolute;
		width: 1px;
		height: 115px;
		top: -115px;
		background: #7c59ff;
		left: 50%;
		transform: translateX(-50%);
	}

	&__emptyLine {
		position: absolute;
		width: 1px;
		height: 115px;
		top: -115px;
		border-left: 1px dashed #484a56;
		left: 50%;
		transform: translateX(-50%);
	}

	&__qText {
		color: #9a9aa3;
		font-size: 18px;
		font-weight: 400;
		line-height: 160%;
		margin: 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		cursor: pointer;

		@media screen and (max-width: 767px) {
			font-size: 16px;
		}

		&___progress {
			color: #fff;
			font-weight: 600;
		}
	}
	&__qDesk {
		color: #9a9aa3;
		font-size: 16px;
		font-weight: 400;
		line-height: 160%;
		margin: 0;

		@media screen and (max-width: 767px) {
			font-size: 14px;
		}
	}
	&__itemImage {
		@media screen and (max-width: 993px) {
			max-width: 260px;
			height: auto;
		}

		@media screen and (max-width: 679px) {
			max-width: 100%;
		}
	}
}