@import "~@btc-alpha/ui-components/dist/styles/functions";
@import "~@btc-alpha/ui-components/dist/styles/media";
@import "~@btc-alpha/ui-components/dist/styles/mixins";

.root {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	max-width: rems(460);

	.items {
		display: flex;
		flex-direction: column;
		gap: rems(4);
		margin-bottom: rems(40);
		width: 100%;
		max-width: rems(380);

		& > div {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: rems(16);
			padding: rems(8);
			border-radius: rems(24);
			border: rems(1) solid var(--alpha-ui-color-separator-opaque);
			background-color: var(--alpha-ui-color-background-base-3);

			& > div:first-child {
				min-width: rems(64);
				height: rems(64);
				border-radius: rems(16);
				border: rems(1) solid var(--alpha-ui-color-separator-opaque);
				background-color: var(--alpha-ui-color-background-base-1);
				background-size: rems(24);
				background-position: center;
				background-repeat: no-repeat;
			}

			& > div:last-child {
				display: flex;
				flex-direction: column;
				gap: rems(4);

				p {
					margin: 0;
					padding: 0;
					max-width: rems(250);
					@include font(14, 500, 20);
				}

				a {
					@include font(14, 500, 20);
					text-decoration: underline;
				}
			}
		}
	}

	.submit {
		width: 100%;
		max-width: rems(380);
		margin-top: rems(8);
	}
}
