@import "src/assets/styles/common/mixins";

.transfer_title {
	font-size: 16px;
	line-height: 19px;
	color: var(--color-primary);
	margin-bottom: 6px;
}

.swap_select_action_block {
	display: grid;
	grid-template-columns: calc((100% - 40px) / 2) 40px calc((100% - 40px) / 2);
}

.coins_container {
	@include flexbox(flex-start, flex-start, column);
	margin: 10px 0;

	> span {
		font-weight: normal;
		font-size: 14px;
		line-height: 19px;
		margin-bottom: 10px;
		color: var(--color-secondary);
	}
}

.coins {
	width: 100%;
	display: grid;
	grid-template: auto / 1fr 1fr;
	grid-gap: 25px;
}

.info_container {
	width: 100%;
	padding: 20px;
	background: #2c2c2e;

	@include max-w(md) {
		padding: 15px 30px;
	}
}

.isolated_select_container {
	width: 100%;
}

.transfer_to_container {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	position: relative;
}

.swap_btn_block {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 16px;

	> button {
		outline: none;
		padding: 0;

		i {
			font-size: 18px;
			color: var(--color-blue);
			transform: rotate(90deg);
		}
	}
}

.divider {
	background: var(--divider-border);
	width: calc(100% + 32px);
	height: 1px;
	position: relative;
	left: -16px;

	@include max-w(md) {
		width: calc(100% + 60px);
		left: -30px;
	}
}

.amount_container {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-top: 12px;
}

.available_amount {
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	font-size: 12px;
	line-height: 14px;
	text-align: right;
	color: var(--color-secondary);
	margin-bottom: 6px;

	button {
		font-weight: normal;
		font-size: 14px;
		line-height: 19px;
		text-align: right;
		outline: none;
		padding: 0;
		margin: 0;
		color: var(--color-blue);
	}
}

.transfer_info_container {
	width: 100%;
	padding: 0 18px;
	display: grid;
	grid-template: auto / 1fr 1fr;
	grid-gap: 8px;
	margin-bottom: 16px;

	@include max-w(md) {
		padding: 0 30px;
	}

	> span {
		font-weight: normal;
		font-size: 12px;
		line-height: 14px;
		color: var(--color-secondary);
		display: flex;
		justify-content: flex-start;
		align-items: center;

		&:nth-child(2n) {
			font-size: 12px;
			line-height: 14px;
			color: var(--color-primary);
			justify-content: flex-end;
		}
	}
}

.stop_limit_confirm_subheader {
	@include flexbox(flex-start, center);
	width: 100%;
	padding: 20px;
	color: var(--color-primary);
}

.stop_limit_stop_order_text {
	color: var(--color-secondary);
	text-align: left;
	padding: 20px;

	> span {
		color: var(--color-primary);
	}
}
