@import "~@btc-alpha/ui-components/dist/styles/functions";
@import "~@btc-alpha/ui-components/dist/styles/media";
@import "~@btc-alpha/ui-components/dist/styles/mixins";

.root {
	.panel {
		display: flex;
		flex-direction: column;
		border-radius: rems(12);
		overflow-y: hidden;
		background-color: var(--alpha-ui-color-background-base-3);

		* {
			color: var(--alpha-ui-color-text-primary);
		}

		.header {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			padding: rems(12) rems(16);
			border-bottom: rems(1) solid var(--alpha-ui-color-separator-opaque);

			& > span {
				@include font(14, 500, 20);
			}
		}

		.amounts {
      position: relative;
			display: flex;
			flex-direction: column;
			gap: rems(4);
			padding: rems(12) rems(16);
			transition: background-color 0.2s;

			& > div {
				@include font(24, 500, 32);

				span {
					color: var(--alpha-ui-color-label-secondary);
					@include font(14, 400, 20);
				}
			}

			span {
        color: var(--alpha-ui-color-label-secondary);
				@include font(14, 500, 20);
			}

      .chevron {
        position: absolute;
        right: rems(26);
        top: 50%;
        transform: translateY(-50%);
        opacity: 0;
        transition: 0.2s;
      }

      @include hover {
        background-color: var(--alpha-ui-color-background-base-1);

        .chevron {
          right: rems(16);
          opacity: 1;
        }
      }
		}
	}

	& > p {
		margin-top: rems(4);
		margin-bottom: rems(16);
		padding: 0;
		text-align: center;
		color: var(--alpha-ui-color-label-tertiary);
		@include font(12, 400, 16);
	}

	.buttons {
		display: flex;
		flex-direction: row;
		align-items: stretch;
		gap: rems(16);

		button {
			flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: rems(4);

      img {
        margin-top: rems(2);
        filter: brightness(10);
      }

			&:not(.primary) {
        border-color: var(--alpha-ui-color-separator-opaque);

        &:not(:hover) {
          background-color: transparent;
        }
      }
		}
	}
}
