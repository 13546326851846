@import "src/assets/styles/common/mixins";

.container {
	@include card();
	@include flexbox(flex-start, center, column);
	margin: 0;
	padding: 26px;
	flex: 1;

	@include max-w(xs) {
		padding: 26px;
	}
}

.steps {
	width: 100%;
	display: grid;
	grid-template: auto / 1fr 26px 1fr auto;
	align-items: center;
	margin-bottom: 30px;
}

.steps {
	width: 100%;
	display: grid;
	grid-template: auto / 1fr 26px 1fr auto;
	align-items: center;
	margin-bottom: 30px;
}

.step {
	@include flexbox(flex-end, center);
	@include transition(background-color);
	position: relative;

	&:not(:last-child) {
		@include flexbox(flex-start, center);
	}

	&:first-child:before {
		left: 26px;
	}

	&:last-child:before {
		left: 0;
	}

	&:first-child:before,
	&:last-child:before {
		content: "";
		z-index: 1;
		position: absolute;
		width: calc(100% - 26px);
		height: 3px;
		background-color: var(--icon-primary);
	}

	&.pass:first-child:before,
	&.pass:last-child:before {
		background-color: var(--color-blue);
	}
}

.step_num {
	@include flexbox(center, center);
	@include transition(background-color);
	user-select: none;
	width: 26px;
	height: 26px;
	border-radius: 50%;
	font-size: 14px;
	color: #fff;
	background-color: var(--icon-primary);

	&.active {
		background-color: var(--color-blue);
	}
}

.content {
	@include flexbox(flex-start, center, column);
	max-width: 340px;
}

.title {
	font-weight: bold;
	font-size: 24px;
	line-height: 28px;
	text-align: center;
	margin-bottom: 15px;
	color: var(--color-primary);
}

.subtitle {
	font-size: 14px;
	line-height: 19px;
	text-align: center;
	margin-bottom: 30px;
	color: var(--color-secondary);
}

.authenticator_container {
	display: grid;
	grid-template: auto / 1fr 1fr;
	grid-gap: 10px;
	margin-bottom: 20px;

	@include max-w(xs) {
		grid-template: auto / 1fr;
		justify-items: center;
	}
}

.authenticator {
	@include transition(color);
	display: grid;
	grid-template: auto / 1fr;
	grid-gap: 14px;
	width: auto;
	cursor: pointer;
	margin: 0;
	padding: 0;

	span {
		font-weight: normal;
		font-size: 14px;
		line-height: 19px;
		text-align: center;
		color: var(--color-primary);
	}

	&.active {
		span {
			color: var(--color-blue);
		}
	}
}

.authenticator_icon {
	@include transition(background-color, border-color);
	background-color: var(--background-gray-opaque);
	padding: 20px;
	border-radius: 35px;
	border: 4px solid transparent;
	width: 170px;
	height: 170px;

	&.active {
		background-color: transparent;
		border-color: var(--color-blue);
	}

	> img {
		width: 120px;
		height: auto;
	}
}

.authenticator_link_container {
	@include flexbox(center, center);
	padding: 30px;
}

.authenticator_links {
	display: grid;
	grid-template: auto / 1fr 1fr;
	grid-gap: 20px;
	width: 100%;

	a {
		@include flexbox(center, center);
	}

	img {
		height: auto;
	}

	@include max-w(xs) {
		grid-template: auto / 1fr;
		justify-items: center;

		img {
			height: 60px;
			width: 170px;
		}
	}
}

.form_content {
	display: grid;
	grid-template: auto / 1fr;
	margin-top: 20px;
	width: 100%;
}

.setup_success {
	@include flexbox(center, center, column);

	i {
		font-size: 120px;
		color: var(--color-green);
	}

	a {
		margin-top: 60px;
	}
}

.two_fa_disable_modal_content {
	@include max-w(xs) {
		overflow: unset;
	}
}
