.question {
	width: 100%;
	max-width: 1660px;
	padding: 0 20px;
	margin: 80px auto 0 auto;

	@media screen and (max-width: 767px) {
		margin-top: 40px;
	}

	&__container {
		display: flex;
		width: 100%;
		padding: 32px;
		background: #7c59ff;
		justify-content: space-between;
		align-items: center;
		border-radius: 16px;

		@media screen and (max-width: 769px) {
			flex-direction: column;
			align-items: flex-start;
			padding: 24px;
		}
	}

	&__content {
		margin-right: 24px;
	}

	&__headline {
		color: #fff;
		font-size: 32px;
		font-weight: 600;
		line-height: 132%;
		letter-spacing: -0.4px;
		margin: 0;

		@media screen and (max-width: 769px) {
			font-size: 24px;
		}
	}

	&__description {
		margin: 8px 0 0;
		color: #fff;
		font-size: 18px;
		font-weight: 400;
		line-height: 132%;
		letter-spacing: 0.06px;

		@media screen and (max-width: 769px) {
			margin: 12px 0 0;
			font-size: 16px;
		}
	}

	&__link {
		display: flex;
		min-width: 179px;
		padding: 18px 20px;
		justify-content: center;
		align-items: center;
		gap: 10px;
		border-radius: 12px;
		background: #fff;
		color: #7c59ff;
		font-size: 18px;
		font-weight: 600;
		line-height: 132%;
		letter-spacing: 0.2px;
		transition: 0.3s ease-in-out;

		@media screen and (max-width: 769px) {
			width: 100%;
			margin-top: 24px;
			padding: 14px 24px;
			font-size: 16px;
		}

		&:hover {
			opacity: 0.7;
		}
	}
}
